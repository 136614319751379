import styled from 'styled-components';

export const PageWrapper = styled.section`
  display: grid;
  place-content: center;
  width: 100vw;
  height: 100vh;
`;

export const ContentWrapper = styled.article`
  width: 40rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    font-weight: bold;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;

  img {
    width: 24rem;
    height: 24rem;
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 1.5rem;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: auto;

  input {
    width: 100%;
  }
`;

export const FooterContainer = styled.footer`
  text-align: center;
  margin-top: 2rem;

  button {
    color: #1890ff;
    font-weight: 700;

    &:hover {
      color: #0c4ecf;
    }
  }
`;

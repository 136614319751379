import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  DatePicker,
  Alert,
  Divider,
  Input,
  Button,
  Select,
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import { Footer } from './shared';
import { DeleteFilled, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { InputWrapper } from '..';
import { ArrayTanggal } from 'utils/helpers/common';

const SettingPPDB = (props) => {
  const [form] = Form.useForm();
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    items,
    toBeLocked,
    submitLock,
    loadingSubmit,
    ...rest
  } = props;
  const [deletedItems, setDeletedItems] = useState([]);
  const SelectionDate = ArrayTanggal(28);

  const handleDeletedItems = (item) => {
    setDeletedItems([...deletedItems, item]);
  };

  useEffect(() => {
    setDeletedItems([]);
  }, [visible]);

  useEffect(() => {
    if (value) {
      const valueAdded =
        value.type === 'PPDB'
          ? value.ppdbItems.map((el) => ({
              // ppdbItemId: el.ppdb,
              master_bill_item: el.master_bill_item._id,
              priority: el.priority,
            }))
          : '';
      form.setFieldsValue({
        instalment_repition: value.instalment_repition,
        instalment_date: value.instalment_date,
        addedItems: valueAdded,
      });
    } else {
      form.setFieldsValue({
        addedItems: [''],
      });
    }
  }, [value]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setDeletedItems([]);
    }
  }, [visible]);

  const submitPayloadEdit = () => {
    const fieldValues = form.getFieldsValue();

    const addedItems = [];
    const editedItems = [];

    fieldValues.addedItems.forEach((val) => {
      const existed = value.ppdbItems.find(
        (item) => item.master_bill_item?._id === val.master_bill_item
      );

      if (existed) {
        editedItems.push({
          ppdbItemId: existed.ppdb,
          ...val,
        });
      } else {
        addedItems.push(val);
      }

      // if (val?.ppdbItemId) {
      //   editedItems.push(val);
      // } else {
      //   const found = value.ppdbItems.find(
      //     (el) => el.master_bill_item?._id === val.master_bill_item
      //   );
      //   if (found) {
      //     editedItems.push({
      //       ppdbItemId: found.ppdb,
      //       ...val,
      //     });
      //   } else {
      //     addedItems.push(val);
      //   }
      // }
    });

    const deleted = deletedItems.filter(Boolean).map((el) => {
      const foundItem = value.ppdbItems.find(
        (val) => val.master_bill_item?._id === el
      );
      if (foundItem) {
        return foundItem?._id;
      }
    });

    value.ppdbItems.forEach((item) => {
      const inEdited = editedItems.some(
        (el) => el.master_bill_item === item?.master_bill_item?._id
      );

      if (!inEdited) {
        deleted.push(item?._id);
      }
    });

    const payload = value.lock_status
      ? {
          editedItems: editedItems
            .filter(Boolean)
            .map((item) => ({
              ppdbItemId: item.ppdbItemId,
              priority: item.priority,
            })),
          instalment_date: fieldValues.instalment_date,
          instalment_repition: fieldValues.instalment_repition,
        }
      : {
          addedItems,
          deletedItems: deleted.filter(Boolean),
          editedItems: editedItems.filter(Boolean),
          instalment_date: fieldValues.instalment_date,
          instalment_repition: fieldValues.instalment_repition,
        };
    console.log(payload, 'this is payload');
    handleSubmit(payload, value._id, value.lock_status);
  };

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : toBeLocked ? 'Lock' : 'Setting'} Uang Pangkal ${
        value.school_year?.name || ''
      }`}
      width="40rem"
      visible={visible}
      footer={
        <Footer
          onCancel={onCancel}
          onSubmit={() =>
            toBeLocked
              ? submitLock(value.lock_status, value._id)
              : submitPayloadEdit()
          }
          toBeLocked={toBeLocked}
          loadingState={loadingSubmit}
        />
      }
      onClose={onCancel}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          master_bill_item: [''],
          instalment_repition: 1,
          instalment_date: '1',
        }}
        onFinish={() => console.log('TEst')}
      >
        <section>
          {isEdit && !value.lock_status && (
            <Alert
              type="info"
              description="Mohon cek detail pengaturan sebelum lock pengaturan."
              message="Cek terlebih dahulu"
              showIcon
              style={{ marginBottom: '2rem' }}
            />
          )}
          <h3 style={{ marginBottom: '2rem' }}>Item Tagihan</h3>
          <Form.List name="addedItems">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item required={false} key={field.key}>
                    <Input.Group>
                      <Row gutter={[12, 1]}>
                        <Col span={16}>
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Nama item tagihan wajib diisi.',
                              },
                            ]}
                            style={{ marginBottom: 0 }}
                            name={[field.name, 'master_bill_item']}
                          >
                            <Select
                              style={{ width: '100%' }}
                              disabled={isEdit && value.lock_status}
                            >
                              {items.map((item) => {
                                return (
                                  <Select.Option value={item._id}>
                                    {item.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <InputWrapper
                            type="number"
                            min={1}
                            style={{ marginBottom: 0, width: '100%' }}
                            formItemProps={{
                              name: [field.name, 'priority'],
                            }}
                            placeholder="Priority"
                          />
                        </Col>
                        <Col span={2} style={{ textAlign: 'right' }}>
                          {fields.length > 1 ? (
                            <Button
                              type="text"
                              onClick={() => {
                                handleDeletedItems(
                                  form.getFieldValue('addedItems')[field.name]
                                    ?.master_bill_item
                                );
                                remove(field.name);
                              }}
                              disabled={isEdit && value.lock_status}
                              icon={
                                <DeleteOutlined style={{ fontSize: '20px' }} />
                              }
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="icon"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                    disabled={isEdit && value.lock_status}
                  >
                    Tambah Tipe Tagihan
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </section>
        <Divider />
        <section>
          <h3 style={{ marginBottom: '2rem' }}>Cicilan</h3>
          <InputWrapper
            type="number"
            name="instalment_repition"
            label="Jumlah Cicilan"
            style={{ width: '100%' }}
            placeholder="Masukan jumlah cicilan"
            min={1}
          />
          <Form.Item
            label="Tanggal Penagihan Setiap Bulan"
            name="instalment_date"
          >
            <Select>
              {SelectionDate.map((date) => (
                <Select.Option value={date}>{date}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </section>
      </Form>
    </Drawer>
  );
};

export default SettingPPDB;

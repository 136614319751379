import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, message, Modal } from 'antd';
import { getDetailWhatsappMessage, resentWhatsappMessage } from 'utils/fetch';

const DetailPesanWA = ({
  visible,
  onClose,
  data,
  id,
  refetchData,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (id) {
      _fetchDetail(id);
    }
  }, [id]);

  const _fetchDetail = (id) => {
    getDetailWhatsappMessage(id)
      .then((res) => {
        console.log({ res });
        setDetail(res.sendWhatsapp);
        const usedPhoneNumber = res.sendWhatsapp.phone
          .split('-')
          ?.find((el) => el.includes('62'));

        setPhone(usedPhoneNumber);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const _handleResent = (id, payload) => {
    setLoading(true);
    resentWhatsappMessage(id, payload)
      .then((res) => {
        console.log({ res });
        message.success('Sukses kirim ulang pesan');
        refetchData();
        onClose();
      })
      .catch((err) => {
        console.log({ err });
        message.error('Gagal mengirim ulang pesan');
      })
      .finally(() => setLoading(false));
  };

  const modifiedText = (text = '') => {
    const mod = text.split('\n').join('<br/>');
    return mod;
  };

  return (
    <Modal
      title="Detail WhatsApp Message"
      visible={visible}
      onCancel={onClose}
      centered
      okText="Kirim Ulang"
      cancelText="Tutup"
      width="40vw"
      okButtonProps={{
        loading: loading,
      }}
      cancelButtonProps={{ loading: loading }}
      onOk={() => {
        _handleResent(id, { phone });
      }}
      {...rest}
    >
      <section>
        <div
          dangerouslySetInnerHTML={{ __html: modifiedText(detail?.message) }}
        ></div>

        <footer
          style={{
            marginTop: '2rem',
            borderTop: '1px solid black',
            paddingTop: '2rem',
          }}
        >
          <p style={{ fontWeight: 600, marginBottom: '0.5rem' }}>
            Ganti Nomor Whatsapp
          </p>
          <Input
            placeholder="Enter Phone Number"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </footer>
      </section>
    </Modal>
  );
};

export default DetailPesanWA;

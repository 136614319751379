import React from "react";
import { Form, Input, InputNumber } from "antd";

const InputWrapper = (props) => {
  const {
    type,
    name,
    label,
    placeholder,
    onChange = () => {},
    typeNumberProps,
    formItemProps,
    style,
    disabled,
    ...rest
  } = props;

  switch (type) {
    case "text":
      return (
        <Form.Item label={label} name={name} {...formItemProps} style={style}>
          <Input
            type="text"
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            {...rest}
          />
        </Form.Item>
      );
    case "email":
      return (
        <Form.Item label={label} name={name} {...formItemProps} style={style}>
          <Input
            type="email"
            disabled={disabled}
            placeholder={placeholder}
            {...rest}
          />
        </Form.Item>
      );
    case "password":
      return (
        <Form.Item label={label} name={name} {...formItemProps} style={style}>
          <Input.Password
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            {...rest}
          />
        </Form.Item>
      );
    case "number":
      return (
        <Form.Item label={label} name={name} {...formItemProps} style={style}>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            disabled={disabled}
            {...typeNumberProps}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={(e) => {
              const value = Number(
                e?.target?.value?.replace(/\Rp\s?|(,*)/g, "")
              );
              if (
                !(
                  (e.keyCode > 95 && e.keyCode < 106) ||
                  (e.keyCode > 47 && e.keyCode < 58) ||
                  e.keyCode == 8
                ) ||
                (!value && e.keyCode == 48)
              ) {
                e.preventDefault();
              }
            }}
            {...rest}
          />
        </Form.Item>
      );
    default:
      return "";
  }
};

export default InputWrapper;

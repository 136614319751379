import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Checkbox, Col, Drawer, Form, Radio, Row, Select } from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';
import { fetchUnitList } from 'utils/fetch';

const ManagePengguna = (props) => {
  const {
    isEdit,
    values,
    visible,
    onCancel,
    handleSubmit,
    loadingState,
    ...rest
  } = props;
  const [form] = Form.useForm();
  const [userType, setUserType] = useState(0);
  const [listUnit, setListUnit] = useState(null);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        name: values.name,
        email: values.email,
        type: values.type,
        units: values.units?.map((unit) => unit?._id),
      });
      if (values.type === 2) setUserType(2);
      else setUserType(1);
    } else {
      form.setFieldsValue({
        name: '',
        email: '',
        type: 1,
        units: '',
      });
      setUserType(1);
    }
  }, [isEdit]);

  useEffect(() => {
    if (!listUnit) {
      fetchUnitList()
        .then((res) => setListUnit(res.units))
        .catch((err) => console.error(err));
    }
  }, [listUnit]);

  const units = useMemo(() => {
    if (listUnit && listUnit?.length) {
      return listUnit?.map((unit) => ({ label: unit.name, value: unit._id }));
    } else {
      return [];
    }
  }, [listUnit]);

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Tambah'} Pengguna`}
      width="40rem"
      visible={visible}
      onClose={onCancel}
      footer={
        <Footer
          onCancel={onCancel}
          onSubmit={form.submit}
          loadingState={loadingState}
        />
      }
      {...rest}
    >
      <Form
        form={form}
        initialValues={{
          name: '',
          email: '',
          type: 0,
          unit: '',
        }}
        layout="vertical"
        onFinish={(data) => handleSubmit(data, values?._id)}
      >
        <InputWrapper
          type="text"
          placeholder="Ketik Nama Pengguna"
          label="Nama Pengguna"
          name={'name'}
        />
        <InputWrapper
          type="email"
          placeholder="Ketik Email Pengguna"
          label="Email"
          name={'email'}
        />
        <Form.Item label="Tipe Pengguna" name="type">
          <Radio.Group
            onChange={(e) => {
              form.setFieldsValue({
                type: e.target.value,
              });
              setUserType(e.target.value);
            }}
          >
            <Radio value={1}>Admin</Radio>
            <Radio value={2}>User</Radio>
          </Radio.Group>
        </Form.Item>

        {userType === 2 ? (
          <Form.Item label="Unit" name="units">
            <Checkbox.Group>
              <Row gutter={[8, 8]}>
                {units?.map((unit) => {
                  return (
                    <Col span={12}>
                      <Checkbox value={unit.value}>{unit.label}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        ) : (
          ''
        )}

        <Alert
          type="info"
          message="Perhatian"
          description={
            <span>
              Format password pengguna adalah <b>name123</b> (namakamu123)
            </span>
          }
          showIcon
        />
      </Form>
    </Drawer>
  );
};

export default ManagePengguna;

import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Table,
  InputNumber,
  DatePicker,
  Select,
  Alert,
  Modal,
  message,
  Dropdown,
  Menu,
  Input,
  Popover,
  Radio,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { CustomButton, Loader } from 'components/atoms';
import IC_EDIT from 'assets/icons/Edit.svg';
import {
  CalendarOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileOutlined,
  MenuOutlined,
  StarFilled,
} from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import {
  BASE_URL,
  createApresiasi,
  deleteApresiasi,
  deleteGeneralBill,
  editNominal,
  getDetailTagihan,
  updateApresiasi,
} from 'utils/fetch';
import moment from 'moment';
import { themeColor } from 'lib/theme';
import { useStore } from 'store';

const DetailSemuaTagihan = (props) => {
  const [form] = Form.useForm();
  const {
    visible,
    onCancel,
    value,
    refetchData,
    apresiasiData,
    data,
    ...rest
  } = props;
  const [displayItem, setDisplayItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [detail, setDetail] = useState(null);
  const [billItems, setBillItems] = useState([]);
  const [payments, setPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [dateApresiasi, setDateApresiasi] = useState('');
  const [listApresiasi, setListApresiasi] = useState([]);
  const [editApresiasi, setEditApresiasi] = useState({
    state: false,
    data: null,
  });
  const { unitId, id } = value;
  const {
    state: { listTahunAjaran },
  } = useStore();

  const ArrayTanggal = Array.from({ length: 31 }, (_, i) => i + 1);

  useEffect(() => {
    if (!visible) setDisplayItem(0);
    if (data) setDetail(data);
  }, [visible, data]);

  const tableTunggakanSPP = [
    {
      title: 'Bulan',
      dataIndex: 'month',
      key: 'month',
      render: (data) => <span>{moment(data).format('MMMM YYYY')}</span>,
    },
    {
      title: 'Total Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{(data || 0)?.toLocaleString() || 0}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        let color;
        let text;
        const debt = record.total_amount - data;
        const isLunas = debt === 0;

        if (isLunas) {
          color = 'green';
          text = 'LUNAS';
        } else {
          color = 'red';
          text = `-Rp${(debt || 0).toLocaleString()}`;
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  const tableTunggakanPPDB = [
    {
      title: 'Tahun Ajaran',
      dataIndex: 'school_year',
      key: 'school_year',
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: 'Total Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{(data || 0)?.toLocaleString() || 0}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        let color;
        let text;
        const debt = record.total_amount - data;
        const isLunas = debt === 0;

        if (isLunas) {
          color = 'green';
          text = 'LUNAS';
        } else {
          color = 'red';
          text = `-Rp${(debt || 0).toLocaleString()}`;
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  const tableTunggakanLainnya = [
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Total Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{(data || 0)?.toLocaleString() || 0}</span>,
    },
    {
      title: 'Jatuh Tempo',
      dataIndex: 'due_date',
      key: 'due_date',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        let color;
        let text;
        const debt = record.total_amount - data;
        const isLunas = debt === 0;

        if (isLunas) {
          color = 'green';
          text = 'LUNAS';
        } else {
          color = 'red';
          text = `-Rp${(debt || 0).toLocaleString()}`;
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  const debt = detail?.total_amount - detail?.paid_amount || 0;

  const Detail = () => (
    <>
      <StudentCard student={detail?.student || null} />

      <Row gutter={[18, 8]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            title="Total Tagihan"
            content={`Rp${(detail?.totalAmount || 0).toLocaleString()}`}
            sideColor="red"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Tagihan SPP"
            content={`Rp${(detail?.sppBillAmount || 0).toLocaleString()}`}
            sideColor="orange"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Tagihan PPDB"
            content={`Rp${(detail?.ppdbAmount || 0).toLocaleString()}`}
            sideColor="blue"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Tagihan Lainnya"
            content={`Rp${(detail?.generalBillAmount || 0).toLocaleString()}`}
            sideColor="yellow"
          />
        </Col>
      </Row>

      <section style={{ marginTop: '2rem' }}>
        <h3>Tunggakan SPP</h3>
        <Table
          columns={tableTunggakanSPP}
          dataSource={detail?.sppBills}
          pagination={false}
        />
      </section>

      <section style={{ marginTop: '2rem' }}>
        <h3>Tunggakan PPDB</h3>
        <Table
          columns={tableTunggakanPPDB}
          dataSource={detail?.ppdbBills}
          pagination={false}
        />
      </section>

      <section style={{ marginTop: '2rem' }}>
        <h3>Tunggakan Lainnya</h3>
        <Table
          columns={tableTunggakanLainnya}
          dataSource={detail?.generalBills}
          pagination={false}
        />
      </section>
    </>
  );

  const drawerTitle = {
    0: `Detail Tagihan`,
  };

  return (
    <Drawer
      title={drawerTitle[displayItem]}
      width={`50vw`}
      visible={visible}
      footer={
        displayItem > 0 ? (
          <Footer
            onCancel={() => setDisplayItem(0)}
            onSubmit={form.submit}
            loadingState={loadingSubmit}
          />
        ) : null
      }
      onClose={onCancel}
      {...rest}
    >
      {loading ? <Loader text="Fetching detail data ..." /> : <Detail />}
    </Drawer>
  );
};

export default DetailSemuaTagihan;

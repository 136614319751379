import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import { CardBase } from 'components/atoms';
import { useStore } from 'store';
import moment from 'moment';
import { themeColor } from 'lib/theme';

const ReviewPenemepatanKelas = ({
  visible,
  onClose,
  table,
  data = [],
  type,
  listKelas = [],
  handleSubmit,
  loadingSubmit,
  ...rest
}) => {
  // console.log(data)
  const [selectedClass, setSelectedClass] = useState({});
  useEffect(() => {
    if (visible) {
      listKelas.length && setSelectedClass(listKelas?.[0] || {});
    }
  }, [visible]);

  return (
    <Modal
      title={'Review Penempatan Kelas Murid'}
      visible={visible}
      onCancel={onClose}
      okButtonProps={{ loading: loadingSubmit }}
      cancelButtonProps={{ loading: loadingSubmit }}
      onOk={() => {
        const studentsNoGrade = data?.noGrade?.length
          ? data?.noGrade.map((el) => el._id)
          : [] || [];
        const studentHasGrade = data?.hasGrade?.length
          ? data?.hasGrade.map((el) => ({
              studentId: el._id,
              gradeId: el.grade,
            }))
          : [] || [];
        const payload = { studentHasGrade, studentsNoGrade };
        console.log('test', payload);
        handleSubmit(payload);
      }}
      {...rest}
      centered
      cancelText="Batal"
      width="70vw"
    >
      <section style={{ margin: '2rem 0' }}>
        <h3>
          Mohon periksa kembali daftar kelas beserta muridnya. Jika sudah benar,
          Anda dapat memproses dengan menekan tombol “Simpan”
        </h3>
        <Row gutter={[16, 0]} style={{ marginTop: '2.8rem' }}>
          <Col span={8}>
            <CardBase
              style={{
                padding: 0,
              }}
              bodyStyle={{
                padding: 0,
                display: 'flex',
                maxHeight: '32.575rem',
              }}
              title="Daftar Kelas"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'scroll',
                  flex: 1,
                }}
              >
                {listKelas.map((item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '8px 20px',
                        justifyContent: 'space-between',
                        backgroundColor:
                          item?._id == selectedClass?._id ? '#E6F0EB' : '',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedClass(item);
                      }}
                    >
                      <div>{item?.name}</div>
                      <div style={{ color: themeColor.primary }}>
                        {data?.hasGrade?.filter((el) => el.grade === item?._id)
                          ?.length || '0'}{' '}
                        Murid
                      </div>
                    </div>
                  );
                })}
              </div>
            </CardBase>
          </Col>
          <Col span={16}>
            <CardBase
              style={{
                padding: 0,
              }}
              bodyStyle={{
                padding: 0,
                display: 'flex',
                maxHeight: '32.575rem',
              }}
              title={`${selectedClass?.name} (${
                data?.hasGrade?.filter((el) => el.grade === selectedClass?._id)
                  ?.length || 0
              } murid)`}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'scroll',
                  flex: 1,
                }}
              >
                {data?.hasGrade
                  ?.filter((el) => el.grade == selectedClass?._id)
                  .map((item) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '8px 20px',
                        }}
                      >
                        <div>{item?.name}</div>
                      </div>
                    );
                  })}
              </div>
            </CardBase>
          </Col>
        </Row>
      </section>
    </Modal>
  );
};

export default ReviewPenemepatanKelas;

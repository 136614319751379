import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Table,
  InputNumber,
  DatePicker,
  Select,
  Alert,
  Modal,
  message,
  Dropdown,
  Menu,
  Input,
  Popover,
  Radio,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { CustomButton, Loader } from 'components/atoms';
import IC_EDIT from 'assets/icons/Edit.svg';
import {
  CalendarOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileOutlined,
  MenuOutlined,
  StarFilled,
} from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import {
  BASE_URL,
  createApresiasi,
  deleteApresiasi,
  deleteGeneralBill,
  editNominal,
  getDetailTagihan,
  updateApresiasi,
} from 'utils/fetch';
import moment from 'moment';
import { themeColor } from 'lib/theme';
import { useStore } from 'store';

const DetailTagihanLainnya = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, refetchData, apresiasiData, ...rest } =
    props;
  const [displayItem, setDisplayItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [detail, setDetail] = useState(null);
  const [billItems, setBillItems] = useState([]);
  const [payments, setPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [dateApresiasi, setDateApresiasi] = useState('');
  const [listApresiasi, setListApresiasi] = useState([]);
  const [editApresiasi, setEditApresiasi] = useState({
    state: false,
    data: null,
  });
  const [selectedPaymentType, setSelectedPaymentType] = useState(1);
  const [selectedAnytimePayment, setSelectedAnytimePayment] = useState(0);

  const { unitId, id } = value;
  const {
    state: { listTahunAjaran },
  } = useStore();

  const ArrayTanggal = Array.from({ length: 31 }, (_, i) => i + 1);

  useEffect(() => {
    if (!visible) setDisplayItem(0);
  }, [visible]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (apresiasiData) {
      setDisplayItem(2);
      setEditApresiasi({ state: true, data: apresiasiData });
    }
  }, [apresiasiData]);

  useEffect(() => {
    if (editApresiasi.state && editApresiasi.data) {
      form.setFieldsValue({
        total_amount: editApresiasi.data?.total_amount,
        notes: editApresiasi.data?.notes,
        date: moment(editApresiasi.data?.date),
      });
      setDateApresiasi(editApresiasi?.data?.date);
    } else {
      form.resetFields();
    }
  }, [editApresiasi]);

  useEffect(() => {
    if (displayItem === 1) {
      const editItems = billItems?.map((item) => ({
        name: item.master_bill_item?.name,
        general_bill_item: item._id,
        total_amount: item.total_amount,
        paid_amount: item.paid_amount,
        new_total_amount: item.total_amount,
      }));

      setTotalAmount(
        editItems.reduce((acc, curr) => acc + (curr.new_total_amount || 0), 0)
      );

      console.log({ detail });

      form.setFieldsValue({
        name: detail?.name,
        date: detail?.date,
        items: editItems,
        school_year: detail?.school_year,
        due_date: detail?.due_date ? moment(detail?.due_date) : null,
        discount: detail?.discount || 0,
        dana_bantuan_siswa: detail?.dana_bantuan_siswa || 0,
        minimum_amount: detail?.minimum_amount || 0,
        fix_payment: detail.fix_payment ? 1 : 0,
        anytime_payment: detail.anytime_payment ? 1 : 0,
      });
      setSelectedPaymentType(detail?.fix_payment ? 1 : 0);
      setSelectedAnytimePayment(detail?.anytime_payment ? 1 : 0);
    }
  }, [displayItem]);

  const fetchData = () => {
    setLoading(true);

    getDetailTagihan(unitId, id, 'general-bill')
      .then((value) => {
        console.log(value, 'this is general bills value');
        setDetail(value.generalBill);
        setPayments(value.payments);
        setBillItems(value.generalBillItems);
        setListApresiasi(value.appreciations);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, 'this is error');
        setLoading(false);
      });
  };

  const submitEdit = (payload) => {
    setLoadingSubmit(true);
    editNominal(unitId, 'general-bill', id, payload)
      .then((res) => {
        console.log(res);
        fetchData();
        refetchData();
        fetchData();
        message.success('Berhasil memperbaharui data tagihan.');
        setDisplayItem(0);
      })
      .catch((err) => {
        console.log(err, 'error submit');
        message.error(
          err?.response?.data?.m || 'Gagal memperbaharui data tagihan'
        );
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Apakah anda yakin akan menghapus tagihan ${detail?.name} ${detail.student.name}?`,
      onOk: () => {
        deleteGeneralBill(unitId, detail?._id)
          .then((res) => {
            console.log(res);
            message.success('Berhasil menghapus data tagihan.');
            refetchData();
            onCancel();
            fetchData();
          })
          .catch((err) => {
            console.log(err, 'error hapus');
            message.error(err.response.data.m);
          });
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
    });
  };

  const tableColumnsItemTagihan = [
    {
      title: 'Item',
      dataIndex: 'master_bill_item',
      key: 'master_bill_item',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        let color;
        let text;
        const debt = record.total_amount - data;
        const isLunas = debt === 0;

        if (isLunas) {
          color = 'green';
          text = 'LUNAS';
        } else {
          color = 'red';
          text = `-Rp${(debt || 0).toLocaleString()}`;
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{(data || 0)?.toLocaleString() || 0}</span>,
    },
  ];

  const tableColumnsItemPayment = [
    {
      title: 'Kode Pembayaran',
      dataIndex: '_id',
      key: '_id',
      render: (data) => <div style={{ width: '15rem' }}>{data || '-'}</div>,
    },
    {
      title: 'Tanggal Pembayaran',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{moment(data).format('DD MMM YYYY') || '-'}</span>
      ),
    },
    {
      title: 'Sumber',
      dataIndex: 'payment_origin',
      key: 'payment_origin',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'paid_items',
      key: 'total_amount',
      render: (data) => {
        const countPaidItmes = data.reduce((acc, curr) => {
          return acc + curr?.amount;
        }, 0);
        return <span>Rp{(countPaidItmes || 0)?.toLocaleString() || 0}</span>;
      },
    },
  ];

  const tableApresiasi = [
    {
      title: 'Tanggal Pemberian',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? new Date(data).toLocaleDateString('ID') : '-'}</span>
      ),
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{Number(data).toLocaleString()}</span>,
    },
    {
      title: 'Catatan',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      render: (data, record) => (
        <Space align="center" size="small">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setDisplayItem(2);
              setEditApresiasi({ state: true, data: record });
            }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => _handleDeleteApresiasi(data)}
          />
        </Space>
      ),
    },
  ];

  const MenuLainnya = (
    <Menu>
      <Menu.Item onClick={() => setDisplayItem(1)}>Edit</Menu.Item>
      <Menu.Item onClick={() => setDisplayItem(2)}>Tambah Apresiasi</Menu.Item>
      <Menu.Item
        danger
        disabled={detail?.paid_amount > 0 || false}
        loading={loadingSubmit}
        onClick={handleDelete}
      >
        Hapus Tagihan
      </Menu.Item>
    </Menu>
  );

  const debt = detail?.total_amount - detail?.paid_amount || 0;

  const Detail = () => (
    <>
      <section style={{ marginBottom: '2rem' }}>
        <header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '0.75rem',
          }}
        >
          <Space>
            <h2 style={{ margin: 0 }}>{detail?.name || '-'}</h2>
          </Space>
          <Space>
            <Button
              icon={<FileDoneOutlined />}
              onClick={() => {
                window.open(
                  BASE_URL(
                    `bill/${unitId}/general-bill/${detail?._id}/download-receipt`
                  ),
                  '_blank'
                );
              }}
            >
              Receipt
            </Button>

            <Dropdown overlay={MenuLainnya}>
              <Button
                icon={<MenuOutlined />}
                // onClick={handleBackToFund}
                // disabled={detail?.payment.payment_origin === 'Manual VA'}
              >
                Menu Lainnya
              </Button>
            </Dropdown>
          </Space>
        </header>
        <Space>
          <span>
            <CalendarOutlined style={{ color: '#1890FF' }} />{' '}
            {new Date(detail?.date || '').toLocaleDateString('ID')}
          </span>
          <span>|</span>
          <span style={{ margin: 0 }}>
            Tanggal Jatuh Tempo: &nbsp;
            {detail?.due_date
              ? new Date(detail?.due_date || '').toLocaleDateString('ID')
              : '-'}
          </span>
          {/* <span>
            <FileOutlined style={{ color: '#1890FF' }} /> SPP2021FK2
          </span> */}
        </Space>
      </section>

      <StudentCard student={detail?.student || null} />

      <Row gutter={[18, 8]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            title="Nominal Diskon"
            content={`Rp${(detail?.discount || 0).toLocaleString()}`}
            sideColor="yellow"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Nominal Dana Bantuan Siswa"
            content={`Rp${(detail?.dana_bantuan_siswa || 0).toLocaleString()}`}
            sideColor="orange"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Nominal Tagihan"
            content={`Rp${(detail?.total_amount || 0).toLocaleString()}`}
            sideColor="blue"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Status Pembayaran"
            content={
              debt === 0 ? 'LUNAS' : `-Rp${(debt || 0).toLocaleString()}`
            }
            sideColor={debt === 0 ? 'green' : 'red'}
            fontColor={debt === 0 ? 'green' : 'red'}
          />
        </Col>
      </Row>

      <section style={{ marginTop: '2rem' }}>
        <h3>Item Tagihan</h3>
        <Table
          columns={tableColumnsItemTagihan}
          dataSource={billItems}
          pagination={false}
        />
      </section>

      <section style={{ marginTop: '2rem' }}>
        <h3>Detail Pembayaran</h3>
        <Table
          columns={tableColumnsItemPayment}
          dataSource={payments}
          pagination={false}
        />
      </section>

      <section style={{ marginTop: '2rem' }}>
        <h3>Apresiasi</h3>
        <Table
          columns={tableApresiasi}
          dataSource={listApresiasi}
          pagination={false}
        />
      </section>
    </>
  );

  const _handleAddApresiasi = async (payload) => {
    try {
      const response = await createApresiasi(unitId, payload);
      if (response) {
        console.log(response);
        fetchData();
        setDisplayItem(0);
        form.resetFields();
      }
    } catch (err) {
      console.log(err, 'error add');
    }
  };

  const _handleEditApresiasi = async (id, payload) => {
    try {
      const response = await updateApresiasi(unitId, id, payload);
      if (response) {
        console.log(response);
        fetchData();
        setDisplayItem(0);
        setEditApresiasi({ state: false, data: null });
        form.resetFields();
      }
    } catch (err) {
      console.log(err, 'error add');
    }
  };

  const _handleDeleteApresiasi = async (id) => {
    try {
      const response = await deleteApresiasi(unitId, id);
      if (response) {
        fetchData();
      }
    } catch (err) {
      console.log(err, 'error delete');
    }
  };

  const drawerTitle = {
    0: `Detail ${detail?.name || ''}`,
    1: `Edit Nominal ${detail?.name || ''}`,
    2: `Tambah Apresiasi`,
  };

  return (
    <Drawer
      title={drawerTitle[displayItem]}
      width={`50vw`}
      visible={visible}
      footer={
        displayItem > 0 ? (
          <Footer
            onCancel={() => setDisplayItem(0)}
            onSubmit={form.submit}
            loadingState={loadingSubmit}
          />
        ) : null
      }
      onClose={onCancel}
      {...rest}
    >
      {loading ? (
        <Loader text="Fetching detail data ..." />
      ) : (
        <>
          {displayItem === 0 && <Detail />}
          {displayItem === 1 && (
            <>
              <Row gutter={[24, 12]} style={{ marginBottom: '1rem' }}>
                <Col span="12">
                  <YellowCard bodyStyle={{ padding: 10 }}>
                    <span>Nama Murid</span>
                    <h2
                      style={{
                        color: themeColor.primary,
                        margin: 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {detail?.student?.name}
                    </h2>
                  </YellowCard>
                </Col>
                <Col span="12">
                  <InfoCard
                    sideColor="blue"
                    title="Total"
                    content={`Rp${(totalAmount || 0).toLocaleString()}`}
                  />
                </Col>
              </Row>

              <Form
                layout="vertical"
                form={form}
                onFinish={(v) => {
                  const items = v.items.map((item) => ({
                    // name: item.name,
                    general_bill_item: item.general_bill_item,
                    new_total_amount: item.new_total_amount || 0,
                  }));
                  const total = v.items.reduce(
                    (curr, acc) => curr + Number(acc.new_total_amount || 0),
                    0
                  );
                  const payload = {
                    name: v?.name,
                    date: detail?.date,
                    total_amount: total,
                    items,
                    school_year: v?.school_year || detail?.school_year,
                    due_date: v.due_date
                      ? moment(v?.due_date).format('MM/DD/YYYY')
                      : null,
                    discount: v?.discount || 0,
                    dana_bantuan_siswa: v?.dana_bantuan_siswa || 0,
                    fix_payment: v?.fix_payment === 1 ? true : false || false,
                    minimum_amount: v?.minimum_amount || 0,
                    anytime_payment: v?.anytime_payment === 1 ? true : false || false,
                  };

                  if (!payload.due_date) {
                    delete payload['due_date'];
                  }

                  // console.log(payload);
                  submitEdit(payload);
                }}
                onValuesChange={(current, values) => {
                  if (current.items) {
                    setTotalAmount(
                      values.items.reduce(
                        (acc, curr) => acc + (curr.new_total_amount || 0),
                        0
                      )
                    );
                  }
                }}
              >
                <InputWrapper type="text" label="Nama Tagihan" name="name" />
                <Form.Item label="Tahun Ajaran" name="school_year">
                  <Select
                    placeholder="Pilih tahun ajaran"
                    style={{ width: '100%' }}
                    // onChange={(e) => setTahunAjaran(e)}
                  >
                    {listTahunAjaran?.map((year) => (
                      <Select.Option value={year._id}>
                        {year.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Tanggal Jatuh Tempo" name="due_date">
                  <DatePicker
                    // disabled={!detail?.due_date}
                    style={{ width: '100%' }}
                    onChange={(_, dateString) => {
                      console.log(dateString);
                    }}
                  />
                </Form.Item>

                <section style={{ marginTop: '2rem' }}>
                  <h3>Lainnya</h3>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        name="discount"
                        label="Nominal Diskon"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                    <Col span={12}>
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        name="dana_bantuan_siswa"
                        label="Nominal Dana Bantuan Siswa"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                  </Row>
                </section>
                <section>
                  <Form.Item
                    label={
                      <span>
                        Tipe Nominal Tagihan &nbsp;{' '}
                        <Popover
                          trigger={['hover']}
                          content={
                            <div>
                              <p>
                                Pilih "Fix Amount" jika nominal tagihan tidak
                                dapat diubah ketika orang tua melakukan
                                pembayaran.
                              </p>
                              <p>
                                Pilih "Variable Amount" jika nominal tagihan
                                dapat diubah ketika orang tua melakukan
                                pembayaran.
                              </p>
                            </div>
                          }
                        >
                          <ExclamationCircleOutlined
                            style={{
                              margin: 0,
                              color: '#4EAFFF',
                              cursor: 'pointer',
                            }}
                          />
                        </Popover>
                      </span>
                    }
                    name="fix_payment"
                    initialValue={1}
                  >
                    <Radio.Group
                      onChange={(e) => setSelectedPaymentType(e.target.value)}
                    >
                      <Radio value={1}>Fix Amount</Radio>
                      <Radio value={0}>Variable Amount</Radio>
                    </Radio.Group>
                  </Form.Item>
                </section>
                <section>
                  <Form.Item
                    label={
                      <span>
                        Pembayaran Kapan Saja &nbsp;{' '}
                        <Popover
                          trigger={['hover']}
                          content={
                            <div>
                              <p>
                                Pilih "Ya" jika tagihan dapat dibayarkan kapan
                                saja.
                              </p>
                              <p>
                                Pilih "Tidak" jika nominal tidak dapat
                                dibayarkan kapan saja.
                              </p>
                            </div>
                          }
                        >
                          <ExclamationCircleOutlined
                            style={{
                              margin: 0,
                              color: '#4EAFFF',
                              cursor: 'pointer',
                            }}
                          />
                        </Popover>
                      </span>
                    }
                    name="anytime_payment"
                    initialValue={0}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setSelectedAnytimePayment(e.target.value)
                      }
                    >
                      <Radio value={1}>Ya</Radio>
                      <Radio value={0}>Tidak</Radio>
                    </Radio.Group>
                  </Form.Item>
                </section>

                {selectedPaymentType === 0 && (
                  <section style={{ marginTop: '2rem' }}>
                    <InputWrapper
                      type="number"
                      prefix="Rp"
                      name="minimum_amount"
                      label="Minimum Pembayaran"
                      formatter={(value) =>
                        `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                    />
                  </section>
                )}

                <Form.List name="items">
                  {(fields, { add, remove }) => {
                    return fields.map((field, idx) => {
                      return (
                        <section>
                          <h3>{billItems[idx].master_bill_item?.name}</h3>
                          <Row gutter={[18, 8]}>
                            <Col span="8">
                              <InputWrapper
                                type="number"
                                prefix="Rp"
                                name="nominal"
                                label="Nominal Tagihan"
                                disabled
                                formItemProps={{
                                  name: [field.name, 'total_amount'],
                                }}
                                formatter={(value) =>
                                  `Rp ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\Rp\s?|(,*)/g, '')
                                }
                              />
                            </Col>
                            <Col span="8">
                              <InputWrapper
                                type="number"
                                prefix="Rp"
                                formItemProps={{
                                  name: [field.name, 'paid_amount'],
                                }}
                                label="Sudah Bayar"
                                disabled
                                formatter={(value) =>
                                  `Rp ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\Rp\s?|(,*)/g, '')
                                }
                              />
                            </Col>
                            <Col span="8">
                              <InputWrapper
                                type="number"
                                prefix="Rp"
                                formItemProps={{
                                  name: [field.name, 'new_total_amount'],
                                }}
                                placeholder="Rp 500.000"
                                label="Ubah Nominal"
                                formatter={(value) =>
                                  `Rp ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\Rp\s?|(,*)/g, '')
                                }
                              />
                            </Col>
                          </Row>
                        </section>
                      );
                    });
                  }}
                </Form.List>
              </Form>

              {/* <section style={{ marginTop: '2rem' }}>
        <Alert
          message="Perhatian!"
          description="Nominal cicilan berikutnya akan menjadi Rp3,700,000"
          type="info"
          showIcon
        />
      </section> */}
            </>
          )}
          {displayItem === 2 && (
            <>
              <YellowCard>
                <span>Nama Murid</span>
                <h2 style={{ color: themeColor.primary, margin: 0 }}>
                  {detail?.student.name}
                </h2>
              </YellowCard>

              <section style={{ margin: '2rem 0' }}>
                <InfoCard
                  sideColor="blue"
                  title="Total"
                  content={`Rp${(totalAmount || 0).toLocaleString()}`}
                />
              </section>
              <Form
                form={form}
                layout="vertical"
                onFinish={(v) => {
                  const payload = {
                    ...v,
                    date: dateApresiasi,
                  };
                  if (editApresiasi.state) {
                    _handleEditApresiasi(editApresiasi?.data?._id, payload);
                  } else {
                    _handleAddApresiasi({
                      ...payload,
                      student: detail?.student?._id,
                      bill_type: 'General',
                      bill_id: detail?._id,
                      unit: unitId,
                    });
                  }
                }}
              >
                <Row gutter={[18, 8]}>
                  <Col span="12">
                    <InputWrapper
                      type="number"
                      prefix="Rp"
                      name="total_amount"
                      label="Nominal"
                      placeholder="Rp 500.000"
                      formatter={(value) =>
                        `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                    />
                  </Col>
                  <Col span="12">
                    <Form.Item label="Tanggal Diberikan" name="date">
                      <DatePicker
                        style={{ width: '100%' }}
                        onChange={(_, dateString) => {
                          setDateApresiasi(dateString);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item name="notes" label="Notes">
                      <Input.TextArea
                        rows={4}
                        placeholder="Masukan catatan"
                        onChange={(e) => {
                          const value = e.target.value;
                          console.log(value);
                          form.setFieldsValue({ notes: value });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </>
      )}
    </Drawer>
  );
};

export default DetailTagihanLainnya;

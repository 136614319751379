import React, { useEffect, useState } from 'react';
import { Col, DatePicker, message, Modal, Row, Select } from 'antd';
import { useStore } from 'store';
import { parsingVABCA, toText } from 'utils/helpers/parse-va';
import moment from 'moment';
import { downloadVADashboard } from 'utils/fetch';
import exportExcel from 'utils/helpers/xlsx';

const DownloadVADashboard = ({
  visible,
  onClose,
  refetchData,
  unitId,
  ...rest
}) => {
  const {
    state: { unitData, userData },
  } = useStore();
  const [parsedData, setParsedData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [unit, setUnit] = useState(null);
  const [cutOff, setCutOff] = useState(null);
  const [loading, setLoading] = useState(false);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  useEffect(() => {
    if (!visible) {
      setParsedData([]);
      setFileData(null);
    }
  }, [visible]);

  useEffect(() => {
    if (userData.type === 2) {
      setUnit(userData.unit._id);
    }
  }, [userData]);

  const handleDownloadVADashboard = () => {
    if (unit && cutOff) {
      setLoading(true);

      const query = `?end_date=${cutOff}`;

      const parsedUnit = arrayUnit.find((item) => item._id === unit);

      downloadVADashboard(parsedUnit._id, query)
        .then((values) => {
          exportExcel(
            values.endResults,
            `Laporan VA Tagihan ${parsedUnit?.name}`
          );
        })
        .catch((err) => {
          console.log(err.response.data?.m, 'error download');
          message.error(
            'Gagal melakukan download data, silakan cek pilihan unit anda.'
          );
        })
        .finally(() => setLoading(false));
    } else {
      message.error('Silakan pilih unit dan tanggal terlebih dahulu');
    }
  };

  const disabled = cutOff && unit ? false : true;

  return (
    <Modal
      title="Download Rekapan Tagihan"
      visible={visible}
      onCancel={onClose}
      centered
      okText="Download"
      cancelText="Batal"
      width="60vw"
      okButtonProps={{
        loading: loading,
        disabled: disabled,
      }}
      cancelButtonProps={{ loading: loading }}
      onOk={() => handleDownloadVADashboard()}
      {...rest}
    >
      <Row gutter={[24, 12]}>
        <Col span="12">
          <label
            htmlFor="unit-selection"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            Pilih Unit
            <Select
              placeholder="Pilih Unit"
              value={unit}
              onChange={(val) => setUnit(val)}
              id="unit-selection"
              allowClear
              disabled={userData.type === 2}
            >
              {arrayUnit?.map((unit) => {
                return (
                  <Select.Option value={unit._id}>{unit.name}</Select.Option>
                );
              })}
            </Select>
          </label>
        </Col>
        <Col span="12">
          <label
            htmlFor="unit-selection"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            Pilih Tanggal Terakhir Cut Off
            <DatePicker
              format={'MM-DD-YYYY'}
              onChange={(_, dateString) => {
                setCutOff(dateString);
              }}
              placeholder="Pilih tanggal"
            />
          </label>
        </Col>
      </Row>
    </Modal>
  );
};

export default DownloadVADashboard;

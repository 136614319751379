import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Table,
  DatePicker,
  Modal,
  message,
  Alert,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import YellowCard from 'components/atoms/card-base/yellow-card';
import {
  alokasiTabungan,
  editTabungan,
  getOutstandingBills,
  getSavingDetails,
  hapusPembayaran,
  refundTabungan,
} from 'utils/fetch';
import { billType } from 'utils/helpers/common';
import { Loader } from 'components/atoms';
import { themeColor } from 'lib/theme';

const DetailTabungan = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, content, refetchData, ...rest } = props;
  const [displayItem, setDisplayItem] = useState(0);
  const [outstandingBills, setOutstandingBills] = useState([]);
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: null,
  });
  const [student, setStudent] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [billsAmount, setBillsAmount] = useState(0);
  const [alokasi, setAlokasi] = useState([]);
  const { studentId = '', unitId = '' } = value;
  const [refundValue, setRefundValue] = useState(0);
  const [afterSubmit, setAfterSubmit] = useState({
    done: false,
    success: [],
    error: [],
  });

  useEffect(() => {
    if (!visible) setDisplayItem(0);
    form.resetFields();
    setRefundValue(0);
    setAfterSubmit({
      done: false,
      success: [],
      error: [],
    });
  }, [visible]);

  useEffect(() => {
    if (visible) {
      fetchDetails();
      fetchOutstandingBills();
    } else {
      setIsEdit({ state: false, data: null });
    }

    return () => {};
  }, [visible, content]);

  useEffect(() => {
    console.log(refundValue);
  }, [refundValue]);

  const fetchOutstandingBills = () => {
    getOutstandingBills(studentId.id)
      .then((res) => {
        setOutstandingBills(res.bills);
        console.log(res);
        const allocationObj = res.bills.map((bill) => ({
          _id: bill._id,
          allocation: 0,
          remaining: bill.remaining,
          type: bill.type,
          date: moment(),
        }));
        form.setFieldsValue({ bills: allocationObj });
        setAlokasi(allocationObj);
        setBillsAmount(res.billsAmount);
      })
      .catch((err) => console.log(err, 'error fetch outstanding bills'));
  };

  const fetchDetails = () => {
    setLoading(true);
    const state = content === 0 ? 'incoming' : 'allocation';

    getSavingDetails(unitId, studentId.id, state)
      .then((value) => {
        console.log(value, 'this is detail tabungan');
        if (state === 'allocation') {
          setTableData(value.allocations);
        } else {
          setTableData(value.incomingSavings);
        }

        setStudent(value.student);
      })
      .catch((err) => {
        console.log(err.response, 'error details');
      })
      .finally(() => setLoading(false));
  };

  const tableColumnRiwayat = [
    {
      title: 'Tanggal Alokasi',
      dataIndex: 'date',
      key: 'date',
      render: (data) => <span>{new Date(data).toLocaleDateString()}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>{`Rp${(data || 0).toLocaleString()}`}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id, record) => (
        <Space>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              hapusDataAlokasi(id, record.bill_type, record.name);
            }}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const tableColumnRincian = [
    {
      title: 'Tanggal Masuk Tabungan',
      dataIndex: 'date',
      key: 'date',
      render: (data) => <span>{new Date(data).toLocaleDateString()}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>Rp{data.toLocaleString('')}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => setIsEdit({ state: true, data: record })}
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() =>
              hapusDataAlokasi(record._id, record.bill_type, record.name)
            }
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const hapusDataAlokasi = (paymentId, type, name) => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin membatalkan alokasi tabungan ${student.name} untuk ${name}?`,
      content: `Jika Anda membatalkan alokasi akan menambah tunggakan murid, dan dana alokasi akan dikembalikan ke tabungan.`,
      onOk: () => {
        hapusPembayaran(unitId, paymentId, billType[type])
          .then((res) => {
            console.log(res);
            fetchDetails();
            refetchData();
            message.success('Berhasil menghapus alokasi');
          })
          .catch((err) => {
            console.log(err.response, 'error hapus');
            message.error(err?.response?.data?.m || 'Gagal menghapus alokasi');
          });
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
    });
  };

  const InfoMurid = () => (
    <>
      <StudentCard student={student} />

      <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            title="Total Tabungan"
            content={`Rp${(student?.savings || 0).toLocaleString()}`}
            sideColor="blue"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Total Tunggakan"
            content={`-Rp${(billsAmount || 0).toLocaleString()}`}
            sideColor="red"
            fontColor="red"
          />
        </Col>
      </Row>
    </>
  );

  const Detail = () => (
    <>
      <InfoMurid />
      <section style={{ marginTop: '2rem' }}>
        <h3>
          {content === 1
            ? 'Riwayat Alokasi Tabungan'
            : 'Rincian Tabungan Masuk'}
        </h3>
        <Table
          columns={content === 1 ? tableColumnRiwayat : tableColumnRincian}
          dataSource={tableData}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            // total: totalData && totalData,
            // onChange: (page) => {
            //   setCurrentPage(page);
            // },
            // current: currentPage,
          }}
          scroll={{ x: 500 }}
        />
      </section>
    </>
  );

  const handleSubmitEdit = (values) => {
    editTabungan(unitId, isEdit.data._id, values)
      .then((res) => {
        console.log(res, 'edit tabungan');
        message.success('Berhasil edit tabungan');
        refetchData();
        onCancel();
        setIsEdit({ state: false, data: null });
      })
      .catch((err) => {
        console.log(err?.response?.data?.m || 'Gagal edit tabungan');
        message.error(err?.response?.data?.m || 'Gagal edit tabungan');
      });
  };

  const Edit = () => (
    <>
      <YellowCard>
        <span>Nama Murid</span>
        <h2 style={{ color: themeColor.primary, margin: 0 }}>
          {student?.name}
        </h2>
      </YellowCard>
      <Row gutter={[24, 12]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            sideColor="blue"
            title="Total Tabungan"
            content={`Rp${(student?.savings || 0).toLocaleString()}`}
          />
        </Col>
        <Col span="12">
          <InfoCard
            sideColor="green"
            title="Total Dana Masuk Belum Alokasi"
            content={`Rp${(student?.funds || 0).toLocaleString()}`}
          />
        </Col>
      </Row>

      <Form
        form={form}
        layout="vertical"
        onFinish={(val) => {
          // console.log(val, 'value edit');
          const payload = {
            ...val,
            date: moment(val.date).format('DD-MM-YYYY'),
          };
          console.log(payload);
          handleSubmitEdit(payload);
        }}
      >
        <section style={{ marginTop: '2rem' }}>
          <Row gutter={[18, 8]}>
            <Col span="12">
              <Form.Item label="Tanggal Alokasi" name="date">
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(_, dateString) => {
                    // form.setFieldsValue({ date: dateString });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <InputWrapper
                type="number"
                prefix="Rp"
                name="total_amount"
                label="Nominal"
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
              />
            </Col>
          </Row>
        </section>
      </Form>

      {/* <section style={{ marginTop: '2rem' }}>
        <Alert
          message="Perhatian!"
          description="Nominal cicilan berikutnya akan menjadi Rp3,700,000"
          type="info"
          showIcon
        />
      </section> */}
    </>
  );

  const Alokasi = () => (
    <>
      <InfoMurid />
      <Form
        layout="vertical"
        form={form}
        onFinish={(v) => {
          const bills = v.bills?.map((bill) => ({
            _id: bill._id,
            type: bill.type,
            allocation: Number(bill.allocation),
            date: bill.date,
          }));
          const total = bills?.reduce(
            (curr, acc) => curr + Number(acc.allocation),
            0
          );

          const payload = {
            bills: bills.filter((bill) => bill.allocation > 0),
            total_allocation: total,
          };

          if (payload.total_allocation > 0) {
            submitAlokasi(payload);
          } else {
            message.error('Silakan isi nominal alokasi terlebih dahulu');
          }
        }}
      >
        <Form.List name="bills">
          {(fields, { add, remove }) => {
            return fields.map((field, idx) => {
              return (
                <section style={{ marginTop: '2rem' }}>
                  <h3>Alokasi ke {outstandingBills[idx]?.name || '-'}</h3>
                  <Row gutter={[18, 8]}>
                    <Col span="8">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        formItemProps={{
                          name: [field.name, 'remaining'],
                        }}
                        // defaultValue={student?.funds || 0}
                        label="Total Tagihan"
                        disabled
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                    <Col span="8">
                      <Form.Item
                        label="Tanggal Alokasi"
                        name={[field.name, 'date']}
                      >
                        <DatePicker
                          // defaultValue={moment()}
                          format="DD/MM/YYYY"
                          style={{ width: '100%' }}
                          onChange={(_, dateString) => {
                            const fieldValue =
                              form.getFieldValue('bills')[field.key];
                            console.log(dateString, fieldValue);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span="8">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        formItemProps={{
                          name: [field.name, 'allocation'],
                        }}
                        label="Nominal Alokasi"
                        placeholder="Rp 500.000"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                  </Row>
                </section>
              );
            });
          }}
        </Form.List>
      </Form>
    </>
  );

  const submitAlokasi = (payload) => {
    setLoadingSubmit(true);
    alokasiTabungan(unitId, studentId.id, payload)
      .then(({ value }) => {
        console.log(value);
        setAfterSubmit({
          ...value.conditions,
          done: true,
        });
        message.success('Berhasil alokasi tabungan');
        fetchDetails();
        fetchOutstandingBills();
        refetchData();
        // onCancel();
      })
      .catch((err) => {
        console.log(err.response, 'this is error alokasi');
        message.error(err?.response?.data?.m || 'Gagal alokasi tabungan');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const submitRefund = (payload) => {
    setLoadingSubmit(true);
    refundTabungan(unitId, payload)
      .then((res) => {
        console.log(res);
        refetchData();
        fetchDetails();
        onCancel();
        message.success('Berhasil refund dana tabungan');
      })
      .catch((err) => {
        console.log(err.response, 'error refund');
        message.error(err?.response?.data?.m || 'Gagal refund dana tabungan');
      })
      .finally(() => setLoadingSubmit(false));
  };

  let drawerTitle = '';
  console.log(content);
  switch (content) {
    case 0:
      drawerTitle = 'Detail Tabungan Murid';
      break;
    case 1:
      drawerTitle = 'Riwayat Alokasi Tabungan';
      break;
    case 2:
      drawerTitle = 'Alokasi Tabungan Murid';
      break;
    case 3:
      drawerTitle = 'Refund Tabungan Murid';
      break;
    default:
      drawerTitle = '';
  }

  return (
    <Drawer
      title={drawerTitle}
      width={content === 3 ? '40.8rem' : `60vw`}
      visible={visible}
      footer={
        isEdit.state || content > 1 ? (
          <Footer
            CustomOk={content === 3 ? 'Refund' : ''}
            loadingState={loadingSubmit}
            submitDisabled={content === 3 ? refundValue <= 0 : false}
            onCancel={() => {
              if (isEdit.state) {
                setIsEdit({ state: false, data: null });
              } else {
                onCancel();
              }
            }}
            onSubmit={() => {
              if (content === 2) {
                form.submit();
              } else if (content === 3) {
                const payload = {
                  unit: unitId,
                  student: student._id,
                  date: new Date().toLocaleDateString(),
                  total_amount: refundValue,
                  refund_origin: 'Tabungan',
                  notes: 'alasan',
                };
                console.log(payload);
                submitRefund(payload);
              } else if (isEdit.state) {
                form.submit();
              }
            }}
          />
        ) : null
      }
      onClose={onCancel}
      {...rest}
    >
      {loading ? (
        <Loader text="Fetching details ..." />
      ) : (
        <>
          {afterSubmit?.done && (
            <section style={{ marginBottom: '2rem' }}>
              {afterSubmit?.success?.length && !afterSubmit?.error?.length && (
                <Alert
                  type="success"
                  message={
                    <span style={{ fontWeight: 600 }}>Pembayaran Berhasil</span>
                  }
                  description={
                    <>
                      {afterSubmit.success.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                    </>
                  }
                  showIcon
                  closable
                  onClose={() =>
                    setAfterSubmit({
                      done: false,
                      success: [],
                      error: [],
                    })
                  }
                />
              )}
              {!afterSubmit?.success?.length && afterSubmit?.error?.length && (
                <Alert
                  type="error"
                  message={
                    <span style={{ fontWeight: 600 }}>Pembayaran Gagal</span>
                  }
                  description={
                    <>
                      {afterSubmit.error.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                    </>
                  }
                  showIcon
                  closable
                  onClose={() =>
                    setAfterSubmit({
                      done: false,
                      success: [],
                      error: [],
                    })
                  }
                />
              )}
              {afterSubmit?.success?.length && afterSubmit?.error?.length ? (
                <Alert
                  type="warning"
                  message={
                    <span style={{ fontWeight: 600 }}>
                      Sebagian Pembayaran Berhasil
                    </span>
                  }
                  description={
                    <>
                      {afterSubmit.success.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                      {afterSubmit.error.map((msg) => (
                        <p style={{ margin: 0 }}>{msg}</p>
                      ))}
                    </>
                  }
                  showIcon
                  closable
                  onClose={() =>
                    setAfterSubmit({
                      done: false,
                      success: [],
                      error: [],
                    })
                  }
                />
              ) : null}
            </section>
          )}

          {isEdit.state && <Edit />}
          {content < 2 && !isEdit.state ? <Detail /> : null}
          {content === 2 && !isEdit.state ? <Alokasi /> : null}
          {content === 3 && !isEdit.state ? (
            <>
              <YellowCard>
                <span>Nama Murid</span>
                <h2 style={{ color: themeColor.primary, margin: 0 }}>
                  {student?.name || '-'}
                </h2>
              </YellowCard>

              <section style={{ margin: '2rem 0' }}>
                <InfoCard
                  sideColor="blue"
                  title="Total"
                  content={`Rp${(student?.savings || 0).toLocaleString()}`}
                />
              </section>
              <Form form={form} layout="vertical">
                <InputWrapper
                  type="number"
                  prefix="Rp"
                  name="total_amount"
                  label="Nominal Refund"
                  onChange={(e) => setRefundValue(e)}
                  formatter={(value) =>
                    `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                />
              </Form>
            </>
          ) : null}
        </>
      )}
    </Drawer>
  );
};

export default DetailTabungan;

import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Select,
  Alert,
  DatePicker,
  message,
  Radio,
  Popover,
} from 'antd';
import moment from 'moment';

import { Footer } from './shared';
import { InputWrapper } from '..';
import {
  addSppBill,
  getDetailPembayaran,
  getUnbillMonth,
  refundPembayaran,
} from 'utils/fetch';
import { Loader } from 'components/atoms';
import { useStore } from 'store';
import { monthDictionary } from 'utils/helpers/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const TambahTagihanSPP = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, refetchData, ...rest } = props;
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState('');
  const [tahunAjaran, setTahunAjaran] = useState('');
  const [billMonths, setBillMonths] = useState([]);
  const [sppItems, setSppItems] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const { unitId = '', studentId = '', type = '' } = value;
  const {
    state: { listTahunAjaran },
  } = useStore();

  const [selectedPaymentType, setSelectedPaymentType] = useState(1);
  const [selectedAnytimePayment, setSelectedAnytimePayment] = useState(0);

  useEffect(() => {
    if (studentId && tahunAjaran && unitId) {
      fetchData(tahunAjaran);
    } else {
      setDetail(null);
      form.resetFields();
    }
  }, [unitId, studentId, tahunAjaran]);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({ paid_amount: detail?.payment?.total_amount });
    }
  }, [detail]);

  const fetchData = (schoolYearId) => {
    setLoading(true);
    getUnbillMonth(unitId, studentId, schoolYearId)
      .then((res) => {
        console.log(res, 'unbill month');
        // setDetail(res);
        setBillMonths(res.value?.months);
        const items = res.value?.items?.map((item) => ({
          master_bill_item: item?.master_bill_item?._id,
          name: item?.master_bill_item?.name,
          total_amount: 0,
        }));
        setSppItems(items);
        form.setFieldsValue({ items: items });
      })
      .catch((err) => console.log(err.response, 'error fetch detail'))
      .finally(() => setLoading(false));
  };

  const handleSubmit = (payload) => {
    setLoadingSubmit(true);
    addSppBill(unitId, studentId, payload)
      .then((res) => {
        console.log(res);
        onCancel();
        // window.location.reload();
        refetchData();
        message.success('Berhasil menambahkan tagihan Uang Sekolah');
      })
      .catch((err) => {
        console.log(err, 'error refund pembayaran');
        message.error(err?.data?.m || 'Gagal menambahkan tagihan Uang Sekolah');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const drawerTitle = 'Tambah Tagihan Uang Sekolah';

  return (
    <Drawer
      title={drawerTitle}
      width={`50vw`}
      visible={visible}
      footer={
        <Footer
          onCancel={() => onCancel()}
          onSubmit={form.submit}
          loadingState={loadingSubmit}
          submitDisabled={!selectedMonth && !tahunAjaran}
        />
      }
      onClose={onCancel}
      {...rest}
    >
      <>
        <h2>Tahun Ajaran</h2>
        <Row gutter={[18, 0]}>
          <Col span="12">
            <Select
              value={tahunAjaran}
              placeholder="Pilih tahun ajaran"
              style={{ width: '100%' }}
              onChange={(e) => setTahunAjaran(e)}
              allowClear
              onClear={() => setTahunAjaran('')}
            >
              {listTahunAjaran?.map((year) => (
                <Select.Option value={year._id}>{year.name}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span="12">
            <Select
              placeholder="Pilih bulan tagihan"
              style={{ width: '100%' }}
              onChange={(e) => setSelectedMonth(e)}
              value={selectedMonth}
              allowClear
              onClear={() => setSelectedMonth('')}
            >
              {billMonths?.map((month) => (
                <Select.Option value={month}>
                  {moment(month).format('MMMM YYYY')}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={(val) => {
            const payload = {
              school_year: tahunAjaran,
              unit: unitId,
              student: studentId,
              month: selectedMonth,
              total_amount: val.items?.reduce(
                (curr, acc) => curr + (acc?.total_amount || 0),
                0
              ),
              items: val.items?.map((item) => ({
                master_bill_item: item.master_bill_item,
                total_amount: item.total_amount,
              })),
              dana_bantuan_siswa: val.dana_bantuan_siswa || 0,
              discount: val.discount || 0,
              fix_payment: val?.fix_payment === 1 ? true : false || false,
              minimum_amount: val?.minimum_amount || 0,
              anytime_payment:
                val?.anytime_payment === 1 ? true : false || false,
            };
            handleSubmit(payload);
          }}
          initialValues={{
            items: [],
            dana_bantuan_siswa: 0,
            discount: 0,
          }}
        >
          <section style={{ marginTop: '2rem' }}>
            <h2>Lainnya</h2>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <InputWrapper
                  type="number"
                  prefix="Rp"
                  name="discount"
                  label="Nominal Diskon"
                  formatter={(value) =>
                    `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                />
              </Col>
              <Col span={12}>
                <InputWrapper
                  type="number"
                  prefix="Rp"
                  name="dana_bantuan_siswa"
                  label="Nominal Dana Bantuan Siswa"
                  formatter={(value) =>
                    `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                />
              </Col>
            </Row>
          </section>{' '}
          <section>
            <Form.Item
              label={
                <span>
                  Tipe Nominal Tagihan &nbsp;{' '}
                  <Popover
                    trigger={['hover']}
                    content={
                      <div>
                        <p>
                          Pilih "Fix Amount" jika nominal tagihan tidak dapat
                          diubah ketika orang tua melakukan pembayaran.
                        </p>
                        <p>
                          Pilih "Variable Amount" jika nominal tagihan dapat
                          diubah ketika orang tua melakukan pembayaran.
                        </p>
                      </div>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        margin: 0,
                        color: '#4EAFFF',
                        cursor: 'pointer',
                      }}
                    />
                  </Popover>
                </span>
              }
              name="fix_payment"
              initialValue={1}
            >
              <Radio.Group
                onChange={(e) => setSelectedPaymentType(e.target.value)}
              >
                <Radio value={1}>Fix Amount</Radio>
                <Radio value={0}>Variable Amount</Radio>
              </Radio.Group>
            </Form.Item>
          </section>{' '}
          <section>
            <Form.Item
              label={
                <span>
                  Pembayaran Kapan Saja &nbsp;{' '}
                  <Popover
                    trigger={['hover']}
                    content={
                      <div>
                        <p>
                          Pilih "Ya" jika tagihan dapat dibayarkan kapan saja.
                        </p>
                        <p>
                          Pilih "Tidak" jika nominal tidak dapat dibayarkan
                          kapan saja.
                        </p>
                      </div>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        margin: 0,
                        color: '#4EAFFF',
                        cursor: 'pointer',
                      }}
                    />
                  </Popover>
                </span>
              }
              name="anytime_payment"
              initialValue={0}
            >
              <Radio.Group
                onChange={(e) => setSelectedAnytimePayment(e.target.value)}
              >
                <Radio value={1}>Ya</Radio>
                <Radio value={0}>Tidak</Radio>
              </Radio.Group>
            </Form.Item>
          </section>
          {selectedPaymentType === 0 && (
            <section style={{ marginTop: '2rem' }}>
              <InputWrapper
                type="number"
                prefix="Rp"
                name="minimum_amount"
                label="Minimum Pembayaran"
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
              />
            </section>
          )}
          <section style={{ marginTop: '2rem' }}>
            <h2>Item Tagihan</h2>
            <Form.List name="items">
              {(fields, { add, remove }) => {
                return fields.map((field, idx) => {
                  return (
                    <section>
                      <Row gutter={[18, 8]}>
                        <Col span="12">
                          <InputWrapper
                            type="text"
                            name="name"
                            placeholder="Item tagihan"
                            label="Item Tagihan"
                            disabled
                            formItemProps={{
                              name: [field.name, 'name'],
                            }}
                          />
                        </Col>
                        <Col span="12">
                          <InputWrapper
                            type="number"
                            prefix="Rp"
                            name="total_amount"
                            label="Nominal Tagihan"
                            formItemProps={{
                              name: [field.name, 'total_amount'],
                            }}
                            formatter={(value) =>
                              `Rp ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )
                            }
                            parser={(value) =>
                              value.replace(/\Rp\s?|(,*)/g, '')
                            }
                          />
                        </Col>
                      </Row>
                    </section>
                  );
                });
              }}
            </Form.List>
          </section>
        </Form>
      </>
    </Drawer>
  );
};

export default TambahTagihanSPP;

import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Drawer } from 'components/shared';
import moment from 'moment';
import { useStore } from 'store';
import { deleteRefundData, fetchTahunAjaran, studentTab } from 'utils/fetch';

const { Dropdown, Button, Table, Menu, Tag, Space } = require('antd');
const { useState, useEffect } = require('react');

const DetailMuridRefund = ({ studentId, unitId }) => {
  const {
    state: { activeSchoolYear },
  } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    id: '',
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    studentTab(studentId, 'refund-list')
      .then((value) => {
        console.log(value, 'refund instalments');
        setData(value.refundList);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => setLoading(false));
  };

  const _handleDeleteRefund = (id) => {
    deleteRefundData(unitId, id)
      .then((res) => {
        console.log(res);
        fetchData();
      })
      .catch((err) => {
        console.log(err, 'error refund delete');
      });
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Status Refund',
      dataIndex: 'canceled_status',
      key: 'canceled_status',
      render: (data) => (
        <Tag color={data ? 'red' : 'green'}>{data ? 'Canceled' : 'Active'}</Tag>
      ),
    },
    {
      title: 'Tanggal Refund',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Jumlah Refund',
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: 200,
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Alasan Refund',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (id, record) => (
        <Space>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => _handleDeleteRefund(id)}
            disabled={record?.canceled_status}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <article>
        <Table
          columns={tableColumns}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
          }}
          // scroll={{ x: 1500 }}
          loading={loading}
        />
      </article>

      <Drawer.DetailTagihanLainnya
        visible={openDrawer.state}
        onCancel={() => {
          setOpenDrawer({
            state: false,
            id: '',
          });
        }}
        value={{ id: openDrawer.id, unitId }}
      />
    </>
  );
};

export default DetailMuridRefund;

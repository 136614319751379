import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Space,
  message,
  Input,
  Select,
  List,
  Checkbox,
  Steps,
  Card,
  Divider,
  DatePicker,
} from 'antd';
import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory } from 'react-router-dom';
import { InputWrapper, Modal } from 'components/shared';
import { useStore } from 'store';
import {
  getListKelasId,
  getMuridByGrade,
  submitStudentGraduate,
  tambahDanaMasuk,
} from 'utils/fetch';
import { CardBase, Loader } from 'components/atoms';

const TambahDanaMasuk = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    state: { listTahunAjaran, selectedUnit, activeSchoolYear },
  } = useStore();
  const [current, setCurrent] = useState(0);
  const [listMurid, setListMurid] = useState([]);
  const [listKelas, setListKelas] = useState([]);
  const [filterKelas, setFilterKelas] = useState([]);
  const [studentByGrade, setStudentByGrade] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState(listMurid);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [dateString, setDateString] = useState('');
  const [tahunAjaran, setTahunAjaran] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [hasNoGrade, setHasNoGrade] = useState([]);
  const [notActive, setNotActive] = useState([]);
  const unitId = selectedUnit?._id;

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setTahunAjaran(activeSchoolYear?._id);
      fetchData();
      // console.log(activeSchoolYear, 'this is school year');
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    if (nameSearch) {
      const filtered = listMurid.filter((murid) =>
        murid?.name
          .toLowerCase()
          .trim()
          .includes(nameSearch.toLowerCase().trim())
      );
      console.log(listMurid, 'test');

      setFilteredStudent(filtered);
    } else {
      setFilteredStudent(listMurid);
    }
  }, [nameSearch]);

  useEffect(() => {
    if (filterKelas.length && studentByGrade) {
      const murid = studentByGrade.map((el) => {
        const inFilter = filterKelas.some((fil) => fil === el._id);
        if (inFilter) {
          return el.students;
        } else {
          return;
        }
      });

      if (filterKelas.some((fil) => fil === 'Murid Tanpa Kelas')) {
        murid.push(hasNoGrade);
      }

      if (filterKelas.some((fil) => fil === 'graduated')) {
        murid.push(notActive);
      }

      const flattened = murid.flatMap((x) => x).filter((y) => y !== undefined);

      setListMurid(flattened);
      setFilteredStudent(flattened);
    } else {
      setListMurid([]);
      setFilteredStudent([]);
    }
  }, [filterKelas, studentByGrade]);

  const handleSubmit = (payload) => {
    setLoadingSubmit(true);
    tambahDanaMasuk(unitId, payload)
      .then((val) => {
        console.log(val);
        message.success('Berhasil menambahkan dana masuk murid.');
        history.push(`/dana-masuk?unit=${selectedUnit.name}`);
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.m || 'Gagal menambahkan dana masuk murid.'
        );
        console.log(err.response);
      })
      .finally(() => setLoadingSubmit(false));
  };

  const fetchData = () => {
    setLoading(true);
    getMuridByGrade(unitId, activeSchoolYear?._id)
      .then((res) => {
        console.log(res, 'this dana masuk');
        setStudentByGrade(res.grades);
        setHasNoGrade(res.studentsNoGrades);
        setNotActive(res.studentsNotActive);
      })
      .catch((err) => {
        console.log(err.response, 'this is error fetch');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (listKelas || listKelas.length) {
      setLoading(false);
      setFilterKelas([listKelas[0]?._id]);
    }
  }, [listKelas]);

  const InputNominal = () => (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={(v) => {
          const payload = selectedStudent.map((el, i) => ({
            ...el,
            ...v,
            amount: Number(v.amount),
            date: dateString,
          }));
          console.log(payload);
          handleSubmit({
            newIncomingFunds: payload,
          });
        }}
        onValuesChange={(changedValue, value) => {
          if (value?.amount && value?.date) {
            setDisabledButton(false);
          } else {
            setDisabledButton(true);
          }
        }}
      >
        <section style={{ margin: '2rem 0' }}>
          <Row gutter={[8, 12]}>
            <Col span="12">
              <InputWrapper
                label="Nominal Dana Masuk"
                type="number"
                name=""
                prefix="Rp"
                name="amount"
                placeholder="Rp 500.000"
                formatter={(value) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
              />
            </Col>
            <Col span="12">
              <Form.Item label="Tanggal Dana Masuk" name="date">
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(_, date) => setDateString(date)}
                />
              </Form.Item>
            </Col>
          </Row>
        </section>
        <section style={{ width: '100%' }}>
          <Form.Item label="Catatan" name="notes">
            <Input.TextArea
              style={{ width: '100%' }}
              rows={3}
              placeholder="Tulis catatan"
            />
          </Form.Item>
        </section>
      </Form>
    </>
  );

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Tambah Dana Masuk</h2>
        <section style={{ marginTop: '2rem', marginBottom: '6rem' }}>
          <Steps current={current}>
            <Steps.Step title="Pilih tahun ajaran & murid" />
            <Steps.Step title="Input Nominal" />
          </Steps>
        </section>

        {loading ? (
          <Loader text="Fetching initial data ..." />
        ) : (
          <>
            {current === 0 ? (
              <>
                <div style={{ marginTop: '2rem', paddingBottom: '4rem' }}>
                  {studentByGrade ? (
                    <Row gutter={[24, 14]}>
                      <Col span="8">
                        <CardBase
                          style={{
                            overflowY: 'auto',
                          }}
                          bodyStyle={{
                            padding: 0,
                            display: 'flex',
                            maxHeight: '32.575rem',
                            flexDirection: 'column',
                          }}
                          title="Daftar Kelas"
                        >
                          <>
                            <Checkbox
                              onChange={() => {
                                if (
                                  filterKelas.length ===
                                  studentByGrade.length + 2
                                ) {
                                  setFilterKelas([]);
                                } else {
                                  const allGradesId = studentByGrade?.map(
                                    (grd) => grd._id
                                  );
                                  setFilterKelas([
                                    ...allGradesId,
                                    'Murid Tanpa Kelas',
                                    'graduated',
                                  ]);
                                }
                              }}
                              checked={
                                filterKelas.length === studentByGrade.length + 2
                              }
                              style={{ margin: '1rem 0' }}
                            >
                              Pilih Semua Kelas
                            </Checkbox>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '1rem 0',
                              }}
                            >
                              <Checkbox
                                checked={filterKelas?.some(
                                  (el) => el === 'graduated'
                                )}
                                onChange={() => {
                                  if (
                                    filterKelas?.some(
                                      (el) => el === 'graduated'
                                    )
                                  ) {
                                    const filtered = filterKelas.filter(
                                      (el) => el !== 'graduated'
                                    );
                                    setFilterKelas(filtered);
                                  } else {
                                    setFilterKelas([
                                      ...filterKelas,
                                      'graduated',
                                    ]);
                                  }
                                }}
                              >
                                Lulus
                              </Checkbox>
                              <span>{notActive?.length || 0} Murid</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '1rem 0',
                              }}
                            >
                              <Checkbox
                                checked={filterKelas?.some(
                                  (el) => el === 'Murid Tanpa Kelas'
                                )}
                                onChange={() => {
                                  if (
                                    filterKelas?.some(
                                      (el) => el === 'Murid Tanpa Kelas'
                                    )
                                  ) {
                                    const filtered = filterKelas.filter(
                                      (el) => el !== 'Murid Tanpa Kelas'
                                    );
                                    setFilterKelas(filtered);
                                  } else {
                                    setFilterKelas([
                                      ...filterKelas,
                                      'Murid Tanpa Kelas',
                                    ]);
                                  }
                                }}
                              >
                                Tanpa Kelas
                              </Checkbox>
                              <span>{hasNoGrade?.length || 0} Murid</span>
                            </div>

                            {studentByGrade?.map((grade) => {
                              const checked = filterKelas?.some(
                                (el) => el === grade._id
                              );
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '1rem 0',
                                  }}
                                >
                                  <Checkbox
                                    checked={checked}
                                    onChange={() => {
                                      if (checked) {
                                        const filtered = filterKelas.filter(
                                          (el) => el !== grade._id
                                        );
                                        setFilterKelas(filtered);
                                      } else {
                                        setFilterKelas([
                                          ...filterKelas,
                                          grade._id,
                                        ]);
                                      }
                                    }}
                                  >
                                    {grade.name}
                                  </Checkbox>
                                  <span>{grade.students?.length} Murid</span>
                                </div>
                              );
                            })}
                          </>
                        </CardBase>
                      </Col>
                      <Col span="16">
                        <CardBase
                          style={{
                            overflowY: 'auto',
                          }}
                          bodyStyle={{
                            padding: '16px 0',
                            display: 'flex',
                            maxHeight: '38.375rem',
                            flexDirection: 'column',
                          }}
                        >
                          <Input.Search
                            placeholder="Cari nama murid"
                            onChange={(e) => setNameSearch(e.target.value)}
                          />
                          {filteredStudent.length ? (
                            <section style={{ margin: '1rem 0' }}>
                              <h4>Murid Terpilih: {selectedStudent.length}</h4>
                              <Checkbox
                                onChange={() => {
                                  // const all = listMurid.map((el) => el._id);
                                  if (
                                    listMurid.length === selectedStudent.length
                                  ) {
                                    setSelectedStudent([]);
                                  } else {
                                    const students = listMurid.map((murid) => ({
                                      ...murid,
                                      studentId: murid._id,
                                    }));
                                    setSelectedStudent(students);
                                  }
                                }}
                                checked={
                                  listMurid.length === selectedStudent.length
                                }
                                style={{ margin: '1rem 0' }}
                              >
                                Pilih Semua Murid
                              </Checkbox>
                              <List
                                dataSource={filteredStudent}
                                renderItem={(item) => {
                                  const checked = selectedStudent.some(
                                    (el) => el.studentId === item._id
                                  );

                                  return (
                                    <List.Item
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        if (checked) {
                                          const filtered =
                                            selectedStudent.filter(
                                              (el) => el.studentId !== item._id
                                            );
                                          setSelectedStudent(filtered);
                                        } else {
                                          setSelectedStudent([
                                            ...selectedStudent,
                                            {
                                              studentId: item._id,
                                            },
                                          ]);
                                        }
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: '100%',
                                          gap: '.8rem',
                                        }}
                                      >
                                        <Checkbox
                                          checked={checked}
                                          onChange={() => {
                                            if (checked) {
                                              const filtered =
                                                selectedStudent.filter(
                                                  (el) =>
                                                    el.studentId !== item._id
                                                );
                                              setSelectedStudent(filtered);
                                            } else {
                                              setSelectedStudent([
                                                ...selectedStudent,
                                                {
                                                  studentId: item._id,
                                                },
                                              ]);
                                            }
                                          }}
                                        />
                                        {item.name}
                                      </div>
                                    </List.Item>
                                  );
                                }}
                              />
                            </section>
                          ) : (
                            <div style={{ marginTop: '1rem' }}>
                              Tidak ada murid di kelas ini
                            </div>
                          )}
                        </CardBase>
                      </Col>
                    </Row>
                  ) : (
                    <Loader text="Fetch data murid..." />
                  )}
                </div>
              </>
            ) : (
              <InputNominal />
            )}
          </>
        )}

        <StyledFixedFooter>
          <Space>
            <Button
              loading={loadingSubmit}
              onClick={() => {
                if (current === 0) {
                  history.goBack();
                } else {
                  setCurrent(current - 1);
                }
              }}
            >
              Kembali
            </Button>
            {current === 0 ? (
              <Button
                disabled={!selectedStudent.length}
                type="primary"
                onClick={() => setCurrent(current + 1)}
              >
                Berikutnya
              </Button>
            ) : (
              <Button
                loading={loadingSubmit}
                disabled={disabledButton}
                type="primary"
                onClick={() => form.submit()}
              >
                Tambah Dana Masuk
              </Button>
            )}
          </Space>
        </StyledFixedFooter>
      </StyledContentWrapper>
    </StyledPageContainer>
  );
};

export default TambahDanaMasuk;

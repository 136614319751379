import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Drawer, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getListKelasId,
  getListTahunAjaran,
  getAlumniMenunggak,
  getAlumniMenunggakDownload,
} from 'utils/fetch';
import {
  DownloadOutlined,
  DownOutlined,
  EyeOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import { statusMurid } from 'utils/helpers/common';
import exportExcel from 'utils/helpers/xlsx';

const AlumniMenunggak = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    state: { activeSchoolYear, unitData, userData },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    unitId: null,
    id: '',
    student: null,
  });
  const [data, setData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [otherData, setOtherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    status: null,
  });
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    status = '',
    name = '',
    grade = '',
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = { unit, limit, page, name, grade, status };
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/alumni-menunggak',
        node: <span>Alumni / Murid Tidak Aktif Menunggak</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['4']);
  }, []);

  useEffect(() => {
    if (userData.type === 2) {
      setSelectedUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);
    fetchDataTahunAjaran();
  }, []);

  useEffect(() => {
    fetchData();

    return () => {};
  }, [page, limit, selectedUnit]);

  useEffect(() => {
    setFilter({
      name,
      status,
      grade,
    });
  }, [name, status, grade]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `unitId=${selectedUnit?._id || ''}&limit=${limit}&page=${
      page - 1
    }`;

    if (name) query += `&name=${name}`;
    if (status) query += `&status=${status}`;

    setLoading(true);

    getAlumniMenunggak(query)
      .then((res) => {
        const numbered = res?.totalStudents.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        setData(numbered);
        setOtherData({
          totalBills: res.totalBills,
          totalAmount: res.totalAmount,
          totalStudents: res.totalStudentsCount,
        });
        setTotalData(res.totalStudentsCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response, 'this is error fetch');
      });
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data || '-'}</span>,
    },
    {
      title: 'Total Tunggakan',
      dataIndex: 'totalDebtAmount',
      key: 'totalDebtAmount',
      render: (data) => {
        return (
          <span>
            {data > 0 ? `Rp${(data || 0).toLocaleString()}` : 'Lunas'}
          </span>
        );
      },
    },
    {
      title: 'Total Tagihan',
      dataIndex: 'totalDebtBills',
      key: 'totalDebtBills',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tunggakan Unit',
      dataIndex: 'unit',
      key: 'unit',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data) => {
        return (
          <Tag color={statusMurid[data || 0].color}>
            {statusMurid[data || 0].status}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      fixed: 'right',
      width: 230,
      render: (id, record) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              history.push({
                pathname: `/daftar-murid/detail/${id}`,
                search: queryString.stringify({
                  unit: record.unit.name.toLowerCase(),
                }),
              });
            }}
          >
            Lihat
          </Button>
          <Button
            icon={<RetweetOutlined />}
            onClick={() => {
              setOpenDrawer({
                state: true,
                id: id,
                unitId: record.unit._id,
                student: record,
              });
            }}
          >
            Bayar
          </Button>
        </Space>
      ),
    },
  ];

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu>
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const parsedResult = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Total Tunggakan',
        'Total Tagihan',
        'Tunggakan Unit',
        'Status',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.totalDebtAmount,
        dt.totalDebtBills,
        dt.unit?.name,
        statusMurid[dt.status || 0]?.status,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const downloadLaporan = () => {
    setLoadingDownload(true);
    let queryDownload = `unitId=${selectedUnit?._id || ''}`;
    getAlumniMenunggakDownload(queryDownload)
      .then((res) => {
        setLoadingDownload(false);
        const parsedData = parsedResult(res?.totalStudents);
        exportExcel(parsedData, 'Laporan Alumni Menunggak');
      })
      .catch((err) => {
        setLoadingDownload(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Dropdown
            disabled={userData.type === 2}
            overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
          >
            <Button icon={<DownOutlined />}>
              {selectedUnit?.name || 'Semua Unit'}
            </Button>
          </Dropdown>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            disabled={loading}
            onClick={downloadLaporan}
            loading={loadingDownload}
          >
            Download Laporan
          </Button>
        </Space>
      </PageHeader>

      <Row gutter={[24, 0]}>
        <Col span="8">
          <InfoCard
            title="Total Tunggakan"
            content={`${(otherData?.totalBills || 0).toLocaleString()}`}
            sideColor="red"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Jumlah Murid"
            content={`${otherData?.totalStudents || 0}`}
            sideColor="red"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Jumlah Tagihan Belum Lunas"
            content={`Rp${(otherData?.totalAmount || 0).toLocaleString()}`}
            sideColor="red"
          />
        </Col>
      </Row>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
          }}
          scroll={{ x: 1500 }}
          loading={loading}
        />
        {/* {JSON.stringify(data)} */}
      </section>

      <Drawer.PembayaranAlumni
        visible={openDrawer.state}
        onCancel={() => {
          setOpenDrawer({
            id: '',
            unitId: '',
            state: false,
          });
        }}
        value={{
          id: openDrawer.id,
          unitId: openDrawer?.unitId,
          student: openDrawer?.student,
        }}
      />
    </>
  );
};

export default AlumniMenunggak;

import styled from 'styled-components';

export const StyledPageContainer = styled.section`
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
`;

export const StyledContentWrapper = styled.article`
  width: 60vw;
  height: auto;
`;

export const StyledFixedFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  background-color: #ffffff;
  padding: 1.5rem 3rem;
  border-top: 1px solid #ccc;
`;

export const EmptyStateContainer = styled.div`
  margin: 15rem 0;
`;

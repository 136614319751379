import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ProtectedRoute, GuestRoute } from 'lib/authentication';
import { SignIn, ForgotPassword } from 'pages/auth';
import { getUserToken } from 'utils/helpers/storage';
import MainRoutes from './main';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <GuestRoute
          exact
          path="/signin"
          // component={SignIn}
          render={(props) => {
            const token = getUserToken('skk-access-token') || null;

            if (token) {
              return <Redirect to="/dashboard/semua" />;
            } else {
              return <SignIn {...props} />;
            }
          }}
        />
        <GuestRoute
          exact
          path="/forgot-password"
          // component={ForgotPassword}
          render={(props) => {
            const token = getUserToken('skk-access-token') || null;

            if (token) {
              return <Redirect to="/dashboard/semua" />;
            } else {
              return <ForgotPassword {...props} />;
            }
          }}
        />

        <ProtectedRoute path="/" component={MainRoutes} />
      </Switch>
    </Router>
  );
};

export default Routes;

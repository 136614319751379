import { RetweetOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

const InfoCard = ({ title, content, action, sideColor, fontColor = '' }) => {
  const [color, setColor] = useState('');
  const [fColor, setFColor] = useState('');

  useEffect(() => {
    if (sideColor === 'blue') setColor('#A3DEF8');
    else if (sideColor === 'red') setColor('#FFA6A7');
    else if (sideColor === 'green') setColor('#99C5AD');
    else setColor(sideColor);

    if (fontColor == 'red') setFColor('#FF4D4F');
    else if (fontColor === 'green') setFColor('#4D9970');
    else setFColor('#333333');
  }, [sideColor, fontColor]);

  console.log(fColor);
  return (
    <section
      style={{
        border: '1px solid #bfbfbf',
        padding: '1rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <article
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{
            marginRight: '2rem',
            display: 'flex',
            alignItems: 'center',
            height: '5.5rem',
          }}
        >
          <div
            style={{
              height: '100%',
              backgroundColor: color,
              width: '0.75rem',
              borderRadius: '5rem',
              marginRight: '1rem',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <span style={{ color: '#595959', fontSize: '1.15rem' }}>
              {title}
            </span>
            <h2 style={{ margin: '0', color: fColor }}>{content}</h2>
          </div>
        </div>

        {action ? (
          <div>
            <Button icon={<RetweetOutlined />} onClick={action.function}>
              {action.name}
            </Button>
          </div>
        ) : null}
      </article>
    </section>
  );
};

export default InfoCard;

import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useStore } from 'store';

const PhoneCodesDropdown = (props) => {
  const { onChange, defaultValue, style } = props;
  const {
    state: { callingCodes },
  } = useStore();

  const [selected, setSelected] = useState('62');
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const MenuOverlay = () => {
    return (
      <Menu
        style={{
          height: '20rem',
          width: '40rem',
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Menu.Item
          style={{
            position: 'sticky',
            top: '-3.75px',
            background: 'white',
            zIndex: 10,
          }}
        >
          <Input.Search
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Menu.Item>
        {callingCodes
          .filter((el) =>
            `${el.name} (${el.callingCodes[0]})`
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim())
          )
          .map((code) => (
            <Menu.Item
              onClick={() => {
                setSelected(code.callingCodes[0]);
                onChange(code.callingCodes[0]);
                console.log(code);
              }}
            >
              {code.name} ({code.callingCodes[0]})
            </Menu.Item>
          ))}
      </Menu>
    );
  };

  return (
    <Dropdown overlay={MenuOverlay}>
      <Button style={{ ...style, color: 'black' }}>
        {`+${selected}` || 'Please select'} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default PhoneCodesDropdown;

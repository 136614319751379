import { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';
import {
  SET_AUTHENTICATED,
  SET_BREADCRUMBS,
  SET_HEADER_ACTIVE,
  SET_SIDER_ACTIVE,
  SET_USER_DATA,
  SET_SCHOOL_YEAR,
  SET_SELECTED_UNIT,
  SET_LIST_SCHOOL_YEAR,
  SET_LIST_KELAS,
  SET_CALLING_CODES,
} from 'utils/action-types.js';

const StoreContext = createContext({});

const INITIAL_STATE = {
  isAuthenticated: false,
  breadcrumbs: [],
  siderActive: [1],
  headerActive: [1],
  userData: null,
  listTahunAjaran: [],
  listKelas: [],
  unitData: {
    tk: {
      name: 'KB/TK Jakarta',
      _id: '625e1727f9881f7af1ce5bd1',
    },
    sd: {
      name: 'SD Jakarta',
      _id: '625e17309814cf7b02c414e1',
    },
    smp: {
      name: 'SMP Jakarta',
      _id: '625e173810569c7b1340c040',
    },
    sma: {
      name: 'SMA Jakarta',
      _id: '625e173ffce2b27b1eed9fd1',
    },
    tkc: {
      name: 'KB/TK Cibubur',
      _id: '625e175b8155637b64cb69ea',
    },
    sdc: {
      name: 'SD Cibubur',
      _id: '625e17546626847b4d8cf098',
    },
    smpc: {
      name: 'SMP Cibubur',
      _id: '625e174e92936a7b47fdcc0a',
    },
    smac: {
      name: 'SMA Cibubur',
      _id: '625e17475d8e9c7b365742db',
    },
  },
  selectedUnit: null,
  activeSchoolYear: null,
};

const useStoreActions = (initialState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAuthentication = (auth) => {
    dispatch({
      type: SET_AUTHENTICATED,
      payload: {
        auth,
      },
    });
  };

  const handleUserData = (data) => {
    dispatch({
      type: SET_USER_DATA,
      payload: {
        data,
      },
    });
  };

  const handleBreadcrumb = (data) => {
    dispatch({
      type: SET_BREADCRUMBS,
      payload: {
        data,
      },
    });
  };

  const handleSiderActive = (data) => {
    dispatch({
      type: SET_SIDER_ACTIVE,
      payload: {
        data,
      },
    });
  };

  const handleHeaderActive = (data) => {
    dispatch({
      type: SET_HEADER_ACTIVE,
      payload: {
        data,
      },
    });
  };

  const handleSelectedUnit = (data) => {
    dispatch({
      type: SET_SELECTED_UNIT,
      payload: {
        data,
      },
    });
  };

  const handleSchoolYear = (data) => {
    dispatch({
      type: SET_SCHOOL_YEAR,
      payload: {
        data,
      },
    });
  };

  const handleListSchoolYear = (data) => {
    dispatch({
      type: SET_LIST_SCHOOL_YEAR,
      payload: { data },
    });
  };

  const handleListKelas = (data) => {
    dispatch({
      type: SET_LIST_KELAS,
      payload: { data },
    });
  };

  const handleCallingCodes = (data) => {
    dispatch({
      type: SET_CALLING_CODES,
      payload: { data },
    });
  };

  return {
    state,
    handleAuthentication,
    handleListSchoolYear,
    handleListKelas,
    handleBreadcrumb,
    handleSiderActive,
    handleHeaderActive,
    handleUserData,
    handleSelectedUnit,
    handleSchoolYear,
    handleCallingCodes,
  };
};

export const StoreProvider = ({ children }) => {
  const {
    state,
    handleAuthentication,
    handleBreadcrumb,
    handleSiderActive,
    handleHeaderActive,
    handleListSchoolYear,
    handleListKelas,
    handleUserData,
    handleSelectedUnit,
    handleSchoolYear,
    handleCallingCodes,
  } = useStoreActions();

  return (
    <StoreContext.Provider
      value={{
        state,
        handleAuthentication,
        handleListSchoolYear,
        handleListKelas,
        handleBreadcrumb,
        handleSiderActive,
        handleHeaderActive,
        handleUserData,
        handleSelectedUnit,
        handleSchoolYear,
        handleCallingCodes,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);

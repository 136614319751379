import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Space,
  message,
  Input,
  Select,
  List,
  Checkbox,
  Steps,
  Card,
  Divider,
  Empty,
  InputNumber,
  DatePicker,
  Radio,
  Popover,
} from 'antd';
import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory } from 'react-router-dom';
import { InputWrapper, Modal } from 'components/shared';
import { useStore } from 'store';
import {
  getListKelasId,
  getMuridByGrade,
  ppdbDetailConfig,
  submitStudentGraduate,
  tambahPpdb,
} from 'utils/fetch';
import { CardBase, Loader } from 'components/atoms';
import { InfoCard } from 'components/fragments/card';
import { instalmentCalculator } from 'utils/helpers/instalment-calculator';
import moment from 'moment';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

const TambahTagihanPPDB = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    state: { listTahunAjaran, selectedUnit, activeSchoolYear },
  } = useStore();
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState(0);
  const [listMurid, setListMurid] = useState([]);
  const [listKelas, setListKelas] = useState([]);
  const [filterKelas, setFilterKelas] = useState([]);
  const [studentByGrade, setStudentByGrade] = useState(null);
  const [hasNoGrade, setHasNoGrade] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState(listMurid);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [tahunAjaran, setTahunAjaran] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [ppdbInfo, setPpdbInfo] = useState(null);
  const [ppdbItems, setPpdbItems] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [namaTagihan, setNamaTagihan] = useState('');
  const [notActive, setNotActive] = useState([]);
  const [instalmentOver, setInstalmentOver] = useState(false);
  const unitId = selectedUnit?._id;

  const [selectedPaymentType, setSelectedPaymentType] = useState(1);
  const [selectedAnytimePayment, setSelectedAnytimePayment] = useState(0);

  useEffect(() => {
    if (activeSchoolYear?._id && unitId) {
      setTahunAjaran(activeSchoolYear?._id);
      fetchData(activeSchoolYear?._id);
    }
  }, [activeSchoolYear, unitId]);

  useEffect(() => {
    if (current === 1 && tahunAjaran) {
      setNameSearch('');
      fetchDetailConfig(tahunAjaran);
    }
  }, [current, tahunAjaran]);

  useEffect(() => {
    if (ppdbItems) {
      const newItems = ppdbItems.map((item) => ({
        ppdb_item: item._id,
        total_amount: 0,
      }));
      form.setFieldsValue({
        items: newItems,
      });
    }
  }, [ppdbItems]);

  useEffect(() => {
    if (nameSearch) {
      const filtered = listMurid.filter((murid) =>
        murid?.name.toLowerCase().includes(nameSearch.toLowerCase())
      );

      setFilteredStudent(filtered);
    } else {
      setFilteredStudent(listMurid);
    }
  }, [nameSearch]);

  useEffect(() => {
    if (filterKelas.length && studentByGrade) {
      const murid = studentByGrade.map((el) => {
        const inFilter = filterKelas.some((fil) => fil === el._id);
        if (inFilter) {
          return el.students;
        } else {
          return;
        }
      });

      if (filterKelas.some((fil) => fil === 'Murid Tanpa Kelas')) {
        murid.push(hasNoGrade);
      }

      if (filterKelas.some((fil) => fil === 'graduated')) {
        murid.push(notActive);
      }

      const flattened = murid.flatMap((x) => x).filter((y) => y !== undefined);

      setListMurid(flattened);
      setFilteredStudent(flattened);
    } else {
      setFilteredStudent([]);
      setListMurid([]);
    }
  }, [filterKelas, studentByGrade]);

  const fetchDetailConfig = (schoolYearId) => {
    console.log('fetch detail config start');
    ppdbDetailConfig(unitId, schoolYearId)
      .then((res) => {
        console.log(res);
        setPpdbInfo(res.ppdb);
        setPpdbItems(res.ppdbItems);
      })
      .catch((err) => {
        console.log(err.response, 'error detail config');
      });
  };

  const fetchData = (schoolYearId) => {
    getMuridByGrade(unitId, schoolYearId)
      .then((res) => {
        console.log(res, 'this is data');
        setListKelas(res.grades);
        setStudentByGrade(res.grades);
        setHasNoGrade(res.studentsNoGrades);
        setNotActive(res.studentsNotActive);
      })
      .catch((err) => {
        console.log(err.response, 'this is error fetch');
      });
  };

  const handleSubmit = (payload) => {
    setLoadingSubmit(true);
    tambahPpdb(unitId, payload)
      .then((res) => {
        console.log(res, 'this is res');
        setLoadingSubmit(false);
        message.success('Berhasil membuat tagihan Uang Pangkal');
        history.goBack();
      })
      .catch((err) => {
        setLoadingSubmit(false);
        message.error(
          err?.response?.data?.m || 'Gagal membuat tagihan Uang Pangkal'
        );
        console.log(err?.response, 'error tambah Uang Pangkal');
      });
  };

  useEffect(() => {
    if (listKelas || listKelas.length) {
      setFilterKelas([listKelas[0]?._id]);
    }
  }, [listKelas]);

  const currentDate = new Date();
  const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Buat Tagihan Uang Pangkal</h2>
        <section style={{ marginTop: '2rem', marginBottom: '6rem' }}>
          <Steps current={current}>
            <Steps.Step title="Pilih tahun ajaran & murid" />
            <Steps.Step title="Input Nominal" />
          </Steps>
        </section>

        <section style={{ paddingBottom: '4rem' }}>
          {current === 0 ? (
            <>
              <Form layout="vertical">
                <Row gutter={[18, 8]}>
                  <Col span="12">
                    <InputWrapper
                      type="text"
                      name="tagihan"
                      label="Nama Tagihan"
                      defaultValue={namaTagihan}
                      placeholder="Ketik nama tagihan di sini"
                      onChange={(e) => {
                        setNamaTagihan(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span="12">
                    <Form.Item label="Tahun Ajaran">
                      <Select
                        placeholder="Pilih tahun ajaran"
                        style={{ width: '100%' }}
                        defaultValue={activeSchoolYear?._id}
                        onChange={(e) => setTahunAjaran(e)}
                      >
                        {listTahunAjaran?.map((year) => (
                          <Select.Option value={year._id}>
                            {year.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div style={{ marginTop: '2rem' }}>
                <h2>Pilih Murid</h2>

                {studentByGrade ? (
                  <>
                    <Row gutter={[24, 14]}>
                      <Col span="9">
                        <CardBase
                          style={{
                            overflowY: 'auto',
                          }}
                          title="Daftar Kelas"
                          bodyStyle={{
                            padding: 0,
                            display: 'flex',
                            maxHeight: '32.575rem',
                            flexDirection: 'column',
                          }}
                        >
                          <>
                            <Checkbox
                              onChange={() => {
                                if (
                                  filterKelas.length ===
                                  studentByGrade.length + 2
                                ) {
                                  setFilterKelas([]);
                                } else {
                                  const allGradesId = studentByGrade?.map(
                                    (grd) => grd._id
                                  );
                                  setFilterKelas([
                                    ...allGradesId,
                                    'Murid Tanpa Kelas',
                                    'graduated',
                                  ]);
                                }
                              }}
                              checked={
                                filterKelas.length === studentByGrade.length + 2
                              }
                              style={{ margin: '1rem 0' }}
                            >
                              Pilih Semua Kelas
                            </Checkbox>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '1rem 0',
                              }}
                            >
                              <Checkbox
                                checked={filterKelas?.some(
                                  (el) => el === 'graduated'
                                )}
                                onChange={() => {
                                  if (
                                    filterKelas?.some(
                                      (el) => el === 'graduated'
                                    )
                                  ) {
                                    const filtered = filterKelas.filter(
                                      (el) => el !== 'graduated'
                                    );
                                    setFilterKelas(filtered);
                                  } else {
                                    setFilterKelas([
                                      ...filterKelas,
                                      'graduated',
                                    ]);
                                  }
                                }}
                              >
                                Lulus
                              </Checkbox>
                              <span>{notActive?.length || 0} Murid</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '1rem 0',
                              }}
                            >
                              <Checkbox
                                checked={filterKelas?.some(
                                  (el) => el === 'Murid Tanpa Kelas'
                                )}
                                onChange={() => {
                                  if (
                                    filterKelas?.some(
                                      (el) => el === 'Murid Tanpa Kelas'
                                    )
                                  ) {
                                    const filtered = filterKelas.filter(
                                      (el) => el !== 'Murid Tanpa Kelas'
                                    );
                                    setFilterKelas(filtered);
                                  } else {
                                    setFilterKelas([
                                      ...filterKelas,
                                      'Murid Tanpa Kelas',
                                    ]);
                                  }
                                }}
                              >
                                Tanpa Kelas
                              </Checkbox>
                              <span>{hasNoGrade?.length || 0} Murid</span>
                            </div>
                            {studentByGrade?.map((grade) => {
                              const checked = filterKelas?.some(
                                (el) => el === grade._id
                              );
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '1rem 0',
                                  }}
                                >
                                  <Checkbox
                                    checked={checked}
                                    onChange={() => {
                                      if (checked) {
                                        const filtered = filterKelas.filter(
                                          (el) => el !== grade._id
                                        );
                                        setFilterKelas(filtered);
                                      } else {
                                        setFilterKelas([
                                          ...filterKelas,
                                          grade._id,
                                        ]);
                                      }
                                    }}
                                  >
                                    {grade.name}
                                  </Checkbox>
                                  <span>{grade.students.length} Murid</span>
                                </div>
                              );
                            })}
                          </>
                        </CardBase>
                      </Col>
                      <Col span="15">
                        <CardBase
                          style={{
                            overflowY: 'auto',
                          }}
                          bodyStyle={{
                            padding: '16px 0',
                            display: 'flex',
                            maxHeight: '38.375rem',
                            flexDirection: 'column',
                          }}
                        >
                          <Input.Search
                            placeholder="Cari nama murid"
                            value={nameSearch}
                            onChange={(e) => setNameSearch(e.target.value)}
                          />
                          <h4 style={{ marginTop: '10px' }}>
                            Murid Terpilih: {selectedStudent.length}
                          </h4>
                          <Checkbox
                            onChange={() => {
                              if (selectedStudent.length === listMurid.length) {
                                setSelectedStudent([]);
                              } else {
                                setSelectedStudent(listMurid);
                              }
                            }}
                            checked={
                              selectedStudent.length === listMurid.length
                            }
                            style={{ marginTop: '1.5rem' }}
                          >
                            Pilih Semua Murid
                          </Checkbox>
                          {filteredStudent.length ? (
                            <section style={{ margin: '1rem 0' }}>
                              <List
                                dataSource={filteredStudent}
                                renderItem={(item) => {
                                  const checked = selectedStudent.some(
                                    (el) => el._id === item._id
                                  );

                                  return (
                                    <List.Item>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: '100%',
                                          cursor: 'pointer',
                                          gap: '.8rem',
                                        }}
                                        onClick={() => {
                                          if (checked) {
                                            const filtered =
                                              selectedStudent.filter(
                                                (el) => el._id !== item._id
                                              );
                                            setSelectedStudent(filtered);
                                          } else {
                                            setSelectedStudent([
                                              ...selectedStudent,
                                              item,
                                            ]);
                                          }
                                        }}
                                      >
                                        <Checkbox
                                          checked={checked}
                                          onChange={() => {
                                            if (checked) {
                                              const filtered =
                                                selectedStudent.filter(
                                                  (el) => el._id !== item._id
                                                );
                                              setSelectedStudent(filtered);
                                            } else {
                                              setSelectedStudent([
                                                ...selectedStudent,
                                                item,
                                              ]);
                                            }
                                          }}
                                        />
                                        {item.name}
                                      </div>
                                    </List.Item>
                                  );
                                }}
                              />
                            </section>
                          ) : (
                            <p
                              style={{
                                marginTop: '1.5rem',
                                textAlign: 'center',
                              }}
                            >
                              Tidak ada murid di kelas ini
                            </p>
                          )}
                        </CardBase>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Loader text="Fetch data murid..." />
                )}
              </div>
            </>
          ) : ppdbInfo ? (
            <>
              <h3>Detail Nominal Uang Pangkal</h3>
              <Form
                layout="vertical"
                form={form}
                onFinish={(v) => {
                  const totalAmount = v.items.reduce(
                    (curr, acc) => curr + Number(acc.total_amount || 0),
                    0
                  );
                  const studentsId = selectedStudent.map(
                    (student) => student._id
                  );
                  const payload = {
                    studentsId,
                    ...v,
                    items: v.items.map((item) => ({
                      ...item,
                      total_amount: item.total_amount || 0,
                    })),
                    school_year: tahunAjaran,
                    total_amount: totalAmount,
                    instalments: v.instalments?.map((ins, i) => ({
                      instalment_date: moment(ins.instalment_date),
                      total_amount: ins.total_amount,
                      instalment_repition: i,
                      notes: ins.notes,
                    })),
                    fix_payment: v?.fix_payment === 1 ? true : false || false,
                    minimum_amount: v?.minimum_amount || 0,
                    anytime_payment:
                      v?.anytime_payment === 1 ? true : false || false,
                    dana_bantuan_siswa: v.dana_bantuan_siswa || 0,
                    discount: v.discount || 0,
                  };
                  // console.log(payload);
                  if (v.instalments?.length) {
                    handleSubmit(payload);
                  } else {
                    message.error('Cicilan tidak boleh kosong.');
                  }
                }}
                onValuesChange={(changedValue, value) => {
                  if (changedValue?.items) {
                    setTotal(
                      value?.items.reduce((acc, curr) => {
                        return acc + Number(curr?.total_amount) || 0;
                      }, 0)
                    );
                  }
                  if (changedValue?.instalments) {
                    const instalmentAmount = value.instalments?.reduce(
                      (acc, curr) => acc + curr?.total_amount || 0,
                      0
                    );
                    const itemAmount = total;

                    if (instalmentAmount > itemAmount) {
                      message.error(
                        'Nominal cicilan tidak dapat melebihi nominal total tagihan Uang Pangkal'
                      );
                      setInstalmentOver(true);
                    } else {
                      setInstalmentOver(false);
                    }
                  }
                }}
                initialValues={{
                  fix_payment: 1,
                  dana_bantuan_siswa: 0,
                  discount: 0,
                }}
              >
                <section style={{ margin: '4rem 0' }}>
                  {ppdbItems?.length ? (
                    <Form.List name="items">
                      {(fields) => {
                        return fields.map((field, idx) => {
                          return (
                            <Row
                              gutter={[8, 12]}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Col span="8">
                                <p>{ppdbItems[idx]?.master_bill_item.name}</p>
                              </Col>
                              <Col span="16">
                                <InputWrapper
                                  type="number"
                                  prefix="Rp"
                                  formItemProps={{
                                    name: [field.name, 'total_amount'],
                                  }}
                                  placeholder="Rp 500.000"
                                  formatter={(value) =>
                                    `Rp ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ','
                                    )
                                  }
                                  parser={(value) =>
                                    value.replace(/\Rp\s?|(,*)/g, '')
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        });
                      }}
                    </Form.List>
                  ) : (
                    <Empty description="Tidak ada data item tagihan pada tahun ajaran ini, silakan cek pengaturan tipe tagihan." />
                  )}
                </section>
                <section style={{ width: '50%' }}>
                  <InfoCard
                    sideColor="green"
                    title="Total"
                    content={`Rp${(total || 0).toLocaleString()}`}
                  />
                </section>
                <section style={{ marginTop: '2rem' }}>
                  <h2>Lainnya</h2>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        name="discount"
                        label="Nominal Diskon"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                    <Col span={12}>
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        name="dana_bantuan_siswa"
                        label="Nominal Dana Bantuan Siswa"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                  </Row>
                </section>
                {/* <section style={{ margin: '4rem 0', width: '100%' }}>
                <h3>Informasi Cicilan</h3>
                <Card
                  style={{
                    backgroundColor: '#EDF7FF',
                    border: '1px solid #4EAFFF',
                    marginTop: '2rem',
                  }}
                >
                  <Row>
                    <Col span="9">
                      <span>
                        Nominal Cicilan {ppdbInfo?.instalment_repition}x per
                        Bulan
                      </span>
                      <h2>
                        Rp
                        {instalmentCalculator(
                          total || 0,
                          ppdbInfo?.instalment_repition || 1
                        )[0].toLocaleString()}
                      </h2>
                    </Col>
                    <Col span="1">
                      <Divider style={{ height: '100%' }} type="vertical" />
                    </Col>
                    <Col span="14">
                      <Row gutter={[8, 8]}>
                        <Col span="12">Bulan Mulai Penagihan</Col>
                        <Col span="12">
                          {ppdbInfo?.instalment_date > new Date().getDay()
                            ? moment(nextMonth).format('MMMM YYYY')
                            : moment(currentDate).format('MMMM YYYY')}
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]}>
                        <Col span="12">Tanggal Penagihan / Bulan</Col>
                        <Col span="12">Tanggal {ppdbInfo?.instalment_date}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </section> */}
                <section style={{ marginTop: '2rem' }}>
                  <Form.Item
                    label={
                      <span>
                        Tipe Nominal Tagihan &nbsp;{' '}
                        <Popover
                          trigger={['hover']}
                          content={
                            <div>
                              <p>
                                Pilih "Fix Amount" jika nominal tagihan tidak
                                dapat diubah ketika orang tua melakukan
                                pembayaran.
                              </p>
                              <p>
                                Pilih "Variable Amount" jika nominal tagihan
                                dapat diubah ketika orang tua melakukan
                                pembayaran.
                              </p>
                            </div>
                          }
                        >
                          <ExclamationCircleOutlined
                            style={{
                              margin: 0,
                              color: '#4EAFFF',
                              cursor: 'pointer',
                            }}
                          />
                        </Popover>
                      </span>
                    }
                    name="fix_payment"
                    initialValue={1}
                  >
                    <Radio.Group
                      onChange={(e) => setSelectedPaymentType(e.target.value)}
                    >
                      <Radio value={1}>Fix Amount</Radio>
                      <Radio value={0}>Variable Amount</Radio>
                    </Radio.Group>
                  </Form.Item>
                </section>{' '}
                <section>
                  <Form.Item
                    label={
                      <span>
                        Pembayaran Kapan Saja &nbsp;{' '}
                        <Popover
                          trigger={['hover']}
                          content={
                            <div>
                              <p>
                                Pilih "Ya" jika tagihan dapat dibayarkan kapan
                                saja.
                              </p>
                              <p>
                                Pilih "Tidak" jika nominal tidak dapat
                                dibayarkan kapan saja.
                              </p>
                            </div>
                          }
                        >
                          <ExclamationCircleOutlined
                            style={{
                              margin: 0,
                              color: '#4EAFFF',
                              cursor: 'pointer',
                            }}
                          />
                        </Popover>
                      </span>
                    }
                    name="anytime_payment"
                    initialValue={0}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setSelectedAnytimePayment(e.target.value)
                      }
                    >
                      <Radio value={1}>Ya</Radio>
                      <Radio value={0}>Tidak</Radio>
                    </Radio.Group>
                  </Form.Item>
                </section>
                {selectedPaymentType === 0 && (
                  <section style={{ marginTop: '2rem' }}>
                    <InputWrapper
                      type="number"
                      prefix="Rp"
                      name="minimum_amount"
                      label="Minimum Pembayaran"
                      formatter={(value) =>
                        `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                    />
                  </section>
                )}
                <section style={{ marginTop: '2rem' }}>
                  <Form.List name="instalments">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map((field, idx) => {
                            return (
                              <div
                                key={field.key}
                                required={false}
                                style={{ margin: 0 }}
                              >
                                <Row gutter={[12, 0]}>
                                  {' '}
                                  <Col span="3">
                                    <Form.Item
                                      // name={[field.name, 'priority']}
                                      initialValue={idx + 1}
                                      label={idx === 0 ? 'Cicilan Ke' : ''}
                                    >
                                      <InputNumber
                                        value={idx + 1}
                                        placeholder="Cicilan Ke - "
                                        min={1}
                                        style={{ width: '100%' }}
                                        disabled
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span="8">
                                    <InputWrapper
                                      formItemProps={{
                                        name: [field.name, 'total_amount'],
                                        rules: [
                                          {
                                            message:
                                              'Nominal tidak boleh kosong',
                                            required: true,
                                          },
                                        ],
                                        required: true,
                                      }}
                                      type="number"
                                      // name={[field.name, 'total_amount']}
                                      prefix="Rp"
                                      // onBlur={(e) => {
                                      //   const array = [...totalAmount];
                                      //   array[field.key] = Number(
                                      //     e.target.value.replace(/\Rp\s?|(,*)/g, '')
                                      //   );
                                      //   setTotalAmount(array);
                                      // }}
                                      label={idx === 0 ? 'Nominal Cicilan' : ''}
                                      placeholder="Nominal Rp 500.000"
                                      formatter={(value) =>
                                        `Rp ${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ','
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/\Rp\s?|(,*)/g, '')
                                      }
                                    />
                                  </Col>
                                  <Col span="5">
                                    <Form.Item
                                      rules={[
                                        {
                                          message: 'Tanggal tidak boleh kosong',
                                          required: true,
                                        },
                                      ]}
                                      required={true}
                                      label={
                                        idx === 0 ? 'Tanggal Jatuh Tempo' : ''
                                      }
                                      name={[field.name, 'instalment_date']}
                                      style={{ width: '100%' }}
                                    >
                                      <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                  </Col>
                                  <Col span="7">
                                    <InputWrapper
                                      formItemProps={{
                                        name: [field.name, 'notes'],
                                      }}
                                      type="text"
                                      label={idx === 0 ? 'Catatan' : ''}
                                      placeholder="Tulis catatan"
                                    />
                                  </Col>
                                  <Col span="1">
                                    <Form.Item
                                      label={
                                        idx === 0 ? <p hidden>Hapus</p> : null
                                      }
                                    >
                                      <Button
                                        type="text"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                        icon={
                                          <DeleteOutlined
                                            style={{ fontSize: '20px' }}
                                          />
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}

                          <Button
                            icon={<PlusCircleOutlined />}
                            onClick={() => {
                              add();
                            }}
                          >
                            Tambah Cicilan
                          </Button>
                        </>
                      );
                    }}
                  </Form.List>
                </section>
              </Form>
            </>
          ) : (
            <Loader text="Fetching uang pangkal info ..." />
          )}
        </section>

        <StyledFixedFooter>
          <Space>
            <Button
              loading={loadingSubmit}
              onClick={() => {
                if (current === 0) {
                  history.goBack();
                } else {
                  setCurrent(current - 1);
                  setPpdbInfo(null);
                  setTotal(0);
                  form.resetFields();
                }
              }}
            >
              Kembali
            </Button>
            {current === 0 ? (
              <Button
                type="primary"
                disabled={!selectedStudent.length || !namaTagihan}
                onClick={() => setCurrent(current + 1)}
              >
                Berikutnya
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={!total || instalmentOver}
                onClick={() =>
                  total && !instalmentOver ? form.submit() : null
                }
                loading={loadingSubmit}
              >
                Simpan
              </Button>
            )}
          </Space>
        </StyledFixedFooter>
      </StyledContentWrapper>
    </StyledPageContainer>
  );
};

export default TambahTagihanPPDB;

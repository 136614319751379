import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  message,
  Progress,
  Row,
  Space,
  Popover,
  Spin,
  DatePicker,
} from 'antd';
import { Modal, PageHeader } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import {
  fetchTahunAjaran,
  getDownloadLaporanKategori,
  getLaporanKategori,
  getRekapAllUnit,
  getRekapUnit,
  getSPPReport,
  updateRekap,
} from 'utils/fetch';
import { CardDashboard } from './keseluruhan';
import { ProgressContainer, ProgressTitle } from './style';

import IC_TUNGGAKAN from 'assets/icons/tunggakan.svg';
import IC_DANA_MASUK from 'assets/icons/dana-masuk.svg';
import IC_REFUND from 'assets/icons/Refund.svg';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';

const RekapSPP = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    state: { unitData, activeSchoolYear, userData },
  } = useStore();
  const [sppReport, setSppReport] = useState(null);
  const [modal, setModal] = useState({
    state: false,
    data: null,
    table: '',
  });
  const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [selectedTahun, setSelectedTahun] = useState(null);
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [mustPaidMonth, setMustPaidMonth] = useState({
    name: 'Januari 2021',
    value: '1-1-2021',
  });
  const [paidThisMonth, setPaidThisMonth] = useState({
    name: 'Februari 2021',
    value: '1-2-2021',
  });
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [pembayaranBulanIni, setPembayaranBulanIni] = useState(null);
  const arrayUnit = useMemo(
    () => Object.keys(unitData).map((key) => unitData[key]),
    [unitData]
  );

  const [filter, setFilter] = useState({
    units: [],
    school_year: '',
    cutoffDate: moment().format('MM/DD/YYYY'),
  });
  const [filterModal, setFilterModal] = useState(false);
  const [data, setData] = useState(null);
  const [userUnits, setUserUnits] = useState([]);

  const [filterCutOff, setFilterCutOff] = useState(false);
  const [cutOffDate, setCutOffDate] = useState(moment());

  useEffect(() => {
    fetchListTahun();
  }, []);

  useEffect(() => {
    if (arrayUnit.length && userData) {
      const defaultUnits = userData?.units?.length
        ? userData?.units?.map((unit) => unit?._id)
        : arrayUnit.map((unit) => unit?._id);

      setFilter({ units: defaultUnits });
      setUserUnits(defaultUnits);
    } else {
      setFilter({
        units: [],
        cutoffDate: '',
      });
    }
  }, [arrayUnit, userData]);

  useEffect(() => {
    if (userData?.unit) {
      setSelectedUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setSelectedTahun(activeSchoolYear);
      setFilter((p) => ({ ...p, school_year: activeSchoolYear?._id }));
      const next = new Date(activeSchoolYear.start_date);

      const date = {
        name: `${moment(next).format('MMMM YYYY')}`,
        value: next,
      };
      setPaidThisMonth(date);
      setMustPaidMonth(date);
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/rekap/spp',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>
              Dashboard Laporan Kategori
            </p>
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['3.1']);
  }, [lastUpdated]);

  useEffect(() => {
    if (filter.units.length && filter.school_year) {
      _fetchRekapanKategori(filter);
    }
  }, [filter]);

  const _fetchRekapanKategori = async (filter) => {
    try {
      const response = await getLaporanKategori(filter);
      console.log({ response: response.value });
      setData(response.value);
      setFilterModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const _fetchDownloadLaporan = async (filter) => {
    try {
      const response = await getDownloadLaporanKategori(filter);
      console.log({ response: response.value });
      return Promise.resolve(response.value);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  const fetchListTahun = () => {
    fetchTahunAjaran()
      .then((res) => {
        console.log(res);
        setListTahunAjaran(res.schoolYears);
      })
      .catch((err) => console.log(err, 'error list tahun'));
  };

  const MenuTahun = ({ onClick, list = [{ name: '' }] }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        {list.map((ta, idx) => {
          return (
            <Menu.Item key={idx} onClick={() => onClick(ta)}>
              {ta.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const parsedSheetKeseluruhan = (data = {}) => {
    const parsed = [
      [
        'No',
        'Jenis Tagihan',
        'Total Nominal Tagihan',
        'Total Sudah Dibayarkan',
        'Total Sisa Belum Bayar',
        'Discount',
        'Dana Bantuan Siswa',
      ],
    ];

    Object.keys(data).forEach((key, index) => {
      const arr = [
        index + 1,
        key,
        data[key]?.totalAmount,
        data[key]?.paidAmount,
        data[key]?.totalAmount - data[key]?.paidAmount,
        data[key]?.discountAmount,
        data[key]?.danaBantuanSiswaAmount,
      ];

      parsed.push(arr);
    });

    return parsed;
  };

  const parsedSheetUangSekolah = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Nomor Virtual Account',
        'Kelas',
        'Unit',
        'Nama Tagihan',
        'Item Tagihan',
        'Jatuh Tempo',
        'Nominal Tagihan',
        'Nominal Sudah Dibayarkan',
        'Diskon',
        'Dana Bantuan Siswa',
        'Nominal Sisa Bayar',
      ],
    ];

    data.forEach((dt, index) => {
      const arr = [
        index + 1,
        dt?.student?.name || '-',
        dt?.student?.virtual_account || '-',
        dt?.student?.grade?.name || '-',
        dt?.unit?.name || '-',
        `SPP ${moment(dt?.month).format('MMMM YYYY')}`,
        dt?.items?.map((item) => item?.master_bill_item)?.join(','),
        moment(dt?.month).format('DD MMMM YYYY'),
        dt?.total_amount + dt?.discount + dt?.dana_bantuan_siswa,
        dt?.paid_amount || 0,
        dt?.discount,
        dt?.dana_bantuan_siswa,
        dt?.total_amount - dt?.paid_amount || 0,
      ];

      parsed.push(arr);
    });

    return parsed;
  };

  const parsedSheetUangPangkal = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Nomor Virtual Account',
        'Kelas',
        'Unit',
        'Cicilan Uang Pangkal',
        'Jatuh Tempo',
        'Nominal Tagihan',
        'Nominal Sudah Dibayarkan',
        'Diskon',
        'Dana Bantuan Siswa',
        'Nominal Sisa Bayar',
      ],
    ];

    data.forEach((dt, index) => {
      const arr = [
        index + 1,
        dt?.student?.name || '-',
        dt?.student?.virtual_account || '-',
        dt?.student?.grade?.name || '-',
        dt?.unit?.name || '-',
        'Cicilan Uang Pangkal',
        moment(dt?.month).format('DD MMMM YYYY'),
        dt?.total_amount + dt?.discount + dt?.dana_bantuan_siswa,
        dt?.paid_amount || 0,
        dt?.discount,
        dt?.dana_bantuan_siswa,
        dt?.total_amount - dt?.paid_amount || 0,
      ];

      parsed.push(arr);
    });

    return parsed;
  };

  const parsedSheetTagihanLainnya = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Nomor Virtual Account',
        'Kelas',
        'Unit',
        'Nama Tagihan',
        'Item Tagihan',
        'Jatuh Tempo',
        'Nominal Tagihan',
        'Nominal Sudah Dibayarkan',
        'Diskon',
        'Dana Bantuan Siswa',
        'Nominal Sisa Bayar',
      ],
    ];

    data.forEach((dt, index) => {
      const arr = [
        index + 1,
        dt?.student?.name || '-',
        dt?.student?.virtual_account || '-',
        dt?.student?.grade?.name || '-',
        dt?.unit?.name || '-',
        dt?.name,
        dt?.items?.map((item) => item?.master_bill_item)?.join(','),
        moment(dt?.month).format('DD MMMM YYYY'),
        dt?.total_amount + dt?.discount + dt?.dana_bantuan_siswa,
        dt?.paid_amount || 0,
        dt?.discount,
        dt?.dana_bantuan_siswa,
        dt?.total_amount - dt?.paid_amount || 0,
      ];

      parsed.push(arr);
    });

    return parsed;
  };

  const parsedBillsItems = (data = {}, title = '') => {
    const parsed = [
      [title],
      [
        'No',
        'Item Tagihan',
        'Total Nominal Tagihan',
        'Total Sudah Dibayarkan',
        'Total Sisa Belum Bayar',
      ],
    ];

    const keys = Object.keys(data);

    keys.forEach((key, idx) => {
      parsed.push([
        idx + 1,
        key,
        data[key]?.totalAmount,
        data[key]?.paidAmount,
        data[key]?.totalAmount - data[key]?.paidAmount,
      ]);
    });

    const grandTotalAmount = keys.reduce((acc, curr) => {
      return acc + data[curr]?.totalAmount || 0;
    }, 0);

    const grandTotalPaidAmount = keys.reduce((acc, curr) => {
      return acc + data[curr]?.paidAmount || 0;
    }, 0);

    const grandTotalSisaBayar = grandTotalAmount - grandTotalPaidAmount;

    const total = [
      '',
      'Total',
      grandTotalAmount,
      grandTotalPaidAmount,
      grandTotalSisaBayar,
    ];

    parsed.push(total);
    parsed.push(['']);

    return parsed;
  };

  const downloadLaporan = () => {
    setLoadingUpdate(true);
    _fetchDownloadLaporan({
      ...filter,
      cutOffDate: moment(cutOffDate).format('MM/DD/YYYY'),
    })
      .then((res) => {
        console.log({ res });
        const sppBillItems = parsedBillsItems(res.sppBillItems, 'Uang Sekolah');
        const ppdbBillItems = parsedBillsItems(
          res.ppdbBillItems,
          'Uang Pangkal'
        );
        const generalBillItems = parsedBillsItems(
          res.generalBillItems,
          'Tagihan Lainnya'
        );

        console.log({ sppBillItems, ppdbBillItems, generalBillItems });
        const parsedData = [
          {
            data: parsedSheetKeseluruhan(res.resultAll),
            sheetName: 'Keseluruhan',
          },
          {
            data: [...sppBillItems, ...ppdbBillItems, ...generalBillItems],
            sheetName: 'Rekapan Per Item',
          },
          {
            data: parsedSheetUangSekolah(res.sppBills),
            sheetName: 'Uang Sekolah',
          },
          {
            data: parsedSheetUangPangkal(res.ppdbBills),
            sheetName: 'Uang Pangkal',
          },
          {
            data: parsedSheetTagihanLainnya(res.generalBills),
            sheetName: 'Tagihan Lainnya',
          },
        ];
        exportExcel(parsedData, 'Laporan Rekap Kategori', true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingUpdate(false));
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <PageHeader>
            <Space>
              <Button onClick={() => setFilterModal(true)}>Filter Unit</Button>
              <Dropdown
                overlay={
                  <MenuTahun
                    onClick={(v) => {
                      setSelectedTahun(v);
                      console.log(v, 'value');
                      setFilter((p) => ({ ...p, school_year: v?._id }));
                    }}
                    list={listTahunAjaran}
                  />
                }
              >
                <Button>{selectedTahun?.name}</Button>
              </Dropdown>
              <DatePicker
                style={{ width: '100%' }}
                value={cutOffDate}
                onChange={(v) => {
                  console.log({ v });
                  setFilter((p) => ({
                    ...p,
                    cutOffDate: moment(v).format('MM/DD/YYYY'),
                  }));
                  setCutOffDate(v);
                }}
              />
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                onClick={() => setFilterCutOff(true)}
                loading={loadingUpdate}
              >
                Download Laporan
              </Button>
            </Space>
          </PageHeader>

          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <div>
              <h1 style={{ fontSize: '1.75rem', fontWeight: 600 }}>
                Uang Sekolah
              </h1>
              <Card
                style={{
                  backgroundColor: '#EDF7FF',
                  border: '1px solid #A7D7FF',
                }}
              >
                <Row gutter={[4, 12]}>
                  <Col span="7">
                    <span>Total Nominal Tagihan</span>
                    <h2>
                      Rp
                      {(data?.sppBillsTotal || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="7">
                    <span>Total Nominal Tagihan Terbayar</span>
                    <h2 style={{ color: '#1A7C47' }}>
                      Rp
                      {(data?.sppBillsPaidAmount || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="8">
                    <span>Total Sisa Tagihan</span>
                    <h2 style={{ color: '#FF4D4F' }}>
                      Rp
                      {(data?.sppBillsRemaining || 0).toLocaleString()}
                    </h2>
                  </Col>

                  <Col span="7">
                    <span>Total Nominal Refund</span>
                    <h2>
                      Rp
                      {(data?.sppBillsRefund || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="7">
                    <span>Total Nominal Dana Bantuan Siswa</span>
                    <h2>
                      Rp
                      {(data?.sppBillsDBS || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="8">
                    <span>Total Nominal Diskon</span>
                    <h2>
                      Rp
                      {(data?.sppBillsDiscount || 0).toLocaleString()}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </div>
            <div>
              <h1 style={{ fontSize: '1.75rem', fontWeight: 600 }}>
                Uang Pangkal
              </h1>
              <Card
                style={{
                  backgroundColor: '#EDF7FF',
                  border: '1px solid #A7D7FF',
                }}
              >
                <Row gutter={[4, 12]}>
                  <Col span="7">
                    <span>Total Nominal Tagihan</span>
                    <h2>
                      Rp
                      {(data?.ppdbBillsTotal || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="7">
                    <span>Total Nominal Tagihan Terbayar</span>
                    <h2 style={{ color: '#1A7C47' }}>
                      Rp
                      {(data?.ppdbBillsPaidAmount || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="8">
                    <span>Total Sisa Tagihan</span>
                    <h2 style={{ color: '#FF4D4F' }}>
                      Rp
                      {(data?.ppdbBillsRemaining || 0).toLocaleString()}
                    </h2>
                  </Col>

                  <Col span="7">
                    <span>Total Nominal Refund</span>
                    <h2>
                      Rp
                      {(data?.ppdbBillsRefund || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="7">
                    <span>Total Nominal Dana Bantuan Siswa</span>
                    <h2>
                      Rp
                      {(data?.ppdbBillsDBS || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="8">
                    <span>Total Nominal Diskon</span>
                    <h2>
                      Rp
                      {(data?.ppdbBillsDiscount || 0).toLocaleString()}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </div>

            <div>
              <h1 style={{ fontSize: '1.75rem', fontWeight: 600 }}>
                Tagihan Lainnya
              </h1>
              <Card
                style={{
                  backgroundColor: '#EDF7FF',
                  border: '1px solid #A7D7FF',
                }}
              >
                <Row gutter={[4, 12]}>
                  <Col span="7">
                    <span>Total Nominal Tagihan</span>
                    <h2>
                      Rp
                      {(data?.generalBillsTotal || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="7">
                    <span>Total Nominal Tagihan Terbayar</span>
                    <h2 style={{ color: '#1A7C47' }}>
                      Rp
                      {(data?.generalBillsPaidAmount || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="8">
                    <span>Total Sisa Tagihan</span>
                    <h2 style={{ color: '#FF4D4F' }}>
                      Rp
                      {(data?.generalBillsRemaining || 0).toLocaleString()}
                    </h2>
                  </Col>

                  <Col span="7">
                    <span>Total Nominal Refund</span>
                    <h2>
                      Rp
                      {(data?.generalBillsRefund || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="7">
                    <span>Total Nominal Dana Bantuan Siswa</span>
                    <h2>
                      Rp
                      {(data?.generalBillsDBS || 0).toLocaleString()}
                    </h2>
                  </Col>
                  <Col span="1">
                    <Divider
                      type="vertical"
                      orientation="center"
                      style={{ height: '100%' }}
                    />
                  </Col>
                  <Col span="8">
                    <span>Total Nominal Diskon</span>
                    <h2>
                      Rp
                      {(data?.generalBillsDiscount || 0).toLocaleString()}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </div>
          </Space>
        </>
      )}

      <Modal.RincianDashboard
        visible={modal.state}
        data={modal.data}
        onClose={() =>
          setModal({ state: false, data: null, table: '', title: '' })
        }
        table={modal.table}
        type="spp"
        modalTitle={modal.title || ''}
      />

      <Modal.FilterUnits
        visible={filterModal}
        onClose={() => setFilterModal(false)}
        onSelectedFilter={(values) => setFilter((p) => ({ ...p, ...values }))}
        units={filter.units}
        userUnits={userUnits}
      />

      <Modal.PilihTanggalCutoffDashboard
        visible={filterCutOff}
        onCancel={() => setFilterCutOff(false)}
        filter={filter}
        setFilter={setFilter}
        onDownload={downloadLaporan}
        defaultValue={cutOffDate}
        isLoadinDownload={loadingUpdate}
      />
    </>
  );
};

export default RekapSPP;

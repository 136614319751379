import React, { useEffect, useState } from 'react';
import {
  Modal,
  Space,
  Table,
  Input,
  Dropdown,
  Button,
  Menu,
  Select,
  message,
} from 'antd';

import { useStore } from 'store';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { updateEmailGuardian } from 'utils/fetch';
import { PhoneCodesDropdown } from 'components/form';

const UpdateEmailWali = ({
  visible,
  onClose,
  data,
  schoolYearId,
  unitId,
  refetchData,
  unit,
  ...rest
}) => {
  const {
    state: { unitData, callingCodes },
  } = useStore();
  const [nameSearch, setNameSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [clonnedData, setClonnedData] = useState([]);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  useEffect(() => {
    if (data && visible) {
      const modifiedData = data?.map((obj) => ({
        ...obj,
        phone_code: obj.phone_code || '62',
      }));
      setClonnedData(modifiedData);
    }
  }, [data, visible]);

  const handleChangeNamaWali = (id, value, field) => {
    const index = clonnedData.findIndex((dt) => dt._id === id);
    const copied = [...clonnedData];
    const item = copied[index];
    item[field] = value;
    // console.log(value);

    setClonnedData(copied);
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'studentName',
      key: 'studentName',
      width: 200,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Kelas',
      dataIndex: 'studentGrade',
      key: 'studentGrade',
      width: 150,
      render: (data) => <span>{data || 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Nama Wali Murid',
      dataIndex: 'guardianName',
      key: 'guardianName',
      width: 250,
      render: (data, r) => {
        const value = clonnedData.find((dt) => dt._id === r._id);

        return (
          <Input
            type="text"
            value={value?.guardianName || ''}
            // defaultValue={clonnedData[i]?.guardianName || ''}
            onChange={(e) => {
              handleChangeNamaWali(r._id, e.target.value, 'guardianName');
              console.log(e.target.value);
            }}
            placeholder="Masukan nama wali murid"
          />
        );
      },
    },
    {
      title: 'Status Wali Murid',
      dataIndex: 'guardianName',
      key: 'guardianName',
      width: 150,
      render: (data, r) => {
        const value = clonnedData.find((dt) => dt._id === r._id);

        return (
          <Select
            placeholder="Pilih status wali"
            value={value?.guardianStatus || 0}
            style={{ width: '100%' }}
            onChange={(val) => {
              handleChangeNamaWali(r._id, val, 'guardianStatus');
              console.log(val);
            }}
          >
            <Select.Option value={1}>Ibu</Select.Option>
            <Select.Option value={2}>Ayah</Select.Option>
            <Select.Option value={3}>Kakek</Select.Option>
            <Select.Option value={4}>Nenek</Select.Option>
            <Select.Option value={5}>Paman</Select.Option>
            <Select.Option value={6}>Bibi</Select.Option>
            <Select.Option value={7}>Lainnya</Select.Option>
          </Select>
        );
      },
    },
    {
      title: 'Input Nomor Whatsapp',
      dataIndex: 'guardianName',
      key: 'guardianName',
      width: 325,
      render: (data, r) => {
        return (
          <Input.Group compact>
            <PhoneCodesDropdown
              onChange={(val) => {
                handleChangeNamaWali(r._id, val, 'phone_code');
              }}
              defaultValue={'62'}
              style={{ width: '25%' }}
            />
            <Input
              type="tel"
              style={{ width: '75%' }}
              placeholder="Ketik Nomor Handphone Disini"
              onChange={(e) => {
                handleChangeNamaWali(r._id, e.target.value, 'phone_number');
                console.log(e.target.value);
              }}
            />
          </Input.Group>
        );
      },
    },
  ];

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu>
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      const response = await updateEmailGuardian(unitId, schoolYearId, {
        guardians: payload,
      });

      if (response) {
        console.log(response);
        message.success(
          'Berhasil memperbaharui data nomor whatsapp wali murid'
        );
        setLoading(false);
        refetchData();
      }
    } catch (err) {
      console.log(err);
      message.error(
        err?.data.m || 'Gagal memperbaharui data nomor whatsapp wali murid'
      );
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Update Nomor Whatsapp Wali Murid"
      visible={visible}
      onCancel={onClose}
      {...rest}
      centered
      cancelText="Batal"
      okText="Update"
      onOk={() => {
        const payload = clonnedData
          .filter(
            (el) => el.phone_code !== undefined && el.phone_number !== undefined
          )
          .map((dt) => ({
            guardianId: dt.studentGuardianId,
            name: dt.guardianName,
            status: dt.guardianStatus,
            phone_code: dt.phone_code,
            phone_number: dt.phone_number,
          }));

        console.log({ payload });

        Modal.confirm({
          title: 'Cek Kembali Nomor',
          icon: <ExclamationCircleOutlined />,
          content:
            'Mohon pastikan Nomor yang ada masukkan telah benar agar tidak ada kesalahan dalam mengirim info tunggakan dan lainnya.',
          onOk() {
            handleSubmit(payload);
          },
        });
      }}
      okButtonProps={{ loading: loading }}
      width="80vw"
    >
      <section style={{ margin: '1rem 0' }}>
        <article>
          <section
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '2rem',
            }}
          >
            <h3 style={{ fontWeight: 'bold' }}>
              Total Wali Murid: {data?.length || 0}
            </h3>
            <Space>
              <Input.Search
                placeholder="Cari nama murid"
                value={nameSearch}
                onChange={(e) => setNameSearch(e.target.value)}
              />

              <Dropdown
                disabled
                overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
              >
                <Button disabled icon={<DownOutlined />}>
                  {/* {selectedUnit || 'Semua Unit'} */}
                  {unit}
                </Button>
              </Dropdown>
            </Space>
          </section>
          {data ? (
            <Table
              columns={tableColumns}
              dataSource={clonnedData.filter((dt) =>
                dt.studentName
                  .toLowerCase()
                  .trim()
                  .includes(nameSearch.toLowerCase().trim())
              )}
              pagination={{
                position: ['bottomLeft'],
                simple: true,
                total: clonnedData.filter((dt) =>
                  dt.studentName
                    .toLowerCase()
                    .trim()
                    .includes(nameSearch.toLowerCase().trim())
                ).length,
                onChange: (page) => {
                  setCurrentPage(page);
                },
                current: currentPage,
              }}
              scroll={{ x: 1200, y: 350 }}
              loading={clonnedData?.length === 0}
            />
          ) : null}
        </article>
      </section>
    </Modal>
  );
};

export default UpdateEmailWali;

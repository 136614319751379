import { StyledCard } from './style';
import React from 'react';

const CardBase = ({ title, children, ...rest }) => {
  return (
    <StyledCard title={title} {...rest}>
      {children}
    </StyledCard>
  );
};

export default CardBase;

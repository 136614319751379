import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, Select } from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';
import { ArrayTanggal } from 'utils/helpers/common';

const EmailReminder = (props) => {
  const [form] = Form.useForm();
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    loadingState,
    ...rest
  } = props;
  const PilihanTanggal = ArrayTanggal(31);

  useEffect(() => {
    if (isEdit) form.setFieldsValue({ email_debt_reminder: value });
  }, [isEdit]);

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Tambah'} Tanggal Reminder`}
      width="40rem"
      visible={visible}
      footer={
        <Footer
          onCancel={() => {
            form.setFieldsValue({ email_debt_reminder: '' });
            onCancel();
          }}
          onSubmit={form.submit}
          loadingState={loadingState}
        />
      }
      onClose={() => {
        form.setFieldsValue({ email_debt_reminder: '' });
        onCancel();
      }}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          email_debt_reminder: isEdit ? value : '',
        }}
        onFinish={(val) => handleSubmit(val)}
      >
        <Form.Item name="email_debt_reminder" label="Tanggal Reminder">
          <Select>
            {PilihanTanggal.map((tgl) => {
              return <Select.Option value={tgl}>{tgl}</Select.Option>;
            })}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EmailReminder;

import { Button, Dropdown, Menu, message, Space, Table, Empty } from 'antd';
import { Modal, PageHeader } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import {
  getListTahunAjaran,
  getLaporanHarianAllUnit,
  getLaporanHarianUnit,
  updateLaporanHarian,
  downloadLaporanDashboard,
  getLaporanHarian,
} from 'utils/fetch';
import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { Loader } from 'components/atoms';
import { themeColor } from 'lib/theme';
import {
  generateKategoriPembayaran,
  generateReportByUnit,
} from 'utils/helpers/common';

const TableRow = (props) => {
  const { columns, data, ...rest } = props;

  return (
    <section
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1.6rem',
      }}
    >
      {columns.map((col, idx) => {
        const isLast = idx === columns.length - 1;

        return (
          <div
            key={col.key}
            style={{
              width: '200px',
              textAlign: isLast ? 'right' : 'left',
              borderBottom: '1px solid #f0f0f0',
              padding: '2rem 0',
            }}
          >
            {col.render(data[col.dataIndex])}
          </div>
        );
      })}
    </section>
  );
};

const LaporanHarian = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    state: { activeSchoolYear, unitData, userData },
  } = useStore();
  const [selectedMonth, setSelectedMonth] = useState({
    name: '',
    value: '',
  });
  const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [lastUpdated, setLastUpdated] = useState('');
  const [otherData, setOtherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const schoolYearId = activeSchoolYear?._id;
  const arrayUnit = useMemo(
    () => Object.keys(unitData).map((key) => unitData[key]),
    [unitData]
  );

  const [data, setData] = useState(null);
  const [filter, setFilter] = useState({ units: [], month: '' });
  const [filterModal, setFilterModal] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [allData, setAllData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [userUnits, setUserUnits] = useState([]);

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setSelectedYear(activeSchoolYear);
      console.log({ activeSchoolYear });
    }
  }, [activeSchoolYear]);

  const listBulan = useMemo(() => {
    return selectedYear
      ? Array.from({ length: 12 }, (_, i) => {
          const date = new Date(selectedYear.start_date);
          const next = new Date(date.setMonth(date.getMonth() + i));

          return {
            name: `${moment(next).format('MMMM YYYY')}`,
            value: next,
          };
        })
      : [];
  }, [selectedYear]);

  useEffect(() => {
    if (arrayUnit.length && userData) {
      const defaultUnits = userData?.units?.length
        ? userData?.units?.map((unit) => unit?._id)
        : arrayUnit.map((unit) => unit?._id);

      setFilter({ units: defaultUnits });
      setUserUnits(defaultUnits);
    } else {
      setFilter({
        units: [],
        cutoffDate: '',
      });
    }
  }, [arrayUnit, userData]);

  useEffect(() => {
    if (userData?.unit) {
      setSelectedUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/laporan-harian',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>
              Dashboard Laporan Harian
            </p>
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['2']);
  }, [lastUpdated]);

  useEffect(() => {
    fetchDataTahunAjaran();
    const next = new Date();

    const date = {
      name: `${moment(next).format('MMMM YYYY')}`,
      value: next,
    };

    setSelectedMonth(date);
    setFilter((p) => ({ ...p, month: moment(next).format('MM-DD-YYYY') }));
  }, []);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
        setListTahunAjaran(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (filter.month && filter.units.length) {
      _fetchDataLaporanharian(filter);
    }
  }, [filter]);

  const _fetchDataLaporanharian = async (filter) => {
    try {
      setLoading(true);
      const response = await getLaporanHarian(filter);
      console.log({ response });
      const { excelHarian } = response.value;
      const tableHeader = excelHarian[1];
      const tableData = excelHarian.slice(2);
      const data = tableData?.map((dt, idx) => {
        const headerData = tableHeader?.reduce((acc, curr, index) => {
          acc[curr] = dt[index];

          return acc;
        }, {});
        return headerData;
      });
      const columns = tableHeader?.map((header) => ({
        title: header,
        dataIndex: header,
        key: header,
        width: `calc(100% / ${tableHeader.length})`,
        render: (data) =>
          header === 'Tanggal' ? (
            <span>{data}</span>
          ) : (
            <span>Rp{Number(data || 0).toLocaleString()}</span>
          ),
      }));
      console.log({ data, columns });
      setTableColumns(columns);
      setData(data);
      setOtherData(data[data.length - 1]);
      setAllData(response.value);
      setFilterModal(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const MenuBulan = ({ onClick }) => {
    const [year, setYear] = useState('');

    useEffect(() => {
      setYear('');

      return () => setYear('');
    }, []);

    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          maxHeight: '25rem',
          overflowY: 'scroll',
          paddingRight: '1rem',
        }}
      >
        {!year
          ? listTahunAjaran.map((ta, idx) => {
              return (
                <Menu.Item
                  key={idx}
                  onClick={() => {
                    setSelectedYear(ta);
                    setYear(ta);
                  }}
                >
                  {ta.name}
                </Menu.Item>
              );
            })
          : listBulan.map((month) => (
              <Menu.Item key={month.value} onClick={() => onClick(month)}>
                {month.name}
              </Menu.Item>
            ))}
      </Menu>
    );
  };

  const keys = otherData ? Object.keys(otherData) : [];

  const parsedDataPayments = (data = []) => {
    const parsed = [
      [
        'No',
        'Tanggal',
        'Nama',
        'NIS',
        'Kelas',
        'Nama Tagihan',
        'Total Nominal',
        'Item Tagihan',
      ],
    ];

    data.forEach((dt, idx) => {
      const itemList = dt.paid_items
        ?.map((item) => {
          if (item.amount > 0) {
            return `${item.typeName}: ${item.amount.toLocaleString()}`;
          }
        })
        .filter(Boolean)
        ?.join('; ');

      const list = [
        idx + 1,
        moment(dt.date).format('DD/MM/YYYY'),
        dt.student?.name,
        dt.student?.nik || '-',
        dt.student?.grade?.name || '-',
        dt.name,
        dt.total_amount,
        itemList || '-',
      ];

      parsed.push(list);
    });

    return parsed;
  };

  const downloadLaporan = async () => {
    try {
      setLoadingDownload(true);
      const parsedKategoriPembayaran = generateKategoriPembayaran(
        allData?.resultDays
      );
      const parsedResultByUnit = generateReportByUnit(allData?.resultDays);
      const parsedPayments = parsedDataPayments(allData?.payments);

      const multipleData = [
        { data: allData?.excelHarian, sheetName: 'Laporan Harian Keseluruhan' },
        {
          data: parsedKategoriPembayaran,
          sheetName: 'Laporan Kategori Pembayaran',
        },
        { data: parsedPayments, sheetName: 'Pembayaran' },
        ...parsedResultByUnit,
      ];

      console.log(multipleData);

      exportExcel(multipleData, 'Laporan Harian', true);
      setLoadingDownload(false);
    } catch (err) {
      setLoadingDownload(false);
      console.log(err);
      message.error(err?.data?.m || 'Gagal download laporan harian');
    }
  };

  const tableColumnsUnit = [];

  return (
    <>
      <PageHeader>
        <Space>
          <Button onClick={() => setFilterModal(true)}>Pilih Unit</Button>
          <Dropdown
            overlay={
              <MenuBulan
                onClick={(v) => {
                  setSelectedMonth(v);
                  setFilter((p) => ({
                    ...p,
                    month: moment(v.value).format('MM-DD-YYYY'),
                  }));
                }}
              />
            }
          >
            <Button>{selectedMonth?.name || '-'}</Button>
          </Dropdown>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadLaporan}
            loading={loadingDownload}
          >
            Download Laporan
          </Button>
        </Space>
      </PageHeader>
      <div style={{ width: '100%', margin: '2rem 0', overflowX: 'scroll' }}>
        <div style={{ width: '100%', minWidth: '100rem' }}>
          <div
            style={{
              padding: '1.6rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              color: '#000000D9',
              backgroundColor: '#FAFAFA',
            }}
          >
            {(
              ((selectedUnit ? tableColumnsUnit : tableColumns)?.length &&
                (selectedUnit ? tableColumnsUnit : tableColumns)) ||
              []
            ).map((item, idx) => {
              // const isLast = idx === keys.length - 1;
              return (
                <div
                  style={{
                    fontWeight: 'bold',
                    // width: isLast ? '300px' : 100,
                    width: '200px',
                    // paddingRight: idx < 1 || idx === 6 ? '0' : '1.6rem',
                    // paddingLeft: idx === 4 || idx === 5 ? '1.6rem' : '0',
                    textAlign:
                      (selectedUnit
                        ? tableColumnsUnit?.length
                        : tableColumns?.length) -
                        1 ==
                      idx
                        ? 'right'
                        : 'left',
                  }}
                >
                  {item?.title}
                </div>
              );
            })}
          </div>
          <div style={{ width: '100%', margin: '2rem 0', overflowY: 'scroll' }}>
            <div style={{ height: 'calc(100vh - 350px)', minHeight: '20rem' }}>
              {/* <Table
                dataSource={data}
                columns={selectedUnit ? tableColumnsUnit : tableColumns}
                pagination={false}
                loading={loading}
                showHeader={false}
              /> */}
              {!loading ? (
                data ? (
                  data?.map((dt) => {
                    return (
                      <TableRow
                        columns={selectedUnit ? tableColumnsUnit : tableColumns}
                        data={dt}
                      />
                    );
                  })
                ) : (
                  <Empty />
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>

          <div
            style={{
              padding: '1.6rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {keys.map((key, idx) => {
              const isLast = idx === keys.length - 1;
              return (
                <div
                  style={{
                    color: themeColor.success,
                    fontWeight: 'bold',
                    // width: isLast ? '300px' : 100,
                    width: `200px`,
                    textAlign: isLast ? 'right' : 'left',
                  }}
                >
                  {idx < 1
                    ? 'Total'
                    : //  otherData[key]
                      `Rp${Number(otherData[key]).toLocaleString()}`}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Modal.FilterUnits
        visible={filterModal}
        onClose={() => setFilterModal(false)}
        onSelectedFilter={(values) => setFilter((p) => ({ ...p, ...values }))}
        units={filter.units}
        userUnits={userUnits}
      />
    </>
  );
};

export default LaporanHarian;

import {
  DownOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Table, Space, Input, Dropdown, Button, Menu, Popover } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStore } from 'store';
import queryString from 'querystring';
import { unitSlug } from 'utils/helpers/common';

const DashboardPPDBTunggakan = ({ data, type }) => {
  const {
    state: { unitData },
  } = useStore();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  //   const [data, setData] = useState([]);
  const [copiedData, setCopiedData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  //   useEffect(() => {
  //     fetchData();
  //   }, [selectedUnit]);

  useEffect(() => {
    if (data) {
      setCopiedData(data);
      setTotalData(data.length);
    }
  }, [data]);

  useEffect(() => {
    if (nameSearch) {
      const filtered = data.filter((item) =>
        item.student_name
          .toLowerCase()
          .trim()
          .includes(nameSearch.toLowerCase().trim())
      );

      setCopiedData(filtered);
    } else {
      setCopiedData(data);
    }
  }, [nameSearch]);

  useEffect(() => {
    return () => setCurrentPage(1);
  }, []);

  //   const fetchData = () => {
  //     let query = `unitId=${selectedUnit?._id || ''}&month=${
  //       selectedMonth?.value || ''
  //     }`;

  //     setLoading(true);

  //     detailRincianDashboard('student-asak-list', query)
  //       .then((res) => {
  //         console.log(res);
  //         setData(res.refundList);
  //         setTotalData(res.totalData);
  //       })
  //       .catch((err) => console.log(err.response, 'error fetch'))
  //       .finally(() => setLoading(false));
  //   };

  const tableColumns = {
    ppdb: [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        width: 80,
        render: (data, record, idx) => <span>{idx + 1}</span>,
      },
      {
        title: 'Nama Murid',
        dataIndex: 'student_name',
        key: 'student_name',
        width: 300,
        render: (data) => <span>{data || '-'}</span>,
      },
      {
        title: 'Tunggakan',
        dataIndex: 'total_debt',
        key: 'total_debt',
        render: (data, record) => {
          return (
            <span style={{ color: '#FF4D4F' }}>
              {data > 0 ? `-Rp${(data || 0).toLocaleString()}` : 'Lunas'}
            </span>
          );
        },
      },
      {
        title: 'Jumlah Cicilan',
        dataIndex: 'total_bill_count',
        key: 'total_bill_count',
        render: (data, record) => {
          return (
            <Space>
              <span>{data}</span>
              {record?.bills?.length ? (
                <Popover
                  placement="top"
                  content={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Daftar Cicilan Belum Lunas</div>
                      {record?.bills.map((item, index) => {
                        return (
                          <div
                            style={{
                              flexDirection: 'row',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              lineHeight: '22px',
                              flex: 1,
                              display: 'flex',
                              gap: '.8rem',
                            }}
                          >
                            <div style={{ color: '#262626' }}>
                              {' '}
                              - {item?.bill_name}
                            </div>
                            <div style={{ color: '#FF4D4F' }}>
                              Rp{(item?.amount || 0).toLocaleString()}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                >
                  <ExclamationCircleOutlined
                    style={{ cursor: 'pointer', color: '#1890FF' }}
                  />
                </Popover>
              ) : null}
            </Space>
          );
        },
      },
      {
        title: 'Kelas',
        dataIndex: 'grade_name',
        key: 'grade',
        render: (data) => <span>{data || 'Murid Tanpa Kelas'}</span>,
      },
      {
        title: 'Unit',
        dataIndex: 'unit_name',
        key: 'unit_name',
        width: 100,
        render: (data) => {
          return <span>{data || '-'}</span>;
        },
      },
      {
        title: '',
        dataIndex: '_id',
        key: '_id',
        fixed: 'right',
        width: '120',
        render: (data, record) => (
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              history.push({
                pathname: `/daftar-murid/detail/${data}`,
                search: queryString.stringify({
                  unit: unitSlug[record?.unit?.name] || '',
                  tabs: 'tunggakan',
                }),
              });
            }}
          >
            Lihat
          </Button>
        ),
      },
    ],
    spp: [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        width: 80,
        render: (data, record, idx) => <span>{idx + 1}</span>,
      },
      {
        title: 'Nama Murid',
        dataIndex: 'student_name',
        key: 'student_name',
        width: 300,
        render: (data) => <span>{data || '-'}</span>,
      },
      {
        title: 'Tunggakan Uang Sekolah',
        dataIndex: 'total_debt',
        key: 'total_debt',
        render: (data, record) => {
          return (
            <span style={{ color: data > 0 ? '#FF4D4F' : 'green' }}>
              {data > 0 ? `-Rp${(data || 0).toLocaleString()}` : 'Lunas'}
            </span>
          );
        },
      },
      {
        title: 'Jumlah Cicilan',
        dataIndex: 'total_bill_count',
        key: 'total_bill_count',
        render: (data, record) => {
          return (
            <Space>
              <span>{data}</span>
              {record?.bills?.length ? (
                <Popover
                  placement="top"
                  content={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Daftar Uang Sekolah Belum Lunas</div>
                      {record?.bills.map((item, index) => {
                        return (
                          <div
                            style={{
                              flexDirection: 'row',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              lineHeight: '22px',
                              flex: 1,
                              display: 'flex',
                              gap: '.8rem',
                            }}
                          >
                            <div style={{ color: '#262626' }}>
                              {' '}
                              - {item?.bill_name}
                            </div>
                            <div style={{ color: '#FF4D4F' }}>
                              Rp{(item?.amount || 0).toLocaleString()}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                >
                  <ExclamationCircleOutlined
                    style={{ cursor: 'pointer', color: '#1890FF' }}
                  />
                </Popover>
              ) : null}
            </Space>
          );
        },
      },
      {
        title: 'Kelas',
        dataIndex: 'grade_name',
        key: 'grade',
        render: (data) => <span>{data || 'Murid Tanpa Kelas'}</span>,
      },
      {
        title: 'Unit',
        dataIndex: 'unit_name',
        key: 'unit_name',
        width: 100,
        render: (data) => {
          return <span>{data || '-'}</span>;
        },
      },
      {
        title: '',
        dataIndex: 'student_id',
        key: 'student_id',
        fixed: 'right',
        width: '120',
        render: (data, record) => (
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              history.push({
                pathname: `/daftar-murid/detail/${data}`,
                search: queryString.stringify({
                  unit: record.unit_name.toLowerCase(),
                  tabs: 'tunggakan',
                }),
              });
            }}
          >
            Lihat
          </Button>
        ),
      },
    ],
    general: [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        width: 80,
        render: (data, record, idx) => <span>{idx + 1}</span>,
      },
      {
        title: 'Nama Murid',
        dataIndex: 'student_name',
        key: 'student_name',
        width: 300,
        render: (data) => <span>{data || '-'}</span>,
      },
      {
        title: 'Nominal',
        dataIndex: 'total_debt',
        key: 'total_debt',
        render: (data) => {
          return <span>{`Rp${(data || 0).toLocaleString()}`}</span>;
        },
      },
      {
        title: 'Jumlah Cicilan',
        dataIndex: 'total_bill_count',
        key: 'total_bill_count',
        render: (data, record) => {
          return (
            <Space>
              <span>{data}</span>
              {record?.bills?.length ? (
                <Popover
                  placement="top"
                  content={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Daftar Tagihan yang Belum Lunas:</div>
                      {record?.bills.map((item, index) => {
                        return (
                          <div
                            style={{
                              flexDirection: 'row',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              lineHeight: '22px',
                              flex: 1,
                              display: 'flex',
                              gap: '.8rem',
                            }}
                          >
                            <div style={{ color: '#262626' }}>
                              {' '}
                              - {item?.bill_name}
                            </div>
                            <div style={{ color: '#FF4D4F' }}>
                              Rp{(item?.amount || 0).toLocaleString()}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                >
                  <ExclamationCircleOutlined
                    style={{ cursor: 'pointer', color: '#1890FF' }}
                  />
                </Popover>
              ) : null}
            </Space>
          );
        },
      },
      {
        title: 'Kelas',
        dataIndex: 'grade_name',
        key: 'grade',
        render: (data) => <span>{data || 'Murid Tanpa Kelas'}</span>,
      },
      {
        title: 'Unit',
        dataIndex: 'unit_name',
        key: 'unit_name',
        width: 100,
        render: (data) => {
          return <span>{data || '-'}</span>;
        },
      },
      {
        title: '',
        dataIndex: 'student_id',
        key: 'student_id',
        fixed: 'right',
        width: '120',
        render: (data, record) => (
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              history.push({
                pathname: `/daftar-murid/detail/${data}`,
                search: queryString.stringify({
                  unit: record.unit_name.toLowerCase(),
                  tabs: 'tunggakan',
                }),
              });
            }}
          >
            Lihat
          </Button>
        ),
      },
    ],
  };

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu>
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <article>
      <section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <h3>Total Murid: {(totalData || 0).toLocaleString()}</h3>
        <Space>
          <Input.Search
            placeholder="Cari nama murid"
            value={nameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
          />

          <Dropdown
            disabled
            overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
          >
            <Button disabled icon={<DownOutlined />}>
              {selectedUnit?.name || 'Semua Unit'}
            </Button>
          </Dropdown>
        </Space>
      </section>
      <Table
        columns={tableColumns[type]}
        dataSource={copiedData}
        pagination={{
          position: ['bottomLeft'],
          total: totalData && totalData,
          onChange: (page) => {
            setCurrentPage(page);
          },
          current: currentPage,
          pageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ['10', '30', '50'],
          onShowSizeChange: (curr, pageSize) => {
            setLimit(pageSize);
          },
        }}
        scroll={{ x: 1200, y: 400 }}
        loading={loading}
      />
    </article>
  );
};

export default DashboardPPDBTunggakan;

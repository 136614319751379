import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  message,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Drawer, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getListKelasId,
  getListTahunAjaran,
  getAlumniMenunggak,
  getAlumniMenunggakDownload,
  getHistoryWhatsapp,
  deleteWhatsappMessage,
} from 'utils/fetch';
import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EyeOutlined,
  FilterOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import {
  messageType,
  optionsStatusWhatsappNew,
  statusMurid,
  statusWhatsapp,
  statusWhatsappNew,
} from 'utils/helpers/common';
import exportExcel from 'utils/helpers/xlsx';
import moment from 'moment';
import { goToSearchQuery } from 'utils/helpers/common';

const RiwayatNotifikasi = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, unitData, userData, listKelas, selectedUnit },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState({
    data: null,
    state: false,
  });
  const [data, setData] = useState([]);
  //   const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [otherData, setOtherData] = useState({
    notYetSent: 0,
    failToSent: 0,
    sent: 0,
  });
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [filterPopover, setFilterPopover] = useState(false);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    start_date: null,
    end_date: null,
    phone: null,
    unit: null,
    whatsapp_status: null,
    message_type: null,
  });
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    student_name,
    grade,
    start_date,
    end_date,
    phone,
    whatsapp_status,
    message_type,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    unit,
    limit,
    page,
    student_name,
    grade,
    start_date,
    end_date,
    phone,
    whatsapp_status,
    message_type,
  };
  const arrayUnit = useMemo(
    () => Object.keys(unitData).map((key) => unitData[key]),
    [unitData]
  );
  const unitId = useMemo(() => selectedUnit?._id, [selectedUnit]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/riwayat-notifikasi',
        node: <span>Riwayat Notifikasi</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['6']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);
    fetchDataTahunAjaran();
  }, []);

  useEffect(() => {
    fetchData({
      page,
      limit,
      student_name,
      start_date,
      end_date,
      phone,
      whatsapp_status,
      grade,
      message_type,
      unit,
    });

    return () => {};
  }, [
    page,
    limit,
    student_name,
    start_date,
    end_date,
    phone,
    whatsapp_status,
    grade,
    message_type,
    unit,
  ]);

  useEffect(() => {
    setFilter({
      student_name,
      start_date,
      end_date,
      phone,
      whatsapp_status,
      grade,
      unit,
      message_type,
    });
  }, [
    student_name,
    start_date,
    end_date,
    phone,
    whatsapp_status,
    grade,
    message_type,
    unit,
  ]);

  useEffect(() => {
    if (filter.unit) {
      fetchDataListKelas(filter.unit);
    }
  }, [filter.unit]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = (unit) => {
    getListKelasId(unit, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = (query = {}) => {
    setLoading(true);
    if (!query.unit) {
      delete query['unit'];
    }

    query.page = Number(query.page) - 1;

    getHistoryWhatsapp(query)
      .then((res) => {
        // console.log({ res }, 'wa');
        setOtherData({
          notYetSent: res.notYetSent,
          sent: res.sent,
          failToSent: res.failToSent,
        });
        setData(res.sendWhatsapps);
        setTotalData(res.totalData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response, 'this is error fetch');
      });
  };

  const handleDelete = (id) => {
    setLoadingDownload(true);

    deleteWhatsappMessage(id)
      .then((res) => {
        console.log({ res });
        message.success('Berhasil menghapus pesan');
      })
      .catch((err) => {
        message.error('Gagal menghapus pesan');
      })
      .finally(() => setLoadingDownload(false));
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      width: 275,
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Unit dan Kelas',
      dataIndex: 'student',
      key: 'student',
      width: 200,
      render: (data, record) => (
        <span>
          {record?.unit?.name} - {data?.grade?.name || 'Tanpa Kelas'}
        </span>
      ),
    },
    {
      title: 'No Handphone',
      dataIndex: 'phone',
      key: 'phone',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tipe Pesan',
      dataIndex: 'message_type',
      key: 'message_type',
      render: (data) => <span>{messageType[data]}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'whatsapp_status',
      key: 'whatsapp_status',
      render: (data) => {
        return (
          <Tag
            style={{ textTransform: 'capitalize' }}
            color={statusWhatsappNew[data || 'pending'].color}
          >
            {data}
          </Tag>
        );
      },
    },
    {
      title: 'Update Terakhir',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (data) => (
        <span>{moment(data).format('DD/MM/YYYY HH:mm:ss')}</span>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: '_id',
    //   key: '_id',
    //   align: 'center',
    //   fixed: 'right',
    //   width: 220,
    //   render: (id, record) => (
    //     <Space>
    //       <Button
    //         icon={<EyeOutlined />}
    //         onClick={() => {
    //           setOpenDrawer({
    //             data: record,
    //             id,
    //             state: true,
    //           });
    //         }}
    //       >
    //         Lihat
    //       </Button>
    //       <Button
    //         icon={<DeleteOutlined />}
    //         danger
    //         onClick={() => {
    //           handleDelete(id);
    //         }}
    //         loading={loadingDownload}
    //       >
    //         Hapus
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu>
        <Menu.Item key='all' onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        limit,
        page,
        ...filter,
      }),
    });
    setFilterPopover(false);
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Input
          type='text'
          name='student_name'
          value={filter.student_name}
          placeholder='Nama Murid'
          onChange={(e) => {
            const newFilter = {
              ...filter,
              student_name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Input
          type='text'
          name='phone'
          value={filter.phone}
          placeholder='Nomor Whatsapp'
          onChange={(e) => {
            const newFilter = {
              ...filter,
              phone: e.target.value,
            };
            setFilter(newFilter);
          }}
          allowClear
        />
        <Select
          placeholder='Unit'
          style={{ width: '100%' }}
          value={filter.unit}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              unit: val,
            };
            setFilter(newFilter);
          }}
          allowClear
        >
          {arrayUnit.map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          placeholder='Kelas'
          style={{ width: '100%' }}
          value={filter.grade}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            console.log(newFilter);
            setFilter(newFilter);
          }}
          disabled={!filter.unit}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          placeholder='Status Pengiriman'
          style={{ width: '100%' }}
          value={filter.whatsapp_status}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              whatsapp_status: val,
            };
            setFilter(newFilter);
          }}
        >
          {optionsStatusWhatsappNew.map((kelas) => {
            return (
              <Select.Option value={kelas.key}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        {/* <Select
          placeholder='Tipe Pesan'
          style={{ width: '100%' }}
          value={filter.message_type}
          onChange={(val) => {
            console.log({ val }, 'message_type');
            const newFilter = {
              ...filter,
              message_type: val,
            };
            setFilter(newFilter);
          }}
        >
          {[
            { key: 1, name: 'Reminder' },
            { key: 2, name: 'Pembayaran' },
            { key: 3, name: 'Lainnya' },
            { key: 4, name: 'Admin' },
          ].map((kelas) => {
            return (
              <Select.Option value={kelas.name}>{kelas.name}</Select.Option>
            );
          })}
        </Select> */}

        <DatePicker.RangePicker
          // placeholder="Tanggal Bayar"
          style={{ width: '100%' }}
          onChange={(e, dateString) => {
            const newFilter = {
              ...filter,
              start_date: dateString[0],
              end_date: dateString[1],
            };
            setFilter(newFilter);
            console.log(dateString, 'this is result from range picker');
          }}
        />
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  grade: null,
                  start_date: null,
                  end_date: null,
                  phone: null,
                  unit: null,
                  whatsapp_status: null,
                  message_type: null,
                });
                setCurrentPage(1);
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type='primary' onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const parsedResult = (data = []) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Total Tunggakan',
        'Total Tagihan',
        'Tunggakan Unit',
        'Status',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.totalDebtAmount,
        dt.totalDebtBills,
        dt.unit?.name,
        statusMurid[dt.status || 0]?.status,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const downloadLaporan = () => {
    setLoadingDownload(true);
    let queryDownload = `unitId=${selectedUnit?._id || ''}`;
    getAlumniMenunggakDownload(queryDownload)
      .then((res) => {
        setLoadingDownload(false);
        const parsedData = parsedResult(res?.totalStudents);
        exportExcel(parsedData, 'Laporan Alumni Menunggak');
      })
      .catch((err) => {
        setLoadingDownload(false);
      });
  };

  return (
    <>
      <PageHeader></PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement='bottomLeft'
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>

      <Row gutter={[24, 0]}>
        <Col span='8'>
          <InfoCard
            title='Total Terkirim'
            content={`${(otherData?.sent || 0).toLocaleString()}`}
            sideColor='green'
          />
        </Col>
        <Col span='8'>
          <InfoCard
            title='Total Belum Terkirim'
            content={`${(otherData?.notYetSent || 0).toLocaleString()}`}
            sideColor='yellow'
          />
        </Col>
        <Col span='8'>
          <InfoCard
            title='Total Gagal Terkirim'
            content={`${(otherData?.failToSent || 0).toLocaleString()}`}
            sideColor='red'
          />
        </Col>
      </Row>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            limit: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1200 }}
          loading={loading}
        />
        {/* {JSON.stringify(data)} */}
      </section>

      {/* <Modal.DetailPesanWA
        onClose={() => {
          setOpenDrawer({
            data: null,
            id: null,
            state: false,
          });
        }}
        visible={openDrawer.state}
        id={openDrawer.id}
        data={openDrawer.data}
        refetchData={() => fetchData(filterQuery)}
      /> */}
    </>
  );
};

export default RiwayatNotifikasi;

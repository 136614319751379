import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Table,
  InputNumber,
  DatePicker,
  Select,
  Alert,
  Input,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import moment from 'moment';
import { InfoCard } from 'components/fragments/card';
import YellowCard from 'components/atoms/card-base/yellow-card';
import {
  allocate,
  allocationHistory,
  editIncomingFunds,
  getDetailDanaMasuk,
  getOutstandingBills,
  refundDanaMasuk,
} from 'utils/fetch';
import { statusMurid, tipeMurid } from 'utils/helpers/common';
import { themeColor } from 'lib/theme';

const PembayaranDanaMasuk = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, ...rest } = props;
  const [displayItem, setDisplayItem] = useState(0);
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: null,
  });
  const [billsAmount, setBillsAmount] = useState(0);
  const [outstandingBills, setOutstandingBills] = useState([]);
  const [funds, setFunds] = useState([]);
  const [allocation, setAllocation] = useState([]);
  const [dateFunds, setDateFunds] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { id = '', unitId = '', student = null } = value;

  const ArrayTanggal = Array.from({ length: 31 }, (_, i) => i + 1);

  useEffect(() => {
    if (!visible) {
      setDisplayItem(0);
      setIsEdit({
        state: false,
        data: null,
      });
    }
  }, [visible]);

  useEffect(() => {
    if (id) {
      fetchOutstandingBills();
    }
  }, [id]);

  const fetchOutstandingBills = () => {
    getOutstandingBills(student._id)
      .then((res) => {
        setOutstandingBills(res.bills);
        console.log(res);
        const allocationObj = res.bills.map((bill) => ({
          _id: bill._id,
          allocation: 0,
          remaining: bill.remaining,
          type: bill.type,
          date: bill.date,
        }));
        form.setFieldsValue({ bills: allocationObj });
        setBillsAmount(res.billsAmount);
      })
      .catch((err) => console.log(err, 'error fetch outstanding bills'));
  };

  useEffect(() => {
    if (isEdit.state) {
      setDateFunds(new Date(isEdit.data.date).toLocaleDateString());
      form.setFieldsValue({
        amount: isEdit?.data?.amount,
        date: moment(isEdit?.data?.date),
        notes: isEdit?.data?.notes,
      });
    }
  }, [isEdit]);

  const handleSubmitEditIncomingFunds = (values) => {
    console.log(values);
    editIncomingFunds(unitId, id.id, isEdit.data._id, values)
      .then((res) => {
        console.log(res);
        setIsEdit({
          state: false,
          data: null,
        });
      })
      .catch((err) => console.log(err.response));
  };
  const InfoMurid = () => (
    <>
      <YellowCard>
        <Row>
          <Col span="12">
            <h2 style={{ color: themeColor.primary }}>{student?.name || ''}</h2>
            <Space>
              {/* <Tag color={statusMurid[student?.status || 0].color}>
                {statusMurid[student?.status || 0].status}
              </Tag>
              <Tag color={tipeMurid[student?.type || 0].color}>
                {tipeMurid[student?.type || 0].text}
              </Tag> */}
            </Space>
          </Col>
          <Col span="1">
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span="11">
            <Row gutter={[8, 8]}>
              <Col span="12">NIS</Col>
              <Col span="12">{student?.nik || '-'}</Col>
            </Row>
            {student?.grade?.name ? (
              <Row gutter={[8, 8]}>
                <Col span="12">Kelas</Col>
                <Col span="12">
                  {student?.grade?.name || 'Murid Tanpa Kelas'}
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row gutter={[8, 8]}>
              <Col span="12">Nomor VA</Col>
              <Col span="12">{student?.virtual_account || '-'}</Col>
            </Row>
          </Col>
        </Row>
      </YellowCard>

      <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            title="Total Dana Masuk Belum Alokasi"
            content={`Rp${(student?.funds || 0).toLocaleString()}`}
            sideColor="green"
          />
        </Col>
        <Col span="12">
          <InfoCard
            title="Total Tunggakan"
            content={
              billsAmount ? `-Rp${(billsAmount || 0).toLocaleString()}` : '-'
            }
            fontColor={billsAmount ? 'red' : ''}
            sideColor="red"
          />
        </Col>
      </Row>
    </>
  );

  const submitAlokasi = (payload) => {
    setLoadingSubmit(true);
    allocate(unitId, 'fund', student._id, payload)
      .then((res) => {
        console.log(res);
        onCancel();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response, 'error alokasi');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const Alokasi = () => (
    <>
      <InfoMurid />
      <Form
        layout="vertical"
        form={form}
        onFinish={(v) => {
          const bills = v.bills.map((bill) => ({
            _id: bill._id,
            type: bill.type,
            allocation: Number(bill.allocation),
            date: bill.date,
          }));
          const total = bills.reduce(
            (curr, acc) => curr + Number(acc.allocation),
            0
          );

          const payload = {
            bills,
            savings: student?.savings,
            total_allocation: total,
          };

          console.log(payload);
          submitAlokasi(payload);
        }}
      >
        <Form.List name="bills">
          {(fields, { add, remove }) => {
            return fields.map((field, idx) => {
              return (
                <section style={{ marginTop: '2rem' }}>
                  <h3>Alokasi ke {outstandingBills[idx]?.name || '-'}</h3>
                  <Row gutter={[18, 8]}>
                    <Col span="12">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        formItemProps={{
                          name: [field.name, 'remaining'],
                        }}
                        // defaultValue={student?.funds || 0}
                        label="Total Tagihan"
                        disabled
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                    <Col span="12">
                      <InputWrapper
                        type="number"
                        prefix="Rp"
                        formItemProps={{
                          name: [field.name, 'allocation'],
                        }}
                        label="Nominal Alokasi"
                        formatter={(value) =>
                          `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                      />
                    </Col>
                  </Row>
                </section>
              );
            });
          }}
        </Form.List>
        {/* <section style={{ marginTop: '2rem' }}>
          <h3>Alokasi ke Tabungan</h3>
          <Row gutter={[18, 8]}>
            <Col span="12">
              <InputWrapper
                type="text"
                prefix="Rp"
                name="nominal"
                defaultValue={student?.funds || 0}
                label="Total Tabungan"
                disabled
              />
            </Col>
            <Col span="12">
              <InputWrapper
                type="text"
                prefix="Rp"
                name="change_nominal"
                label="Nominal Alokasi"
              />
            </Col>
          </Row>
        </section> */}
      </Form>
    </>
  );

  return (
    <Drawer
      title={`Pembayaran Tunggakan Alumni/Murid Tidak Aktif`}
      width={`50vw`}
      visible={visible}
      footer={
        <Footer
          onCancel={() => {
            if (isEdit) {
              setIsEdit(false);
            } else {
              onCancel();
            }
          }}
          onSubmit={() => form.submit()}
          loadingState={loadingSubmit}
        />
      }
      onClose={onCancel}
      {...rest}
    >
      <Alokasi />
    </Drawer>
  );
};

export default PembayaranDanaMasuk;

import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Button,
  Form,
  Input,
  Checkbox,
  Space,
  message,
  Col,
  Row,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper } from '..';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getListKelasSebelumnya } from 'utils/fetch';

const TambahKelas = (props) => {
  const [form] = Form.useForm();
  const [previousGrades, setPreviousGrades] = useState(null);
  const [filled, setFilled] = useState([]);
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    unitId,
    loadingState,
    ...rest
  } = props;

  useEffect(() => {
    if (isEdit) form.setFieldsValue({ kode: value });
  }, [isEdit]);

  useEffect(() => {
    if (!visible) form.resetFields();
  }, [visible]);

  const handleChecked = (e) => {
    console.log(e.target.checked);
    if (e.target.checked === true) {
      if (!previousGrades) {
        getListKelasSebelumnya(unitId)
          .then((res) => {
            console.log(res, 'this is kelas sebelumnya');
            form.setFieldsValue({ grades: res.previousGrades });
            if (res.previousGrades.length === 0) {
              setFilled(['']);
              setPreviousGrades(['']);
            } else {
              setPreviousGrades(res.previousGrades);
              setFilled(res.previousGrades);
            }
          })
          .catch((err) => {
            console.log(err);
            message.error('Gagal memuat kelas sebelumnya');
          });
      }

      form.setFieldsValue({ grades: previousGrades });
      setFilled(previousGrades);
    } else {
      form.resetFields();
      setPreviousGrades(null);
    }
  };

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Tambah'} Kelas`}
      width="50rem"
      visible={visible}
      footer={
        <Footer
          onCancel={() => {
            form.setFieldsValue({ grades: [{}] });
            onCancel();
          }}
          onSubmit={form.submit}
          // submitDisabled={!filled?.length || !filled[0]?.length}
          loadingState={loadingState}
        />
      }
      onClose={() => {
        form.setFieldsValue({ grades: [{}] });
        onCancel();
      }}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          grades: isEdit ? value : [{ name: '', discount_amount: 0 }],
        }}
        onFinish={(val) => handleSubmit(val)}
      >
        <Space style={{ marginBottom: '1.5rem' }}>
          <Checkbox onChange={handleChecked}>
            Gunakan daftar kelas sebelumnya
          </Checkbox>
        </Space>
        <Form.List name="grades">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row style={{ width: '100%' }} gutter={[8, 8]}>
                  <Col span={11}>
                    <InputWrapper
                      name={[field.key, 'name']}
                      label={`Nama Kelas ${field.key + 1}`}
                      type="text"
                      placeholder="Ketik nama kelas"
                      formItemProps={{
                        name: [field.key, 'name'],
                        style: { width: '100%' },
                      }}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col span={11}>
                    <InputWrapper
                      type="number"
                      name={[field.key, 'discount_amount']}
                      formItemProps={{
                        name: [field.key, 'discount_amount'],
                        style: { width: '100%' },
                      }}
                      prefix="Rp"
                      label="Nominal Diskon Uang Sekolah"
                      placeholder="Rp 500.000"
                      formatter={(value) =>
                        `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                    />
                  </Col>
                  <Col
                    span={2}
                    style={{
                      width: '100%',
                      display: 'grid',
                      placeContent: 'center',
                      paddingTop: '1.2rem',
                    }}
                  >
                    {' '}
                    {fields.length > 1 ? (
                      <Button
                        type="text"
                        onClick={() => {
                          remove(field.name);
                          const removed = filled.splice(index, 1);
                          setFilled(removed);
                        }}
                        icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="icon"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Tambah Kelas
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
};

export default TambahKelas;

import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, DatePicker, message, Modal, Row, Select } from 'antd';
import { useStore } from 'store';
import { parsingVABCA, toText } from 'utils/helpers/parse-va';
import moment from 'moment';
import { downloadVADashboard } from 'utils/fetch';
import exportExcel from 'utils/helpers/xlsx';
import { setLocalStorage } from 'utils/helpers/storage';

const FilterUnits = ({
  visible,
  onClose,
  refetchData,
  unitId,
  onSelectedFilter,
  units,
  userUnits,
  ...rest
}) => {
  const {
    state: { unitData, userData },
  } = useStore();
  const [unit, setUnit] = useState([]);
  const [loading, setLoading] = useState(false);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  const disabled = unit.length ? false : true;

  useEffect(() => {
    if (units?.length) setUnit(units);
  }, [units]);

  const unitsCheckbox = useMemo(() => {
    return arrayUnit.length
      ? arrayUnit.map((item) => ({ label: item.name, value: item._id }))
      : [];
  }, [arrayUnit]);

  return (
    <Modal
      title="Filter Unit"
      visible={visible}
      onCancel={onClose}
      centered
      okText="Filter"
      cancelText="Batal"
      width="40vw"
      okButtonProps={{
        loading: loading,
        disabled: disabled,
      }}
      cancelButtonProps={{ loading: loading }}
      onOk={() => {
        onSelectedFilter({ units: unit });
        onClose();
      }}
      {...rest}
    >
      <Row gutter={[24, 12]}>
        <Col span="24">
          <label
            htmlFor="unit-selection"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Checkbox.Group
              //   options={unitsCheckbox}
              defaultValue={unit}
              onChange={(v) => {
                setUnit(v);
              }}
              value={unit}
            >
              <Row gutter={[8, 12]}>
                {unitsCheckbox?.map((unit) => {
                  return (
                    <Col span={8}>
                      <Checkbox
                        disabled={!userUnits?.includes(unit.value)}
                        value={unit.value}
                      >
                        {unit.label}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </label>
        </Col>
      </Row>
    </Modal>
  );
};

export default FilterUnits;

import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Alert,
  Modal,
  message,
  Table,
  Menu,
  Dropdown,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard, StudentCard } from 'components/fragments/card';
import { CustomButton, Loader } from 'components/atoms';
import IC_EDIT from 'assets/icons/Edit.svg';
import {
  CalendarOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  FileOutlined,
  MenuOutlined,
  MoneyCollectOutlined,
  StarFilled,
} from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import { BASE_URL, getDetailPembayaran, hapusPembayaran } from 'utils/fetch';
import { statusMurid, tipeMurid } from 'utils/helpers/common';
import moment from 'moment';
import { EditPaymentDate } from '../modal';
import { themeColor } from 'lib/theme';

const DetailPembayaranSPP = (props) => {
  const { visible, onCancel, value, refetchData, ...rest } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [detail, setDetail] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { unitId = '', paymentId = '' } = value;

  useEffect(() => {
    if (visible && paymentId) {
      fetchData();
    } else {
      setDetail(null);
    }
  }, [visible, paymentId]);

  const fetchData = () => {
    getDetailPembayaran(unitId, 'spp', paymentId)
      .then((res) => {
        console.log(res, 'detail pembayaran spp');
        setDetail(res);
      })
      .catch((err) => console.log(err.response, 'error fetch detail'));
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin menghapus pembayaran ${detail?.payment.name} oleh ${detail?.payment.student.name}?`,
      content: (
        <div>
          <p>
            Jika Anda menghapus pembayaran akan menambah tunggakan murid, dan
            dana alokasi akan dikembalikan ke dana masuk.
          </p>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      cancelButtonProps: {
        type: 'secondary',
      },
      onOk() {
        hapusPembayaran(unitId, paymentId, 'spp', 'dana_masuk=false')
          .then((res) => {
            console.log(res);
            onCancel();
            refetchData();
            message.success('Berhasil menghapus pembayaran');
          })
          .catch((err) => {
            console.log(err.response);
            message.err(err?.response?.data?.m || 'Gagal menghapus pembayaran');
          });
      },
      centered: true,
    });
  };

  const handleBackToFund = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin mengembalikan pembayaran ${detail?.payment.name} oleh ${detail?.payment.student.name} ke Dana Masuk?`,
      content: (
        <div>
          <p>
            Jika anda setuju maka akan menambah tunggakan murid, dan dana akan
            dikembalikan ke dana masuk.
          </p>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: 'Ya',
      cancelText: 'Batal',
      cancelButtonProps: {
        type: 'secondary',
      },
      onOk() {
        hapusPembayaran(unitId, paymentId, 'spp', 'dana_masuk=true')
          .then((res) => {
            console.log(res);
            onCancel();
            refetchData();
            message.success('Berhasil mengembalikan pembayaran ke Dana Masuk');
          })
          .catch((err) => {
            console.log(err.response);
            message.err(
              err?.response?.data?.m ||
                'Gagal mengembalikan pembayaran ke Dana Masuk'
            );
          });
      },
      centered: true,
    });
  };

  const tableColumnsItemTagihan = [
    {
      title: 'Item',
      dataIndex: 'master_bill_item',
      key: 'ppdb_item',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: 'Sudah Bayar',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data) => <span>{`Rp${data?.toLocaleString()}` || 0}</span>,
    },
    {
      title: 'Refund',
      dataIndex: 'refund',
      key: 'refund',
      render: (data) => <span>{`Rp${data?.toLocaleString()}` || 0}</span>,
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      align: 'right',
      render: (data) => <span>{`Rp${data?.toLocaleString()}` || 0}</span>,
    },
  ];

  console.log({ detail });

  const MenuHapus = (
    <Menu>
      {detail?.payment?.payment_origin !== ('Direct VA' || 'Manual VA') ? (
        <Menu.Item danger onClick={handleDelete}>
          Hapus Pembayaran
        </Menu.Item>
      ) : null}
      <Menu.Item danger onClick={handleBackToFund}>
        Kembalikan Ke Dana Masuk
      </Menu.Item>
    </Menu>
  );

  const Detail = () => (
    <>
      <section style={{ marginBottom: '2rem' }}>
        <header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '0.75rem',
          }}
        >
          <Space>
            <h2 style={{ margin: 0 }}>{detail?.payment?.name}</h2>
          </Space>
          <Space>
            <Button
              icon={<FileDoneOutlined />}
              onClick={() => {
                window.open(
                  BASE_URL(
                    `bill/${unitId}/spp/${detail?.spp._id}/download-receipt`
                  ),
                  '_blank'
                );
              }}
            >
              Receipt
            </Button>
            {detail?.payment?.payment_origin !==
            ('Direct VA' || 'Manual VA') ? (
              <Button
                icon={<CalendarOutlined />}
                onClick={() => setOpenModal(true)}
              >
                Edit Tanggal
              </Button>
            ) : null}

            {detail?.payment?.payment_origin === 'Tabungan' ? (
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={handleDelete}
                // disabled={detail?.payment.payment_origin === 'Manual VA'}
              >
                Hapus Pembayaran
              </Button>
            ) : (
              <Dropdown overlay={MenuHapus}>
                <Button
                  icon={<MenuOutlined />}
                  // onClick={handleBackToFund}
                  // disabled={detail?.payment.payment_origin === 'Manual VA'}
                >
                  Menu Lainnya
                </Button>
              </Dropdown>
            )}
          </Space>
        </header>
        <Space>
          <span>
            <CalendarOutlined style={{ color: '#1890FF' }} />{' '}
            {moment(detail?.payment?.date).format('DD/MM/YYYY')}
          </span>
        </Space>
      </section>
      <StudentCard student={detail?.payment?.student} />

      <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            title="Sudah Bayar"
            content={`Rp${(detail?.spp?.paid_amount || 0).toLocaleString()}`}
            sideColor="green"
          />
        </Col>
        {detail?.spp?.refund > 0 && (
          <Col span="12">
            <InfoCard
              title="Refund"
              content={`Rp${(detail?.spp?.refund || 0).toLocaleString()}`}
              sideColor="red"
            />
          </Col>
        )}
      </Row>

      <section style={{ marginTop: '2rem' }}>
        <h3>Item Terbayar</h3>
        <Table
          columns={tableColumnsItemTagihan}
          dataSource={detail?.sppBillItems || []}
          pagination={false}
        />
      </section>
    </>
  );

  const Edit = () => (
    <>
      <YellowCard>
        <span>Nama Murid</span>
        <h2 style={{ color: themeColor.primary, margin: 0 }}>
          {detail?.payment?.student?.name}
        </h2>
      </YellowCard>

      <section style={{ margin: '2rem 0' }}>
        <InfoCard
          sideColor="green"
          title="Dana Tersedia"
          content={`Rp${(900000).toLocaleString()}`}
        />
      </section>

      <h2>{detail?.payment?.name}</h2>

      <Form layout="vertical">
        <InputWrapper
          type="text"
          prefix="Rp"
          name="sudah"
          defaultValue={detail?.spp?.paid_amount}
          label="Sudah Bayar"
          disabled
        />
        <InputWrapper
          type="text"
          prefix="Rp"
          name="nominal"
          label="Ubah Nominal"
        />
      </Form>

      <Alert
        type="info"
        showIcon
        message="Perhatian!"
        description="Perubahan nominal pembayaran akan mempengaruhi jumlah sisa dana masuk/tabungan."
      />
    </>
  );

  return (
    <Drawer
      title={`${isEdit ? 'Edit Pembayaran' : 'Detail Pembayaran Uang Sekolah'}`}
      width={isEdit ? '35vw' : '60vw'}
      visible={visible}
      footer={
        isEdit && (
          <Footer onCancel={() => setIsEdit(false)} onSubmit={() => {}} />
        )
      }
      onClose={onCancel}
      {...rest}
    >
      {isEdit ? (
        <Edit />
      ) : detail ? (
        <Detail />
      ) : (
        <Loader text="Fetching details ..." />
      )}

      {openModal ? (
        <EditPaymentDate
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          unitId={unitId}
          paymentId={paymentId}
          refetch={() => {
            refetchData();
            fetchData();
          }}
          defaultValue={{
            date: moment(detail?.payment?.date),
            ...detail.payment,
          }}
        />
      ) : null}
    </Drawer>
  );
};

export default DetailPembayaranSPP;

import styled from 'styled-components';

export const StyledDetailKelasData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.8rem 0 0;
  & > p {
    margin-bottom:0;
  }
`;

import axios from 'axios';
import { getUserToken, setUserToken } from './helpers/storage';

export const BASE_URL = (target) =>
  `${process.env.REACT_APP_API_URL_KETAPANG}/${target}`;
// // https://3ab7456fb1a4.ngrok.io

// export const BASE_URL = (target) => `http://localhost:3001/api/v1/${target}`;

const headerConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'origin',
    authorization: `Bearer ${getUserToken()}`,
  },
};

export const getActiveSchoolYear = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL('master-data/active-school-year'), headerConfig)
      .then((res) => {
        resolve(res.data.value.activeSchoolYear);
      })
      .catch((err) => reject(err.response));
  });
};

export const loginUser = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL('master-data/sign-in'), data, headerConfig)
      .then((res) => {
        const token = res.data.value.userToken.split(' ')[1];
        setUserToken(token);
        if (getUserToken()) {
          resolve(res.data.value.user);
        }
      })
      .catch((err) => reject(err));
  });
};

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL('master-data/forgot-password'), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const fetchTahunAjaran = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL('master-data/school-year'), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const createTahunAjaran = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL('master-data/school-year'), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const activateTahunAjaran = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(`master-data/school-year/${id}/activate`),
        null,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const fetchDetailTahunAjaran = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`master-data/school-year/${id}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const fetchUserList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL('master-data/user'), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const createNewUser = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL('master-data/user'), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const editUserData = (data, id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`master-data/user/${id}`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const fetchUnitList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL('master-data/unit'), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getUserData = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL('master-data/decode-token'), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const editProfile = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`master-data/profile`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getMasterBillItem = (unitId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`unit/master-bill-item/${unitId}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const createItemTagihan = (data, unitId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL(`unit/master-bill-item/${unitId}`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const editItemTagihan = (data, unitId, itemId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(`unit/master-bill-item/${unitId}/${itemId}`),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const editDashboardStatus = (data, unitId, itemId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(`unit/master-bill-item/${unitId}/${itemId}/dashboard-status`),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getSPPdanPPDBUnit = (unitId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`unit/spp-ppdb/${unitId}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const editPpdbUnit = (data, unitId, ppdbId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`unit/ppdb/${unitId}/${ppdbId}`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const lockPpdbUnit = (data, unitId, ppdbId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`unit/ppdb/${unitId}/${ppdbId}/lock`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const editPpdbAfterLock = (data, unitId, ppdbId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(`unit/ppdb/${unitId}/${ppdbId}/lock-update`),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const editSppUnit = (data, unitId, sppId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`unit/spp/${unitId}/${sppId}`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const lockSppUnit = (data, unitId, sppId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`unit/spp/${unitId}/${sppId}/lock`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const editSppAfterLock = (data, unitId, sppId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(`unit/spp/${unitId}/${sppId}/lock-update`),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getListSistemPembayaran = (unitId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`unit/payment-schedule/${unitId}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const editVACode = (data, unitId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(`unit/payment-schedule/${unitId}/va-code`),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const editSistemPembayaran = (data, unitId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`unit/payment-schedule/${unitId}`), data, headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getListKelasSebelumnya = (unitId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`master-data/previous-grades/${unitId}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getListKelas = (unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(`grade-student/${unitId}/${schoolYearId}/grade`),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getListMurid = (unitId, schoolYearId, query) => {
  console.log(query, 'fetch query');
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(`grade-student/${unitId}/${schoolYearId}/student?${query}`),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getDetailMurid = (unitId, schoolYearId, studentId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(
          `grade-student/${unitId}/${schoolYearId}/student/${studentId}`
        ),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getListTahunAjaran = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`master-data/school-years`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getListKelasId = (unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(`master-data/grade/${unitId}/${schoolYearId}`),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const submitCreateStudent = (data, unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        BASE_URL(`grade-student/${unitId}/${schoolYearId}/student`),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getMuridByGrade = (unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(
          `master-data/student-group-by-grade/${unitId}/${schoolYearId}`
        ),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const submitStudentGraduate = (data, unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        BASE_URL(`grade-student/${unitId}/${schoolYearId}/student/graduate`),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getStudentNoClass = (unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(
          `grade-student/${unitId}/${schoolYearId}/grade/no-grade-student`
        ),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getStudentHasClass = (unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(
          `grade-student/${unitId}/${schoolYearId}/grade/has-grade-student`
        ),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const tambahKelas = (unitId, schoolYearId, data) => {
  return axios
    .post(
      BASE_URL(`grade-student/${unitId}/${schoolYearId}/grade`),
      data,
      headerConfig
    )
    .then((res) => {
      Promise.resolve(res.data);
    })
    .catch((err) => {
      Promise.reject(err.response);
    });
};

export const hapusKelas = (unitId, schoolYearId, gradeId) => {
  return axios
    .delete(
      BASE_URL(`grade-student/${unitId}/${schoolYearId}/grade/${gradeId}`),
      headerConfig
    )
    .then((res) => {
      Promise.resolve(res.data);
    })
    .catch((err) => {
      Promise.reject(err.response);
    });
};

export const editDetailKelas = (unitId, schoolYearId, gradeId, data) => {
  return axios
    .put(
      BASE_URL(`grade-student/${unitId}/${schoolYearId}/grade/${gradeId}`),
      data,
      headerConfig
    )
    .then((res) => {
      Promise.resolve(res.data);
    })
    .catch((err) => {
      Promise.reject(err.response);
    });
};

export const submitAssignMurid = (data, unitId, schoolYearId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        BASE_URL(
          `grade-student/${unitId}/${schoolYearId}/grade/assign-student`
        ),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const editDetailMurid = (
  data,
  unitId,
  schoolYearId,
  studentId,
  type
) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(
          `grade-student/${unitId}/${schoolYearId}/student/${studentId}/${type}`
        ),
        data,
        headerConfig
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getStudentHistory = (unitId, schoolYearId, studentId) => {
  return axios
    .get(
      BASE_URL(
        `grade-student/${unitId}/${schoolYearId}/student/${studentId}/grade-history`
      ),
      headerConfig
    )
    .then((res) => {
      return Promise.resolve(res.data.value);
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
};

export const getBill = (unitId, type, query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`bill/${unitId}/${type}?${query}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getPayment = (unitId, type, query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BASE_URL(`payment/${unitId}/${type}${query ? `?${query}` : ''}`),
        headerConfig
      )
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const getDetailTagihan = (unitId, id, type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`bill/${unitId}/${type}/${id}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const editNominal = (unitId, type, id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL(`bill/${unitId}/${type}/${id}`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const tambahPpdb = (unitId, data) => {
  return axios
    .post(BASE_URL(`bill/${unitId}/ppdb`), data, headerConfig)
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

export const ppdbDetailConfig = (unitId, schoolYearId) => {
  return axios
    .get(
      BASE_URL(`bill/${unitId}/ppdb/detail-configuration/${schoolYearId}`),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const deletePPDB = (unitId, ppdbId) => {
  return axios
    .delete(BASE_URL(`bill/${unitId}/ppdb/${ppdbId}`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const deleteGeneralBill = (unitId, billId) => {
  return axios
    .delete(BASE_URL(`bill/${unitId}/general-bill/${billId}`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const cancelPPDB = (unitId, ppdbId, data) => {
  return axios
    .post(BASE_URL(`bill/${unitId}/ppdb/cancel/${ppdbId}`), data, headerConfig)
    .then((res) =>
      Promise.resolve(res.data).catch((err) => Promise.reject(err))
    );
};

export const nonAktifkanSPP = (unitId, sppId) => {
  return axios
    .delete(BASE_URL(`bill/${unitId}/spp/${sppId}`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const aktifkanSPP = (unitId, sppId) => {
  return axios
    .get(BASE_URL(`bill/${unitId}/spp/${sppId}/activate`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const tambahDanaMasuk = (unitId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL(`payment/${unitId}/fund`), data, headerConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getDetailDanaMasuk = (unitId, id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`payment/${unitId}/fund/${id}/incoming`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const tambahTagihanLainnya = (unitId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL(`bill/${unitId}/general-bill`), data, headerConfig)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const editIncomingFunds = (unitId, studentId, incomingFundId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BASE_URL(
          `payment/${unitId}/fund/${studentId}/incoming/${incomingFundId}`
        ),
        data,
        headerConfig
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getOutstandingBills = (studentId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`master-data/outstanding-bills/${studentId}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const tambahPembayaran = (unitId, studentId, data) => {
  return axios
    .post(
      BASE_URL(`payment/${unitId}/payment/${studentId}`),
      data,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getDetailPembayaran = (unitId, type, id) => {
  return axios
    .get(BASE_URL(`payment/${unitId}/payment/${type}/${id}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const allocationHistory = (unitId, type, id) => {
  return axios
    .get(
      BASE_URL(`payment/${unitId}/${type}/${id}/allocation-history`),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const allocate = (unitId, type, id, data) => {
  return axios
    .post(
      BASE_URL(`payment/${unitId}/${type}/${id}/allocate`),
      data,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const refundPembayaran = (unitId, paymentId, data) => {
  return axios
    .post(
      BASE_URL(`payment/${unitId}/refund/payment/${paymentId}`),
      data,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const refundTabungan = (unitId, data) => {
  return axios
    .post(BASE_URL(`payment/${unitId}/refund/savings`), data, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const refundDanaMasuk = (unitId, data) => {
  return axios
    .post(BASE_URL(`payment/${unitId}/refund/fund`), data, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const hapusPembayaran = (unitId, paymentId, type, query) => {
  return axios
    .delete(
      BASE_URL(
        `payment/${unitId}/payment/${type}/${paymentId}${
          query ? `?${query}` : ''
        }`
      ),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const getSavingDetails = (unitId, studentId, state) => {
  return axios
    .get(
      BASE_URL(`payment/${unitId}/savings/${studentId}/${state}`),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const alokasiTabungan = (unitId, studentId, data) => {
  return axios
    .post(
      BASE_URL(`payment/${unitId}/savings/${studentId}/allocate`),
      data,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const getMainDashboardData = (state, query) => {
  return axios
    .get(BASE_URL(`dashboard/main-dashboard/${state}?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getAlumniMenunggak = (query) => {
  return axios
    .get(BASE_URL(`dashboard/alumni-dashboard?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getAlumniMenunggakDownload = (query) => {
  return axios
    .get(BASE_URL(`dashboard/alumni-dashboard/download?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getSPPReport = (query, state) => {
  const endpoint = state
    ? `dashboard/spp-bill-dashboard/${state}`
    : 'dashboard/spp-bill-dashboard';

  return axios
    .get(BASE_URL(`${endpoint}?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getPPDBReport = (query, state) => {
  const endpoint = state
    ? `dashboard/ppdb-bill-dashboard/${state}`
    : 'dashboard/ppdb-bill-dashboard';

  return axios
    .get(BASE_URL(`${endpoint}?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getGeneralReport = (query, state) => {
  const endpoint = state
    ? `dashboard/general-bill-dashboard/${state}`
    : 'dashboard/general-bill-dashboard';

  return axios
    .get(BASE_URL(`${endpoint}?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getDailyReport = (query) => {
  return axios
    .get(BASE_URL(`dashboard/daily-report?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const detailRincianDashboard = (type, query) => {
  return axios
    .get(BASE_URL(`dashboard/main-dashboard/${type}?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const rincianMonthlyPayment = (type, query) => {
  return axios
    .get(BASE_URL(`dashboard/${type}/monthly-payment?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const studentTabSPP = (studentId, schoolYearId) => {
  return axios
    .get(
      BASE_URL(`student-tab/${studentId}/${schoolYearId}/spp-list`),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const studentTab = (studentId, type, schoolYearId) => {
  const url = schoolYearId
    ? `student-tab/${studentId}/${schoolYearId}/${type}`
    : `student-tab/${studentId}/${type}`;

  return axios
    .get(BASE_URL(url), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const uploadVA = (unitId, data, config) => {
  return axios
    .post(BASE_URL(`payment/${unitId}/fund/bulk-csv`), data, {
      ...headerConfig,
      headers: { ...headerConfig.headers, ...config },
    })
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getDashboardDataAllUnit = () => {
  return axios
    .get(BASE_URL(`dashboard/general-dashboard/all-units`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getDashboardDataUnit = (unitId) => {
  return axios
    .get(BASE_URL(`dashboard/general-dashboard/unit/${unitId}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const updateDashboardData = () => {
  return axios
    .get(BASE_URL(`dashboard/general-dashboard/update`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const getLaporanHarianAllUnit = (query) => {
  return axios
    .get(BASE_URL(`dashboard/daily-report?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getLaporanHarianUnit = (unitId, query) => {
  return axios
    .get(BASE_URL(`dashboard/daily-report/${unitId}?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getLaporanBulananAllUnit = (query) => {
  return axios
    .get(BASE_URL(`dashboard/monthly-report?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getLaporanBulananUnit = (unitId, query) => {
  return axios
    .get(BASE_URL(`dashboard/monthly-report/${unitId}?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const updateLaporanHarian = () => {
  return axios
    .get(BASE_URL(`dashboard/daily-report/update`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const updateLaporanBulanan = () => {
  return axios
    .get(BASE_URL(`dashboard/monthly-report/update`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const getRekapAllUnit = (type, query) => {
  return axios
    .get(BASE_URL(`dashboard/${type}${query ? `?${query}` : ''}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getRekapUnit = (type, unitId, query) => {
  return axios
    .get(
      BASE_URL(`dashboard/${type}/${unitId}${query ? `?${query}` : ''}`),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const updateRekap = (type) => {
  return axios
    .get(BASE_URL(`dashboard/${type}/update`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const hapusIncomingFund = (unitId, studentId, fundId) => {
  return axios
    .delete(
      BASE_URL(`payment/${unitId}/fund/${studentId}/incoming/${fundId}`),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const editTabungan = (unitId, paymentId, data) => {
  return axios
    .put(
      BASE_URL(`payment/${unitId}/payment/savings-allocation/${paymentId}`),
      data,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const editTanggalReminder = (unitId, data) => {
  return axios
    .put(
      BASE_URL(`unit/payment-schedule/${unitId}/debt-reminder`),
      data,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
};

export const downloadVADashboard = (unitId, query) => {
  return axios
    .get(
      BASE_URL(
        `dashboard/general-dashboard/download-va-report/${unitId}${query}`
      ),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getTabAllBills = (studentId) => {
  return axios
    .get(BASE_URL(`student-tab/${studentId}/all-bill`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const generateExcelTagihan = (unitId, type, query) => {
  return axios
    .get(
      BASE_URL(
        `bill/${unitId}/${type}/download-excel${query ? `?${query}` : ''}`
      ),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getSppBillItems = (unitId, schoolYearId) => {
  return axios
    .get(BASE_URL(`master-data/spp-bill-item/${unitId}/${schoolYearId}`))
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const downloadDataPembayaran = (unitId, query) => {
  return axios
    .get(
      BASE_URL(`payment/${unitId}/payment/download-excel?${query}`),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err.response));
};

export const downloadLaporanDashboard = (type, query) => {
  return axios
    .get(BASE_URL(`dashboard/${type}/download-data?${query}`), headerConfig)
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err.response));
};

export const getNoEmailGuardians = (unitId, schoolYearId) => {
  return axios
    .get(
      BASE_URL(
        `grade-student/${unitId}/${schoolYearId}/student/no-guardian-email-count`
      ),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err.response));
};

export const updateEmailGuardian = (unitId, schoolYearId, payload) => {
  return axios
    .put(
      BASE_URL(
        `grade-student/${unitId}/${schoolYearId}/student/update-guardians`
      ),
      payload,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const blastReminder = (unitId, schoolYearId, payload) => {
  return axios
    .post(
      BASE_URL(`grade-student/${unitId}/${schoolYearId}/student/send-reminder`),
      payload,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const downloadDanaMasuk = (unitId, query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL(`payment/${unitId}/fund/download?${query}`), headerConfig)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => reject(err));
  });
};

export const updatePaymentDate = (unitId, paymentId, payload) => {
  return axios
    .put(
      BASE_URL(`payment/${unitId}/payment/edit-date/${paymentId}`),
      payload,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const studentDownloadData = (unitId, schoolYearId, type, payload) => {
  return axios
    .post(
      BASE_URL(`grade-student/${unitId}/${schoolYearId}/student/${type}`),
      payload,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const deleteRefundData = (unitId, refundId) => {
  return axios
    .delete(BASE_URL(`payment/${unitId}/refund/${refundId}`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const createApresiasi = (unitId, payload) => {
  return axios
    .post(BASE_URL(`payment/${unitId}/appreciation`), payload, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const deleteApresiasi = (unitId, id) => {
  return axios
    .delete(BASE_URL(`payment/${unitId}/appreciation/${id}`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getListApresiasi = (unitId) => {
  return axios
    .get(BASE_URL(`payment/${unitId}/appreciation`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const updateApresiasi = (unitId, id, payload) => {
  return axios
    .put(
      BASE_URL(`payment/${unitId}/appreciation/${id}`),
      payload,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getUnPaidSPP = (unitId, studentId) => {
  return axios
    .get(BASE_URL(`bill/${unitId}/spp/un-paid-spp/${studentId}`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getUnbillMonth = (unitId, studentId, schoolYearId) => {
  return axios
    .get(
      BASE_URL(
        `bill/${unitId}/spp/get-unbill-month/${studentId}/${schoolYearId}`
      ),
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const deactivateSPPBill = (unitId, billId) => {
  return axios
    .delete(BASE_URL(`bill/${unitId}/spp/delete/${billId}`), headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const addSppBill = (unitId, studentId, payload) => {
  return axios
    .post(BASE_URL(`bill/${unitId}/spp/${studentId}`), payload, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getDashboardAllTotalTunggakan = (filter) => {
  return axios
    .post(
      BASE_URL(`dashboard/keseluruhan/total-tunggakan`),
      filter,
      headerConfig
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getDashboardAllDanaMasuk = (filter) => {
  return axios
    .post(BASE_URL(`dashboard/keseluruhan/dana-masuk`), filter, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getDashboardAllTabungan = (filter) => {
  return axios
    .post(BASE_URL(`dashboard/keseluruhan/tabungan`), filter, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getLaporanHarian = (filter) => {
  return axios
    .post(BASE_URL(`dashboard/laporan-harian`), filter, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getLaporanKategori = (filter) => {
  return axios
    .post(BASE_URL(`dashboard/laporan-kategori`), filter, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getDownloadLaporanKategori = (filter) => {
  return axios
    .post(BASE_URL(`dashboard/laporan-kategori/download`), filter, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getLaporanBulananSPP = (filter) => {
  return axios
    .post(BASE_URL(`dashboard/laporan-spp`), filter, headerConfig)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
};

export const getHistoryWhatsapp = (query) => {
  return axios
    .get(BASE_URL(`dashboard/whatsapp`), {
      ...headerConfig,
      params: query,
    })
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const getDetailWhatsappMessage = (id) => {
  return axios
    .get(BASE_URL(`dashboard/whatsapp/${id}`), {
      ...headerConfig,
    })
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const resentWhatsappMessage = (id, payload) => {
  return axios
    .post(BASE_URL(`dashboard/whatsapp/${id}/resent`), payload, {
      ...headerConfig,
    })
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

export const deleteWhatsappMessage = (id) => {
  return axios
    .delete(BASE_URL(`dashboard/whatsapp/${id}`), {
      ...headerConfig,
    })
    .then((res) => Promise.resolve(res.data.value))
    .catch((err) => Promise.reject(err));
};

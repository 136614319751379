import React, { useEffect, useState } from 'react';
import { StyledMenu, StyledSider, StyledMenuItem } from './style';
import { MENU } from 'lib/menu';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from 'store';
import Sider from 'antd/lib/layout/Sider';
import { Menu, Space } from 'antd';
import queryString from 'querystring';
import IC_TK from 'assets/icons/TK.svg';
import IC_SD from 'assets/icons/SD.svg';
import IC_SMP from 'assets/icons/SMP.svg';
import IC_SMA from 'assets/icons/SMA.svg';
import IC_PENGATURAN from 'assets/icons/Pengaturan.svg';

const CustomSider = ({ collapsed, setCollapsed }) => {
  const {
    state: { siderActive },
  } = useStore();
  const location = useLocation();
  const [selected, setSelected] = useState(['1']);
  const [currentMenu, setCurrentMenu] = useState('pengaturan');
  const { unit = '' } = queryString.parse(location.search.replace('?', ''));
  useEffect(() => {
    setSelected(siderActive);
  }, [siderActive]);

  const isDashboard = location.pathname.includes('dashboard');

  useEffect(() => {
    if (location.pathname.includes('settings')) setCurrentMenu('pengaturan');
    else if (location.pathname.includes('dashboard'))
      setCurrentMenu('dashboard');
    else setCurrentMenu('main');
  }, [location]);

  const icons = {
    tk: IC_TK,
    sd: IC_SD,
    smp: IC_SMP,
    sma: IC_SMA,
    tkc: IC_TK,
    sdc: IC_SD,
    smpc: IC_SMP,
    smac: IC_SMA,
    pengaturan: IC_PENGATURAN,
  };

  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      width="25rem"
    >
      <StyledMenu
        defaultSelectedKeys={['1']}
        selectedKeys={selected}
        mode="inline"
      >
        {currentMenu !== 'dashboard' ? (
          <div
            style={{
              marginBottom: '2.25rem',
              paddingLeft: !collapsed ? '1rem' : '',
              display: 'flex',
              justifyContent: collapsed ? 'center' : '',
              fontWeight: 'bold',
            }}
          >
            <Space size="middle">
              <img src={icons[unit || 'pengaturan']} />
              {!collapsed ? (
                <span>{unit?.toUpperCase() || 'Pengaturan'}</span>
              ) : null}
            </Space>
          </div>
        ) : null}
        {MENU[currentMenu].map((menu) => {
          if (menu.childrens) {
            return (
              <Menu.SubMenu
                title={menu.name}
                key={menu.key}
                icon={menu?.icon ? <menu.icon /> : null}
              >
                {menu.childrens.map((child) => (
                  <StyledMenuItem key={child.key}>
                    <Link
                      to={{
                        pathname: child.to,
                        search:
                          !isDashboard &&
                          queryString.stringify({
                            unit,
                          }),
                      }}
                    >
                      {child.name}
                    </Link>
                  </StyledMenuItem>
                ))}
              </Menu.SubMenu>
            );
          } else {
            return (
              <StyledMenuItem
                key={menu.key}
                icon={menu?.icon ? <menu.icon /> : null}
              >
                <Link
                  to={{
                    pathname: menu.to,
                    search:
                      !isDashboard &&
                      queryString.stringify({
                        unit,
                      }),
                  }}
                >
                  {menu.name}
                </Link>
              </StyledMenuItem>
            );
          }
        })}
      </StyledMenu>
    </StyledSider>
  );
};

export default CustomSider;

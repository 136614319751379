import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Alert,
  Form,
  DatePicker,
  Space,
  message,
  Select,
} from 'antd';
import moment from 'moment';
import { updatePaymentDate } from 'utils/fetch';

const EditPaymentDate = ({
  visible,
  onCancel,
  refetch = () => {},
  defaultValue,
  unitId,
  paymentId,
}) => {
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (visible && defaultValue.date) {
      form.setFieldsValue({
        date: moment(defaultValue.date),
        payment_origin: defaultValue.payment_origin,
      });

      console.log({ defaultValue });
    }
  }, [defaultValue, visible]);

  const handleSubmit = async (payload) => {
    try {
      setLoadingSubmit(true);

      const response = await updatePaymentDate(unitId, paymentId, payload);
      console.log(response);
      if (response) {
        refetch();
        onCancel();
        message.success('Berhasil memperbaharui tanggal');
        setLoadingSubmit(false);
      }
    } catch (err) {
      setLoadingSubmit(false);
      console.log(err);
      message.error(err?.data?.m || 'Gagal memperbaharui tanggal');
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onCancel={onCancel}
      title="Edit Tanggal"
      style={{ width: 'fit-content' }}
      width={300}
      onOk={form.submit}
      okText="Edit"
      cancelText="Batal"
      okButtonProps={{ loading: loadingSubmit }}
      cancelButtonProps={{ disabled: loadingSubmit }}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={(v) => {
          handleSubmit({
            date: moment(v.date).format('MM/DD/YYYY'),
            payment_origin: v.payment_origin,
          });
        }}
      >
        <Form.Item label="Tanggal" style={{ width: '100%' }} name="date">
          {/* <Space style={{ width: '100%' }}> */}
          <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
          {/* </Space> */}
        </Form.Item>
        <Form.Item label="Tipe Pembayaran" name="payment_origin">
          <Select placeholder="Pilih tipe pembayaran" style={{ width: '100%' }}>
            {[
              'Manual VA',
              'Transfer',
              'Debit/Credit Card',
              'QRIS',
              'Beasiswa',
              'Lainnya',
              'Greja',
              'Mesin Edisi',
            ].map((val) => (
              <Select.Option value={val}>{val}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditPaymentDate;

import { Button, Form, Input, message } from 'antd';
import React from 'react';
import {
  PageWrapper,
  ContentWrapper,
  TitleContainer,
  FormContainer,
  FooterContainer,
} from './style';
import IMAGES from 'assets/images/lupa-password.png';
import { useHistory } from 'react-router-dom';
import { ForgotPasswordForm } from 'components/form';
import { forgotPassword } from 'utils/fetch';

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const handleSubmit = (values) => {
    console.log(values, 'form values');
    return forgotPassword({ email: values.email_forgot })
      .then((res) => {
        console.log(res);
        message.success(res.title);
        history.push('/signin');
      })
      .catch((err) => console.error(err));
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleContainer>
          <img src={IMAGES} alt="lupa-password" />
          <h1>Lupa Password</h1>
        </TitleContainer>
        <FormContainer>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <ForgotPasswordForm />
          </Form>
        </FormContainer>
        <Button type="primary" onClick={form.submit}>
          Kirim Password Lama
        </Button>
        <FooterContainer>
          <Button type="link" onClick={() => history.push('/signin')}>
            Kembali ke laman Sign in
          </Button>
        </FooterContainer>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';
import { Drawer, Button, Form, DatePicker, Alert } from 'antd';
import moment from 'moment';
import { Footer } from './shared';
import { createTahunAjaran } from 'utils/fetch';

const TambahTahunAjaran = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, value, isEdit, handleSubmit, ...rest } = props;
  const [yearSelected, setYearSelected] = useState('');

  useEffect(() => {
    if (isEdit && value) {
      setYearSelected(value?.text);
      form.setFieldsValue({ year: moment(value?.text) });
    }
  }, [isEdit, value]);

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : 'Tambah'} Tahun Ajaran`}
      width="40rem"
      visible={visible}
      footer={<Footer onCancel={onCancel} onSubmit={form.submit} />}
      onClose={onCancel}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => handleSubmit({ year: yearSelected })}
      >
        <Form.Item
          label="Pilih Tahun Mulai Ajaran"
          name="year"
          style={{ width: '100%' }}
        >
          <DatePicker
            picker="year"
            placeholder="Pilih tahun"
            style={{ width: '100%' }}
            onChange={(date, dateValue) => {
              // form.setFieldsValue(moment().year(dateValue));
              setYearSelected(dateValue);
            }}
          />
        </Form.Item>
      </Form>
      {yearSelected && (
        <Alert
          showIcon
          type="info"
          message={`Tahun yang terbuat Juli ${yearSelected} - Juni ${
            Number(yearSelected) + 1
          }`}
        />
      )}
    </Drawer>
  );
};

export default TambahTahunAjaran;

import {
  DownOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Table, Space, Input, Dropdown, Button, Menu, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStore } from 'store';
import { detailRincianDashboard } from 'utils/fetch';
import queryString from 'querystring';
import moment from 'moment';
import { unitSlug } from 'utils/helpers/common';

const DashboardTunggakan = ({ data, unitName }) => {
  const {
    state: { unitData },
  } = useStore();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  const [copiedData, setCopiedData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const arrayUnit = Object.keys(unitData).map((key) => unitData[key]);

  // useEffect(() => {
  //   fetchData();
  // }, [selectedUnit]);

  useEffect(() => {
    if (data) {
      console.log({ data });
      setTotalData(data.length);
    }
  }, [data]);

  useEffect(() => {
    if (nameSearch) {
      const filtered = data.filter((item) =>
        item.name.toLowerCase().trim().includes(nameSearch.toLowerCase().trim())
      );

      setCopiedData(filtered);
    } else {
      setCopiedData(data);
    }
  }, [nameSearch]);

  useEffect(() => {
    return () => setCurrentPage(1);
  }, []);

  // const fetchData = () => {
  //   let query = `unitId=${selectedUnit?._id || ''}`;

  //   setLoading(true);

  //   detailRincianDashboard('student-debt-list', query)
  //     .then((res) => {
  //       console.log(res);
  //       // setData(res.studentDebtList);
  //       setTotalData(res.totalData);
  //     })
  //     .catch((err) => console.log(err.response, 'error fetch'))
  //     .finally(() => setLoading(false));
  // };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nominal Tunggakan',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (data, record) => {
        const debtsDetail = [
          record?.generalBills?.map((bill) => ({
            name: bill.name,
            amount: bill.total_amount,
          })),
          record?.ppbInstalments?.map((bill) => ({
            name: 'Cicilan Ke-' + bill.instalment_repition,
            amount: bill.total_amount,
          })),
          record?.sppBills?.map((bill) => ({
            name: `SPP Bulan ${moment(bill?.month).format('MMMM YYYY')}`,
            amount: bill.total_amount,
          })),
        ]
          .flat()
          .filter(Boolean);

        const popoverContent = (
          <ul style={{ padding: '4px' }}>
            {debtsDetail?.map((debt, idx) => (
              <li key={debt.name + idx}>
                {debt?.name}: Rp{Number(debt.amount).toLocaleString('en')}
              </li>
            ))}
          </ul>
        );

        console.log({ debtsDetail });

        return (
          <Space>
            <span style={{ color: '#FF4D4F' }}>
              {data > 0 ? `Rp${(data || 0).toLocaleString()}` : 'Lunas'}
            </span>
            <Popover content={popoverContent}>
              <InfoCircleOutlined style={{ color: '#459FEA' }} />
            </Popover>
          </Space>
        );
      },
    },
    {
      title: 'Kelas',
      dataIndex: 'grade',
      key: 'grade',
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      render: (data) => <span>{data?.name || '-'}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      fixed: 'right',
      render: (data, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            history.push({
              pathname: `/daftar-murid/detail/${data}`,
              search: queryString.stringify({
                unit: unitSlug[record?.unit?.name] || '',
                tabs: 'tunggakan',
              }),
            });
          }}
        >
          Lihat
        </Button>
      ),
    },
  ];

  const MenuUnit = ({ onClick }) => {
    return (
      <Menu>
        <Menu.Item key="all" onClick={() => onClick('')}>
          Semua Unit
        </Menu.Item>
        {arrayUnit.map((unit) => (
          <Menu.Item key={unit._id} onClick={() => onClick(unit)}>
            {unit.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <article>
      <section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <h3>Total Murid: {totalData}</h3>
        <Space>
          <Input.Search
            placeholder="Cari nama murid"
            value={nameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
          />

          <Dropdown
            disabled
            overlay={<MenuUnit onClick={(v) => setSelectedUnit(v)} />}
          >
            <Button disabled icon={<DownOutlined />}>
              {unitName || 'Semua Unit'}
            </Button>
          </Dropdown>
        </Space>
      </section>
      <Table
        columns={tableColumns}
        dataSource={copiedData}
        pagination={{
          position: ['bottomLeft'],
          total: totalData && totalData,
          onChange: (page) => {
            setCurrentPage(page);
          },
          current: currentPage,
          pageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ['10', '30', '50'],
          onShowSizeChange: (curr, pageSize) => {
            setLimit(pageSize);
          },
        }}
        scroll={{ x: 1200, y: 350 }}
        loading={loading}
      />
    </article>
  );
};

export default DashboardTunggakan;

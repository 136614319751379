import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Space,
  message,
  Input,
  Select,
  List,
  Checkbox,
  Modal as AntdModal,
} from 'antd';
import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory } from 'react-router-dom';
import { Modal } from 'components/shared';
import { useStore } from 'store';
import {
  blastReminder,
  getListKelasId,
  getMuridByGrade,
  submitStudentGraduate,
} from 'utils/fetch';
import { CardBase, Loader } from 'components/atoms';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const KirimReminder = () => {
  const history = useHistory();
  const {
    state: { listTahunAjaran, selectedUnit, activeSchoolYear },
  } = useStore();
  const [listMurid, setListMurid] = useState([]);
  const [listKelas, setListKelas] = useState([]);
  const [filterKelas, setFilterKelas] = useState([]);
  const [studentByGrade, setStudentByGrade] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState([]);
  const [konfirmasi, setKonfirmasi] = useState({
    state: false,
    data: null,
  });
  const [tahunAjaran, setTahunAjaran] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [nameSearch, setNameSearch] = useState('');
  const [studentWithDebt, setStudentWithDebt] = useState([]);
  const [hasNoGrade, setHasNoGrade] = useState([]);
  const [notActive, setNotActive] = useState([]);
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;

  useEffect(() => {
    if (tahunAjaran) {
      fetchData();
      fetchDataKelas();
    }
  }, [tahunAjaran]);

  useState(() => {
    setTahunAjaran(schoolYearId);
  }, [schoolYearId]);

  useEffect(() => {
    if (nameSearch) {
      const filtered = listMurid.filter((murid) =>
        murid?.name.toLowerCase().includes(nameSearch.toLowerCase())
      );
      console.log(filtered, 'test');

      setFilteredStudent(filtered);
    } else {
      setFilteredStudent(listMurid);
    }
  }, [nameSearch]);

  useEffect(() => {
    if (filterKelas.length && studentByGrade) {
      if (filterKelas.some((el) => el === 'all-debt')) {
        setListMurid(studentWithDebt);
        setFilteredStudent(studentWithDebt);
      } else {
        const murid = studentByGrade.map((el) => {
          const inFilter = filterKelas.some((fil) => fil._id === el._id);
          if (inFilter) {
            return el.students;
          } else {
            return;
          }
        });

        if (filterKelas.some((fil) => fil === 'Murid Tanpa Kelas')) {
          murid.push(hasNoGrade);
        }

        if (filterKelas.some((fil) => fil === 'graduated')) {
          murid.push(notActive);
        }

        const flattened = murid
          .flatMap((x) => x)
          .filter((y) => y !== undefined);

        setListMurid(flattened);
        setFilteredStudent(flattened);
      }
    } else {
      setListMurid([]);
      setFilteredStudent([]);
    }
  }, [filterKelas, studentByGrade]);

  const handleSubmit = (values) => {
    setLoadingSubmit(true);
    blastReminder(unitId, schoolYearId, values)
      .then((res) => {
        console.log(res);
        message.success('Berhasil mengirimkan reminder tagihan');
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.data?.m || 'Gagal mengirimkan reminder');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const fetchDataKelas = () => {
    getListKelasId(unitId, tahunAjaran)
      .then((res) => {
        console.log(res);
        setListKelas(res.grades);
      })
      .catch((err) => console.log(err.response));
  };

  const fetchData = () => {
    getMuridByGrade(unitId, tahunAjaran)
      .then((res) => {
        console.log(res.grades, 'this is response grade');
        setStudentByGrade(res.grades);
        setHasNoGrade(res.studentsNoGrades);
        setNotActive(
          res.studentsNotActive?.map((std) => ({ ...std, graduated: true }))
        );
        const withDebt = res.grades.map((grade) => {
          const students = grade.students;
          const debt = students.map((std) => {
            if (std.totalDebt > 0) {
              return { ...std, grade: grade?._id };
            }
          });

          return debt.filter(Boolean);
        });

        const flattened = withDebt.flatMap((x) => x);

        setStudentWithDebt(flattened);
      })
      .catch((err) => {
        console.log(err.response, 'this is error fetch');
      });
  };

  useEffect(() => {
    if (listKelas || listKelas.length) {
      setFilterKelas([listKelas[0]]);
    }
  }, [listKelas]);

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() =>
            history.push(
              `/daftar-murid?unit=${selectedUnit?.name.toLowerCase()}`
            )
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Kirim Reminder Tunggakan</h2>

        <div style={{ marginTop: '2rem' }}>
          <h2>Pilih Murid</h2>
          {studentByGrade ? (
            <>
              <Row gutter={[24, 14]}>
                <Col span="9">
                  <CardBase
                    style={{
                      overflowY: 'auto',
                      height: '42.5rem',
                    }}
                  >
                    <h3>Daftar Kelas</h3>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '1rem 0',
                      }}
                    >
                      <Checkbox
                        checked={filterKelas?.some((el) => el === 'all-debt')}
                        onChange={() => {
                          if (filterKelas?.some((el) => el === 'all-debt')) {
                            const filtered = filterKelas.filter(
                              (el) => el !== 'all-debt'
                            );
                            setSelectedStudent([]);
                            setFilterKelas(filtered);
                          } else {
                            setFilterKelas(['all-debt']);
                          }
                        }}
                      >
                        Murid Nunggak
                      </Checkbox>
                      <span style={{ color: '#FF4D4F' }}>
                        {studentWithDebt?.length || 0} Murid
                      </span>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '1rem 0',
                      }}
                    >
                      <Checkbox
                        checked={filterKelas?.some((el) => el === 'graduated')}
                        onChange={() => {
                          if (filterKelas?.some((el) => el === 'graduated')) {
                            const filtered = filterKelas.filter(
                              (el) => el !== 'graduated'
                            );
                            const filteredByGrade = selectedStudent?.filter(
                              (std) => !std?.graduated
                            );
                            setSelectedStudent(filteredByGrade);
                            setFilterKelas(filtered);
                          } else {
                            setFilterKelas([...filterKelas, 'graduated']);
                          }
                        }}
                      >
                        Lulus
                      </Checkbox>
                      <span>{notActive?.length || 0} Murid</span>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '1rem 0',
                      }}
                    >
                      <Checkbox
                        disabled={filterKelas.some((el) => el === 'all-debt')}
                        checked={filterKelas?.some(
                          (el) => el === 'Murid Tanpa Kelas'
                        )}
                        onChange={() => {
                          if (
                            filterKelas?.some(
                              (el) => el === 'Murid Tanpa Kelas'
                            )
                          ) {
                            const filtered = filterKelas.filter(
                              (el) => el !== 'Murid Tanpa Kelas'
                            );
                            const filteredByGrade = selectedStudent?.filter(
                              (std) => std?.grade
                            );
                            setSelectedStudent(filteredByGrade);
                            setFilterKelas(filtered);
                          } else {
                            setFilterKelas([
                              ...filterKelas,
                              'Murid Tanpa Kelas',
                            ]);
                          }
                        }}
                      >
                        Tanpa Kelas
                      </Checkbox>
                      <span>{hasNoGrade?.length || 0} Murid</span>
                    </div>
                    {studentByGrade?.map((grade) => {
                      const checked = filterKelas?.some(
                        (el) => el._id === grade._id
                      );
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: '1rem 0',
                          }}
                        >
                          <Checkbox
                            disabled={filterKelas.some(
                              (el) => el === 'all-debt'
                            )}
                            checked={checked}
                            onChange={() => {
                              if (checked) {
                                const filtered = filterKelas.filter(
                                  (el) => el._id !== grade._id
                                );
                                const filteredByGrade = selectedStudent?.filter(
                                  (std) => std.grade !== grade._id
                                );
                                setSelectedStudent(filteredByGrade);
                                setFilterKelas(filtered);
                              } else {
                                setFilterKelas([...filterKelas, grade]);
                              }
                            }}
                          >
                            {grade.name}
                          </Checkbox>
                          <span>{grade.students.length} Murid</span>
                        </div>
                      );
                    })}
                  </CardBase>
                </Col>
                <Col span="15">
                  <section
                    style={{
                      overflowY: 'auto',
                      height: '42.5rem',
                    }}
                  >
                    <Input.Search
                      placeholder="Cari nama murid"
                      onChange={(e) => {
                        const value = e.target.value;
                        setNameSearch(value);
                      }}
                    />
                    {filteredStudent.length ? (
                      <section style={{ margin: '1rem 0' }}>
                        <h4>Murid Terpilih: {selectedStudent.length}</h4>
                        <Checkbox
                          onChange={() => {
                            // const all = listMurid.map((el) => el._id);
                            if (listMurid.length === selectedStudent.length) {
                              setSelectedStudent([]);
                            } else {
                              setSelectedStudent(listMurid);
                            }
                          }}
                          checked={listMurid.length === selectedStudent.length}
                          style={{ margin: '0.5rem 0' }}
                        >
                          Pilih Semua Murid
                        </Checkbox>
                        <List
                          dataSource={filteredStudent}
                          renderItem={(item) => {
                            const checked = selectedStudent.some(
                              (el) => el._id === item._id
                            );

                            return (
                              <List.Item>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <Checkbox
                                    checked={checked}
                                    onChange={() => {
                                      if (checked) {
                                        const filtered = selectedStudent.filter(
                                          (el) => el._id !== item._id
                                        );
                                        setSelectedStudent(filtered);
                                      } else {
                                        setSelectedStudent([
                                          ...selectedStudent,
                                          item,
                                        ]);
                                      }
                                    }}
                                  >
                                    {item.name}
                                  </Checkbox>
                                  {item.totalDebt ? (
                                    <span style={{ color: '#FF4D4F' }}>
                                      -Rp
                                      {(item.totalDebt || 0).toLocaleString()}
                                    </span>
                                  ) : (
                                    <span>LUNAS</span>
                                  )}
                                </div>
                              </List.Item>
                            );
                          }}
                        />
                      </section>
                    ) : (
                      <p
                        style={{
                          textAlign: 'center',
                          marginTop: '1.5rem',
                          width: '100%',
                        }}
                      >
                        No Data
                      </p>
                    )}
                  </section>
                </Col>
              </Row>
            </>
          ) : (
            <Loader text="Fetching data..." />
          )}
        </div>

        <StyledFixedFooter>
          <Space>
            <Button
              onClick={() => {
                history.push(
                  `/daftar-murid?unit=${selectedUnit?.name.toLowerCase()}`
                );
              }}
            >
              Kembali
            </Button>
            <Button
              type="primary"
              onClick={() => {
                AntdModal.confirm({
                  title: 'Cek Kembali Daftar Murid',
                  icon: <ExclamationCircleOutlined />,
                  content:
                    'Mohon periksa kembali daftar murid. Jika sudah benar, Anda dapat memproses dengan menekan tombol “Kirim Reminder”',
                  onOk() {
                    handleSubmit({
                      studentIds: selectedStudent.map((std) => std._id),
                    });
                  },
                  okText: 'Kirim Reminder',
                  cancelText: 'Batal',
                });
              }}
              disabled={!selectedStudent.length}
              loading={loadingSubmit}
            >
              Kirim Reminder
            </Button>
          </Space>
        </StyledFixedFooter>
      </StyledContentWrapper>

      <Modal.KonfirmasiKelulusan
        visible={konfirmasi.state}
        onClose={() => setKonfirmasi({ state: false, data: null })}
        selectedStudent={selectedStudent}
        data={konfirmasi.data}
        handleSubmit={handleSubmit}
        loadingSubmit={loadingSubmit}
      />
    </StyledPageContainer>
  );
};

export default KirimReminder;

import { CardBase, CustomButton } from 'components/atoms';
import { Drawer, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  message,
  PageHeader as ANTDHeader,
  Row,
  Space,
  Tag,
} from 'antd';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import { EditOutlined, StarOutlined, StarTwoTone } from '@ant-design/icons';
import IC_FIXED from 'assets/icons/fixed-payment.svg';
import IC_FLEXIBLE from 'assets/icons/flexible-payment.svg';
import {
  editSistemPembayaran,
  editTanggalReminder,
  editVACode,
  getListSistemPembayaran,
} from 'utils/fetch';

const SistemPembayaran = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    state: { selectedUnit },
  } = useStore();
  const [data, setData] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({
    sistemPembayaran: false,
    virtualAccount: false,
    emailReminder: false,
  });
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: '',
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const unitId = selectedUnit?._id;

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/pengaturan-unit/tipe-tagihan',
        node: <span>Sistem Pembayaran</span>,
      },
    ];

    handleBreadcrumb(newBreadcrumb);
    handleSiderActive([9, '9.2']);
  }, []);

  useEffect(() => {
    if (selectedUnit) {
      fetchDataSistemPembayaran();
    }
  }, [selectedUnit]);

  const fetchDataSistemPembayaran = () => {
    getListSistemPembayaran(unitId)
      .then((res) => {
        if (res) {
          console.log(res);
          setData(res.unit);
        }
      })
      .catch((err) => console.log(err.response));
  };

  const handleSubmitVACode = (values) => {
    // console.log(values);
    setLoadingSubmit(true);
    editVACode(values, unitId)
      .then((res) => {
        message.success('Berhasil mengganti kode virtual account');
        fetchDataSistemPembayaran();
        setOpenDrawer({
          ...openDrawer,
          virtualAccount: false,
        });
      })
      .catch((err) => message.error(err.response.data.m))
      .finally(() => setLoadingSubmit(false));
  };

  const handleTanggalPembayaran = (values) => {
    setLoadingSubmit(true);

    editTanggalReminder(unitId, values)
      .then((res) => {
        if (res) {
          message.success('Berhasil mengganti tanggal reminder tagihan');
          fetchDataSistemPembayaran();
          setOpenDrawer({
            ...openDrawer,
            emailReminder: false,
          });
        }
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.m || 'Gagal mengganti tanggal reminder tagihan'
        );
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleEditPembayaran = (values) => {
    setLoadingSubmit(true);

    const payload = {
      ...values,
      fixed_payment_status: values.fixed_payment_status === 0 ? false : true,
      variable_payment_status:
        values.variable_payment_status === 0 ? false : true,
    };
    editSistemPembayaran(payload, unitId)
      .then((res) => {
        message.success('Berhasil mengganti sistem pembayaran');
        fetchDataSistemPembayaran();
        setOpenDrawer({
          ...openDrawer,
          sistemPembayaran: false,
        });
      })
      .catch((err) => message.error(err.response.data.m))
      .finally(() => setLoadingSubmit(false));
  };

  return (
    <>
      <section>
        <section style={{ marginBottom: '4rem' }}>
          <PageHeader>
            <CustomButton.IconTextButton
              type="primary"
              icon={IC_PLUSR}
              onClick={() => {
                setIsEdit({ ...isEdit, data: data });
                setOpenDrawer({ ...openDrawer, sistemPembayaran: true });
              }}
            >
              {data?.fixed_payment_status || data?.variable_payment_status
                ? 'Ubah'
                : 'Aktifkan'}{' '}
              Sistem Pembayaran
            </CustomButton.IconTextButton>
          </PageHeader>
          <Row gutter={[24, 0]}>
            <Col span="12">
              <Card
                style={{
                  width: '100%',
                }}
              >
                <Space size="large">
                  <img src={IC_FIXED} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Space>
                      <span style={{ fontSize: '1.75rem' }}>Fixed Payment</span>
                      {data?.fixed_payment_status && (
                        <Tag
                          color="blue"
                          style={{ textTransform: 'uppercase' }}
                        >
                          Aktif
                        </Tag>
                      )}
                    </Space>

                    {!data?.fixed_payment_status ? (
                      <Tag
                        color="red"
                        style={{
                          width: 'fit-content',
                          textTransform: 'uppercase',
                        }}
                      >
                        Tidak Aktif
                      </Tag>
                    ) : (
                      <p>
                        Tanggal {data?.fixed_payment_start} -{' '}
                        {data?.fixed_payment_end}
                      </p>
                    )}
                  </div>
                </Space>
              </Card>
            </Col>
            <Col span="12">
              <Card
                style={{
                  width: '100%',
                }}
              >
                <Space size="large">
                  <img src={IC_FLEXIBLE} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Space>
                      <span style={{ fontSize: '1.75rem' }}>
                        Flexible Payment
                      </span>
                      {data?.variable_payment_status && (
                        <Tag
                          color="blue"
                          style={{ textTransform: 'uppercase' }}
                        >
                          Aktif
                        </Tag>
                      )}
                    </Space>
                    {!data?.variable_payment_status ? (
                      <Tag
                        color="red"
                        style={{
                          width: 'fit-content',
                          textTransform: 'uppercase',
                        }}
                      >
                        Tidak Aktif
                      </Tag>
                    ) : (
                      <p>
                        Tanggal {data?.variable_payment_start} -{' '}
                        {data?.variable_payment_end}
                      </p>
                    )}
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>
        </section>

        <section>
          <PageHeader title="Kode Virtual Account" />
          <Row gutter={[24, 0]}>
            <Col span="12">
              <Card
                style={{
                  width: '100%',
                }}
              >
                <div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <h3 style={{ margin: 0, width: '100%' }}>
                      Kode Bank Virtual Account
                    </h3>
                    {data?.va_code && (
                      <Button
                        type="text"
                        onClick={() => {
                          setIsEdit({
                            state: true,
                            data: String(data?.va_code),
                          });
                          setOpenDrawer({
                            ...openDrawer,
                            virtualAccount: true,
                          });
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    )}
                  </div>
                  {data?.va_code ? (
                    <h3>{data?.va_code}</h3>
                  ) : (
                    <Button
                      onClick={() =>
                        setOpenDrawer({ ...openDrawer, virtualAccount: true })
                      }
                    >
                      Input Kode VA
                    </Button>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </section>

        <section style={{ marginTop: '4rem' }}>
          <PageHeader title="Tanggal Reminder Tunggakan" />
          <Row gutter={[24, 0]}>
            <Col span="12">
              <Card
                style={{
                  width: '100%',
                }}
              >
                <div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <h3 style={{ margin: 0, width: '100%' }}>
                      Tanggal Reminder:
                    </h3>
                    {data?.email_debt_reminder && (
                      <Button
                        type="text"
                        onClick={() => {
                          setIsEdit({
                            state: true,
                            data: data?.email_debt_reminder,
                          });
                          setOpenDrawer({
                            ...openDrawer,
                            emailReminder: true,
                          });
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    )}
                  </div>
                  {data?.email_debt_reminder ? (
                    <h3>Tanggal {data?.email_debt_reminder} setiap bulan</h3>
                  ) : (
                    <Button
                      onClick={() =>
                        setOpenDrawer({ ...openDrawer, emailReminder: true })
                      }
                    >
                      Pilih Tanggal
                    </Button>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </section>
      </section>

      <Drawer.VirtualAccount
        visible={openDrawer.virtualAccount}
        onCancel={() => {
          setOpenDrawer({ ...openDrawer, virtualAccount: false });
          setIsEdit({ state: false, data: '' });
        }}
        isEdit={isEdit.state}
        value={isEdit.data}
        handleSubmit={handleSubmitVACode}
        loadingState={loadingSubmit}
      />

      <Drawer.EmailReminder
        visible={openDrawer.emailReminder}
        onCancel={() => {
          setOpenDrawer({ ...openDrawer, emailReminder: false });
          setIsEdit({ state: false, data: '' });
        }}
        isEdit={isEdit.state}
        value={isEdit.data}
        handleSubmit={handleTanggalPembayaran}
        loadingState={loadingSubmit}
      />

      <Drawer.SistemPembayaran
        visible={openDrawer.sistemPembayaran}
        onCancel={() => {
          setOpenDrawer({ ...openDrawer, sistemPembayaran: false });
          setIsEdit({ state: false, data: '' });
        }}
        isEdit={isEdit.state}
        value={isEdit.data}
        handleSubmit={handleEditPembayaran}
        loadingState={loadingSubmit}
      />
    </>
  );
};

export default SistemPembayaran;

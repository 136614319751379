export const themeColor = {
  primary: '#459FEA',
  success: '#4D9970',
};

export const themeTitle = {
  title: 'SKK Sistem Tagihan',
  description: 'SKK Sistem Tagihan.',
  headline: 'SKK Sistem Tagihan',
};

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Card } from 'components/fragments';
import { Drawer, InputWrapper, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import queryString from 'querystring';
import IC_EDIT from 'assets/icons/Edit.svg';
import { CustomButton } from 'components/atoms';
import { useHistory, useLocation } from 'react-router-dom';
import {
  downloadDanaMasuk,
  getListKelasId,
  getListTahunAjaran,
  getPayment,
  deleteRefundData,
} from 'utils/fetch';
import {
  AccountBookOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  RetweetOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import exportExcel from 'utils/helpers/xlsx';
import moment from 'moment';
import { goToSearchQuery } from 'utils/helpers/common';

const SampleData = [
  {
    no: 1,
    name: 'Test',
    grade: {
      name: 'TK A1',
    },
    spp_month: 'Agustus 2021',
    amount: 200000,
    totalDebt: 0,
    _id: 'asdjkhasjkd8675agsjha',
  },
];

export const RefundList = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas, listTahunAjaran },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    content: 0,
    id: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(null);
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [otherData, setOtherData] = useState(null);
  const [filter, setFilter] = useState({
    student_name: null,
    start_date: null,
    end_date: null,
    refund_origin: null,
    name: null,
    grade: null,
    status: null,
    nis: null,
  });
  const [loadingDownload, setLoadingDownload] = useState(false);
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    name,
    grade,
    nis,
    student_name,
    start_date,
    end_date,
    refund_origin,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    page,
    limit,
    unit,
    name,
    grade,
    nis,
    student_name,
    start_date,
    end_date,
    refund_origin,
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/refund-list',
        node: <span>List Refund</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['8']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (unitId && schoolYearId) {
      fetchDataListKelas();
      fetchDataTahunAjaran();
    }
  }, [unitId, schoolYearId]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [
    page,
    limit,
    name,
    grade,
    nis,
    student_name,
    start_date,
    end_date,
    refund_origin,
    selectedUnit,
  ]);

  useEffect(() => {
    setFilter({
      name,
      grade,
      nis,
      student_name,
      start_date,
      end_date,
      refund_origin,
    });
  }, [name, grade, nis, student_name, start_date, end_date, refund_origin]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `limit=${limit}&page=${page - 1}`;

    if (name) query += `&name=${name}`;
    if (student_name) query += `&student_name=${student_name}`;
    if (start_date) query += `&start_date=${start_date}`;
    if (end_date) query += `&end_date=${end_date}`;
    if (refund_origin) query += `&refund_origin=${refund_origin}`;
    if (grade) query += `&grade=${grade}`;
    if (nis) query += `&student_nik=${nis}`;

    console.log(selectedUnit, unitId, 'this is unit');

    setLoading(true);

    getPayment(unitId, 'refund', query)
      .then((res) => {
        setLoading(false);
        const numbered = res.refunds.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        console.log(res, 'this is response');
        if (numbered.length) {
          setData(numbered);
        }
        setTotalData(res.totalData);
        setOtherData({
          totalActiveRefund: res.totalActiveRefund,
          totalRefundAmount: res.totalRefundAmount,
        });
        setFilterPopover(false);
      })
      .catch((err) => {
        setLoading(false);
        setFilterPopover(false);
        console.log(err, 'this is error fetch');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          name="student_name"
          placeholder="Nama Murid"
          value={filter?.student_name}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              student_name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Input
          type="text"
          name="nis"
          value={filter?.nis}
          placeholder="Nomor NIS"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              nis: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Select
          placeholder="Kelas"
          style={{ width: '100%' }}
          value={filter?.grade}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            console.log(newFilter);
            setFilter(newFilter);
          }}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>

        <Input
          type="text"
          name="name"
          placeholder="Nama Tagihan"
          value={filter?.name}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />

        <Input
          type="text"
          name="refund_origin"
          placeholder="Refund Origin"
          value={filter?.refund_origin}
          onChange={(e) => {
            const newFilter = {
              ...filter,
              refund_origin: e.target.value,
            };
            setFilter(newFilter);
          }}
        />

        <DatePicker.RangePicker
          // placeholder="Tanggal Bayar"
          style={{ width: '100%' }}
          onChange={(e, dateString) => {
            const newFilter = {
              ...filter,
              start_date: dateString[0],
              end_date: dateString[1],
            };
            setFilter(newFilter);
            console.log(dateString, 'this is result from range picker');
          }}
        />
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  status: null,
                  grade: null,
                  nis: null,
                });
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const _handleDeleteRefund = (id) => {
    deleteRefundData(unitId, id)
      .then((res) => {
        console.log(res);
        fetchData();
      })
      .catch((err) => {
        console.log(err, 'error refund delete');
      });
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      width: 400,
      render: (data, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                `/daftar-murid/detail/${data._id}?unit=${selectedUnit?.name}`,
                '_blank'
              )
            }
          >
            {data?.name}
          </span>
        );
      },
    },
    {
      title: 'Kelas',
      dataIndex: 'grade',
      key: 'grade',
      width: 200,
      render: (data) => <span>{data ? data.name : 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Item Tagihan',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Status Refund',
      dataIndex: 'canceled_status',
      key: 'canceled_status',
      render: (data) => (
        <Tag color={data ? 'red' : 'green'}>{data ? 'Canceled' : 'Active'}</Tag>
      ),
    },
    {
      title: 'Tanggal Refund',
      dataIndex: 'date',
      key: 'date',
      render: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Jumlah Refund',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>{`Rp${(data || 0).toLocaleString()}`}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (id, record) => (
        <Space>
          {/* <Button
            icon={<EditOutlined />}
            onClick={() => {
              setOpenDrawer({
                state: record.refund_origin,
                id: id,
                content: 3,
                student: record.student._id,
              });
            }}
          >
            Edit
          </Button> */}
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => _handleDeleteRefund(id)}
            disabled={record?.canceled_status}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const parseData = (data) => {
    const parsed = [
      ['No', 'Nama', 'Virtual Account', 'Total Nominal', 'Total Tunggakan'],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.virtual_account,
        dt.funds,
        dt.totalDebt,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const handleDownload = () => {
    let query = ``;

    // if (name) query += `name=${name}&`;
    // if (status) query += `status=${status}&`;
    // if (grade) query += `grade=${grade}&`;
    // if (nis) query += `nik=${nis}&`;

    if (name) query += `&name=${name}`;
    if (student_name) query += `&student_name=${student_name}`;
    if (start_date) query += `&start_date=${start_date}`;
    if (end_date) query += `&end_date=${end_date}`;
    if (refund_origin) query += `&refund_origin=${refund_origin}`;
    if (grade) query += `&grade=${grade}`;
    if (nis) query += `&student_nik=${nis}`;

    setLoadingDownload(true);
    downloadDanaMasuk(unitId, query)
      .then((val) => {
        const parsed = parseData(val.students);
        exportExcel(parsed, 'Laporan Dana Masuk');
      })
      .catch((err) => {
        console.log(err.response.data.m);
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  // update 2 sept. menu lainnya

  const MenuDropdown = () => {
    return (
      <Menu style={{ boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.12)' }}>
        <Menu.Item
          key="riwayat-va"
          onClick={() =>
            history.push({
              pathname: '/dana-masuk/riwayat-va',
              search: queryString.stringify({
                unit: selectedUnit.name,
              }),
            })
          }
        >
          Riwayat VA
        </Menu.Item>
        <Menu.Item key="import-va" onClick={() => setOpenModal(true)}>
          Import VA
        </Menu.Item>
        <Menu.Item key="download" onClick={() => handleDownload()}>
          Download Laporan
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <PageHeader></PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>

      <Row gutter={[24, 0]}>
        <Col span="8">
          <InfoCard
            title="Jumlah Transaksi Refund"
            content={otherData?.totalActiveRefund || 0}
            sideColor="green"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Total Nominal Refund"
            content={`Rp${(
              otherData?.totalRefundAmount || 0
            ).toLocaleString()}`}
            sideColor="blue"
          />
        </Col>
      </Row>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1600 }}
          loading={loading}
        />
      </section>

      <Drawer.DetailDanaMasuk
        visible={openDrawer.state === 'Dana Masuk'}
        onCancel={() => {
          setOpenDrawer({
            content: 0,
            id: '',
            state: '',
          });
        }}
        value={{
          id: openDrawer.id,
          unitId: unitId,
          studentId: openDrawer?.student,
        }}
        content={openDrawer.content}
        refetchData={fetchData}
      />
    </>
  );
};

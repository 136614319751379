import React, { useState } from 'react';
import { Modal, Button, Alert, DatePicker } from 'antd';
import { BASE_URL } from 'utils/fetch';
import moment from 'moment';

const PilihTanggalCutoff = ({ visible, onCancel, type, id }) => {
  const [range, setRange] = useState([]);

  return (
    <Modal
      centered
      visible={visible}
      onCancel={() => {
        onCancel();
        setRange([]);
      }}
      okText="Download"
      onOk={() => {
        const [start, end] = range;

        if (type === 'lunas') {
          window.open(
            `${BASE_URL(
              `/grade-student/${id}/download-report?status=Paid&cut_off_start=${moment(
                start
              ).format('MM-DD-YYYY')}&cut_off_end=${moment(end).format(
                'MM-DD-YYYY'
              )}`
            )}`,
            '_blank'
          );
        } else {
          window.open(
            `${BASE_URL(
              `/grade-student/${id}/download-report?status=Outstanding&cut_off_start=${moment(
                start
              ).format('MM-DD-YYYY')}&cut_off_end=${moment(end).format(
                'MM-DD-YYYY'
              )}`
            )}`,
            '_blank'
          );
        }

        setRange([]);
        onCancel();
      }}
    >
      <section>
        <h4>Pilih Tanggal Cut Off</h4>
        <div style={{ width: '100%' }}>
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            value={range}
            onChange={(v) => {
              console.log({ v });
              setRange(v);
            }}
          />
        </div>
      </section>
    </Modal>
  );
};

export default PilihTanggalCutoff;

import React from 'react';
import { Breadcrumb } from 'antd';
import { useStore } from 'store';
import {Link} from 'react-router-dom'
const CustomBreadcrumb = () => {
  const {
    state: { breadcrumbs },
  } = useStore();

  return (
    <Breadcrumb separator=">" style={{ fontSize: '2rem' }}>
      {breadcrumbs.map((el) => {
        return(
        <Breadcrumb.Item><Link to={el?.linkTo}>{el.node}</Link></Breadcrumb.Item>
      )})}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;

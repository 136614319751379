import { Button, Col, Input, Popover, Row, Select, Space, Table } from 'antd';
import { Drawer, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import {
  generateExcelTagihan,
  getBill,
  getListKelasId,
  getListTahunAjaran,
} from 'utils/fetch';
import {
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { getNotesData, goToSearchQuery } from 'utils/helpers/common';

const TagihanLainnya = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas, listTahunAjaran },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    id: '',
    data: null,
  });
  const [data, setData] = useState(null);
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [otherData, setOtherData] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    virtual_account: null,
    student_name: null,
    school_year: null,
  });

  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    student_name,
    name,
    grade,
    virtual_account,
    school_year,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    unit,
    limit,
    page,
    name,
    grade,
    student_name,
    virtual_account,
    school_year,
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/tagihan/semua',
        node: <span>Daftar Semua Tagihan</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['3.5']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (unitId && schoolYearId) {
      fetchDataListKelas();
      // fetchDataTahunAjaran();
    }
  }, [unitId, schoolYearId]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [
    page,
    limit,
    name,
    student_name,
    grade,
    virtual_account,
    selectedUnit,
    school_year,
  ]);

  useEffect(() => {
    setFilter({
      name,
      student_name,
      grade,
      virtual_account,
      school_year,
    });
  }, [name, student_name, grade, virtual_account, school_year]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `limit=${limit}&page=${page - 1}`;

    if (student_name) query += `&student_name=${student_name}`;
    if (grade) query += `&student_grade=${grade}`;
    if (virtual_account) query += `&student_virtual_account=${virtual_account}`;
    if (school_year) query += `&school_year=${school_year}`;

    setLoading(true);

    getBill(unitId, 'all-bill', query)
      .then((res) => {
        const numbered = res.data.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        console.log(numbered);
        setData(numbered);
        setTotalData(res.totalData);
        setLoading(false);
        setOtherData({
          totalDebt: res.totalDebt,
          totalSPP: res.totalSPP,
          totalPPDB: res.totalPPDB,
          totalLainnya: res.totalLainnya,
        });
        setFilterPopover(false);
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setFilterPopover(false);
        console.log(err.response, 'this is error fetch');
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          name="student_name"
          value={filter.student_name}
          placeholder="Nama Murid"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              student_name: e.target.value,
            };
            setFilter(newFilter);
          }}
        />{' '}
        <Input
          type="text"
          name="student_virtual_account"
          value={filter.student_virtual_account}
          placeholder="Virtual Account"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              student_virtual_account: e.target.value,
            };
            setFilter(newFilter);
          }}
        />
        <Select
          placeholder="Kelas"
          value={filter.grade}
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            setFilter(newFilter);
          }}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          placeholder="Tahun Ajaran"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              school_year: val,
            };
            setFilter(newFilter);
            const year = listTahunAjaran.find((el) => el._id === val);
            setSelectedYear(year);
          }}
          value={filter?.school_year}
          allowClear
          onClear={() => {
            setSelectedYear(null);
            setFilter({
              ...filter,
              school_year: null,
            });
          }}
        >
          {listTahunAjaran?.map((year) => {
            return <Select.Option value={year._id}>{year.name}</Select.Option>;
          })}
        </Select>
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  student_name: null,
                  grade: null,
                  nis: null,
                });
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      fixed: 'left',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      width: 270,
      fixed: 'left',
      render: (data, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                `/daftar-murid/detail/${data?._id}?unit=${selectedUnit?.name}`,
                '_blank'
              )
            }
          >
            {data?.name || '-'}
          </span>
        );
      },
    },
    {
      title: 'Kelas',
      dataIndex: 'student',
      key: 'student_grade',
      width: 100,
      render: (data) => <span>{data?.grade?.name || 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Virtual Account',
      dataIndex: 'student',
      key: 'student_grade',
      width: 200,
      render: (data) => <span>{data?.virtual_account || '-'}</span>,
    },
    {
      title: 'Total Tagihan',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 200,
      render: (data) => <span>Rp {Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Aging 1 - 30 Hari',
      dataIndex: 'aging30Hari',
      key: 'aging30Hari',
      width: 150,
      render: (data) => <span>Rp {Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Aging 30 - 60 Hari',
      dataIndex: 'aging60Hari',
      key: 'aging60Hari',
      width: 150,
      render: (data) => <span>Rp {Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Aging 60 - 90 Hari',
      dataIndex: 'aging90Hari',
      key: 'aging90Hari',
      width: 150,
      render: (data) => <span>Rp {Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Aging > 90 Hari',
      dataIndex: 'agingAbove90Hari',
      key: 'agingAbove90Hari',
      width: 150,
      render: (data) => <span>Rp {Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Keterangan',
      dataIndex: 'notes',
      key: 'student_grade',
      width: 400,
      render: (data) => (
        <ul>
          {data?.map((dt) => {
            return <li>{dt}</li>;
          })}
        </ul>
      ),
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id, record) => (
        <Button
          onClick={() => setOpenDrawer({ state: true, id: id, data: record })}
          icon={<EyeOutlined />}
        >
          Lihat
        </Button>
      ),
    },
  ];

  const generateParsed = (data) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'Kelas',
        'Virtual Account',
        'Total Nominal',
        'Tagihan PPDB',
        'Tagihan SPP',
        'Tagihan Lainnya',
        'Keterangan',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt?.student?.name || '-',
        dt?.student?.grade?.name || 'Murid Tanpa Kelas',
        dt?.student?.virtual_account || '-',
        dt?.totalAmount || 0,
        dt?.ppdbAmount || 0,
        dt?.sppBillAmount || 0,
        dt?.generalBillAmount || 0,
        dt?.notes?.join('; ') || '-',
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const handleDownload = () => {
    let query = '';

    if (student_name) query += `&student_name=${student_name}`;
    if (grade) query += `&student_grade=${grade}`;
    if (virtual_account) query += `&student_virtual_account=${virtual_account}`;
    if (school_year) query += `&school_year=${school_year}`;

    setLoadingDownload(true);

    generateExcelTagihan(unitId, 'all-bill', query)
      .then((res) => {
        const { data } = res;
        if (res) {
          const toBeDownloaded = data;

          exportExcel(toBeDownloaded, 'Laporan Semua Tagihan');
        }
      })
      .catch((err) => {
        console.log(err, 'error response');
      })
      .finally(() => setLoadingDownload(false));
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Button
            type="button"
            icon={<DownloadOutlined />}
            onClick={handleDownload}
            loading={loadingDownload}
          >
            Dowload Laporan
          </Button>
        </Space>
      </PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>

      <Row gutter={[24, 0]}>
        <Col span="6">
          <InfoCard
            title="Total Tagihan"
            content={`Rp${(otherData?.totalDebt || 0).toLocaleString() || 0}`}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="6">
          <InfoCard
            title="Total Tagihan SPP"
            content={`Rp${(otherData?.totalSPP || 0).toLocaleString() || 0}`}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="6">
          <InfoCard
            title="Total Tagihan PPDB"
            content={`Rp${(otherData?.totalPPDB || 0).toLocaleString() || 0}`}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="6">
          <InfoCard
            title="Total Tagihan Lainnya"
            content={`Rp${
              (otherData?.totalLainnya || 0).toLocaleString() || 0
            }`}
            sideColor="#FFA6A7"
          />
        </Col>
      </Row>

      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1500 }}
          loading={loading}
        />
      </section>

      <Drawer.DetailSemuaTagihan
        visible={openDrawer.state}
        onCancel={() => {
          setOpenDrawer({
            state: false,
            id: '',
          });
        }}
        value={{ unitId, id: openDrawer.id }}
        refetchData={fetchData}
        data={openDrawer.data}
      />
    </>
  );
};

export default TagihanLainnya;

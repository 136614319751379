import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import {
  PageWrapper,
  ContentWrapper,
  TitleContainer,
  FormContainer,
  FooterContainer,
} from './style';
import { useHistory } from 'react-router-dom';
import { SignInForm } from 'components/form';
import { loginUser } from 'utils/fetch';
import { useStore } from 'store';
import BrandLogo from 'assets/accelego-logo';
import { themeTitle } from 'lib/theme';

const SignIn = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { handleUserData } = useStore();
  const [loading, setLoading] = useState(false);

  const handleLogin = (values) => {
    setLoading(true);
    return loginUser(values)
      .then((res) => {
        handleUserData(res);
        console.log(res);
        if (res) window.location.replace('/dashboard/semua');
        // if (res) history.push('/dashboard/semua');
      })
      .catch((err) => {
        message.error(err?.response?.data.m || 'Gagal melakukan login');
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <TitleContainer>
          {/* <BrandLogo /> */}
          <img
            src="https://www.skketapang.org/wp-content/uploads/2019/11/Logo-SKK.png"
            alt="brand-logo"
          />
          <h1 style={{ textAlign: 'center' }}>{themeTitle.headline}</h1>
        </TitleContainer>
        <FormContainer>
          <Form form={form} layout="vertical" onFinish={handleLogin}>
            <SignInForm />
          </Form>
        </FormContainer>
        <Button type="primary" onClick={form.submit} loading={loading}>
          Sign In
        </Button>
        <FooterContainer>
          <Button type="link" onClick={() => history.push('/forgot-password')}>
            Lupa Password?
          </Button>
        </FooterContainer>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default SignIn;

import { StarFilled } from '@ant-design/icons';
import moment from 'moment';
import queryString from 'querystring';

export const statusMurid = {
  0: {
    status: 'Default',
    color: 'orange',
  },
  1: {
    status: 'AKTIF',
    color: 'blue',
  },
  2: {
    status: 'TIDAK AKTIF',
    color: 'red',
  },
  3: {
    status: 'LULUS',
    color: 'green',
  },
};

export const statusWhatsapp = {
  '-1': {
    status: 'Gagal Terkirim',
    color: 'red',
  },
  0: {
    status: 'Belum Terkirim',
    color: 'orange',
  },
  2: {
    status: 'Sedang Dikirim',
    color: 'blue',
  },
  1: {
    status: 'Terkirim',
    color: 'green',
  },
};

export const statusWhatsappNew = {
  pending: {
    status: 'Belum Terkirim',
    color: 'yellow',
  },
  sent: {
    status: 'Sedang Dikirim',
    color: 'blue',
  },
  delivered: {
    status: 'Terkirim',
    color: 'green',
  },
  read: {
    status: 'Sudah Dibaca',
    color: 'teal',
  },
  rejected: {
    status: 'Gagal Terkirim',
    color: 'red',
  },
  failed: {
    status: 'Gagal Terkirim',
    color: 'red',
  },
};

export const optionsStatusWhatsappNew = [
  { key: 'pending', name: 'Pending' },
  { key: 'sent', name: 'Sent' },
  { key: 'delivered', name: 'Delivered' },
  { key: 'read', name: 'Read' },
  { key: 'rejected', name: 'Rejected' },
  { key: 'failed', name: 'Failed' },
];

export const messageType = {
  1: 'Reminder',
  2: 'Pembayaran',
  3: 'Others',
  4: 'Admin',
};

export const tipeMurid = {
  0: {
    text: 'Default',
    color: 'red',
  },
  1: {
    text: 'Murid Standard',
    color: 'blue',
  },
  2: {
    text: (
      <span>
        <StarFilled /> &nbsp;ASAK
      </span>
    ),
    color: 'orange',
  },
};

export const billType = {
  SPP: 'spp',
  'PPDB Instalment': 'ppdb-instalment',
  General: 'general',
  Tabungan: 'savings-allocation',
};

export const ArrayTanggal = (limit) =>
  Array.from({ length: limit }, (_, i) => i + 1);

export const monthDictionary = {
  1: 'Januari',
  2: 'Februari',
  3: 'Maret',
  4: 'April',
  5: 'Mei',
  6: 'Juni',
  7: 'Juli',
  8: 'Agustus',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Desember',
};

export const goToSearchQuery = (qKey, value, filterQuery, history) => {
  Object.keys(filterQuery).forEach((key) => {
    if (!filterQuery[key]) delete filterQuery[key];
  });
  history.push({
    search: queryString.stringify({
      ...filterQuery,
      [qKey]: value,
    }),
  });
};

export const jenisMurid = [
  {
    value: 1,
    label: 'Murid Standar',
  },
  {
    value: 2,
    label: 'Anak Guru / Karyawan',
  },
  {
    value: 3,
    label: 'Anak yang Menerima Bantuan Siswa',
  },
  {
    value: 4,
    label: 'Anak Berprestasi',
  },
];

export const generateKategoriPembayaran = (resultDays) => {
  let paymentTypes = [];
  let daysPrint = [];
  Object.keys(resultDays).forEach((propsDay) => {
    let paymentTypeSortPerDay = [];
    let rowPerDay = [propsDay];

    Object.keys(resultDays[propsDay]).forEach((propsUnit) => {
      Object.keys(resultDays[propsDay][propsUnit].payment_type).forEach(
        (propsPaymentType) => {
          // console.log(resultDays[propsDay][propsUnit].payment_type[propsPaymentType])
          if (paymentTypes.indexOf(propsPaymentType) == -1) {
            paymentTypes.push(propsPaymentType);
          }

          let findPerDay = paymentTypeSortPerDay.findIndex(
            (perDay) => perDay.type === propsPaymentType
          );
          if (findPerDay === -1) {
            paymentTypeSortPerDay.push({
              type: propsPaymentType,
              amount:
                resultDays[propsDay][propsUnit].payment_type[propsPaymentType],
            });
          } else {
            paymentTypeSortPerDay[findPerDay].amount +=
              resultDays[propsDay][propsUnit].payment_type[propsPaymentType];
          }
        }
      );
    });

    let sortingPaymentTypes = paymentTypes.map((type) => {
      let findTypePerDay = paymentTypeSortPerDay.find((d) => d.type === type);
      return findTypePerDay ? findTypePerDay.amount : 0;
    });

    rowPerDay.push(
      paymentTypeSortPerDay.reduce((acc, curr) => {
        acc += curr.amount;
        return acc;
      }, 0)
    );

    rowPerDay = rowPerDay.concat(sortingPaymentTypes);
    daysPrint.push(rowPerDay);
  });

  let headerPaymentCategory = [
    ['Tanggal', 'Total Amount'].concat(paymentTypes),
  ];
  let printPaymentCategory = headerPaymentCategory.concat(daysPrint);

  return printPaymentCategory;
};

export const generateReportByUnit = (resultDays) => {
  let schoolsReport = [];
  Object.keys(resultDays).forEach((propsDay) => {
    Object.keys(resultDays[propsDay]).forEach((propsSchool) => {
      let findSchool = schoolsReport.findIndex(
        (s) => s.schoolName === resultDays[propsDay][propsSchool].unitName
      );
      if (findSchool === -1) {
        let newSchool = {
          schoolName: resultDays[propsDay][propsSchool].unitName,
          days: [
            {
              date: propsDay,
            },
          ],
          General: [],
          'PPDB Instalment': [],
          SPP: [],
        };

        Object.keys(resultDays[propsDay][propsSchool].billType).forEach(
          (propsBill) => {
            newSchool.days[0][propsBill] = [];
            Object.keys(
              resultDays[propsDay][propsSchool].billType[propsBill].paid_items
            ).forEach((propsBillItem) => {
              if (newSchool[propsBill].indexOf(propsBillItem) == -1) {
                newSchool[propsBill].push(propsBillItem);
              }
              newSchool.days[0][propsBill].push({
                item: propsBillItem,
                amount:
                  resultDays[propsDay][propsSchool].billType[propsBill]
                    .paid_items[propsBillItem],
              });
            });
          }
        );

        schoolsReport.push(newSchool);
      } else {
        let day = {
          date: propsDay,
        };

        Object.keys(resultDays[propsDay][propsSchool].billType).forEach(
          (propsBill) => {
            day[propsBill] = [];
            Object.keys(
              resultDays[propsDay][propsSchool].billType[propsBill].paid_items
            ).forEach((propsBillItem) => {
              if (
                schoolsReport[findSchool][propsBill].indexOf(propsBillItem) ==
                -1
              ) {
                schoolsReport[findSchool][propsBill].push(propsBillItem);
              }

              day[propsBill].push({
                item: propsBillItem,
                amount:
                  resultDays[propsDay][propsSchool].billType[propsBill]
                    .paid_items[propsBillItem],
              });
            });
          }
        );

        schoolsReport[findSchool].days.push(day);
      }
    });
  });

  schoolsReport = schoolsReport.map((school, index) => {
    let rowSchools = [[`Laporan Unit ${school.schoolName}`]];
    let rowKategori = ['Tanggal'];
    school['SPP'].map((s) => {
      rowKategori.push(s);
    });
    school['PPDB Instalment'].map((s) => {
      rowKategori.push(s);
    });
    school.General.map((s) => {
      rowKategori.push(s);
    });

    rowSchools.push(rowKategori);

    for (let i = 0; i < school.days.length; i++) {
      let day = school.days[i];
      let dayRow = [day.date];
      let spp = school['SPP'].map((g) => {
        let totalAmount = day['SPP']
          ? day['SPP'].reduce((acc, curr) => {
              if (curr.item == g) {
                acc += curr.amount;
              }

              return acc;
            }, 0)
          : 0;

        return totalAmount;
      });

      dayRow = dayRow.concat(spp);

      let ppdb = school['PPDB Instalment'].map((g) => {
        let totalAmount = day['PPDB Instalment']
          ? day['PPDB Instalment'].reduce((acc, curr) => {
              if (curr.item == g) {
                acc += curr.amount;
              }

              return acc;
            }, 0)
          : 0;

        return totalAmount;
      });

      dayRow = dayRow.concat(ppdb);

      let general = school.General.map((g) => {
        let totalAmount = day.General
          ? day.General.reduce((acc, curr) => {
              if (curr.item == g) {
                acc += curr.amount;
              }

              return acc;
            }, 0)
          : 0;

        return totalAmount;
      });

      dayRow = dayRow.concat(general);

      rowSchools.push(dayRow);
    }

    console.log({
      sheetName: school.schoolName,
      data: rowSchools,
    });

    return {
      sheetName: school.schoolName.replace('/', ' '),
      data: rowSchools,
    };
  });

  return schoolsReport;
};

export const getNotesData = (dt) => {
  const sppBills = dt?.sppBills?.map(
    (spp) =>
      `SPP ${moment(spp.month).format('MMMM YYYY')}: Rp${Number(
        spp.total_amount - spp.paid_amount
      ).toLocaleString('en')}`
  );
  const ppdbBills = dt?.ppdbInstalments?.map(
    (ppdb) =>
      `Cicilan PPDB Ke-${ppdb.instalment_repition + 1}: Rp${Number(
        ppdb.total_amount - ppdb.paid_amount
      ).toLocaleString('en')}`
  );
  const generalBills = dt?.ppdbInstalments?.map(
    (general) =>
      `${general.name}: Rp${Number(
        general.total_amount - general.paid_amount
      ).toLocaleString('en')}`
  );

  const flatten = [...sppBills, ...ppdbBills, ...generalBills];

  return flatten.join('; ');
};

export const unitSlug = {
  'KB/TK Cibubur': 'tkc',
  'SD Cibubur': 'sdc',
  'SMP Cibubur': 'smpc',
  'SMA Cibubur': 'smac',
  'KB/TK Jakarta': 'tk',
  'SD Jakarta': 'sd',
  'SMP Jakarta': 'smp',
  'SMA Jakarta': 'sma',
};

import React, { useEffect } from 'react';
import { Card, Col, Row, Space, Spin, Tag } from 'antd';
import { StyledContentWrapper, StyledPageContainer } from '../style';
import IC_USER from 'assets/icons/user-icon.svg';
import IC_EDIT from 'assets/icons/Edit.svg';
import { CustomButton } from 'components/atoms';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { getUserData } from 'utils/fetch';

export const Profile = () => {
  const history = useHistory();
  const {
    state: { userData },
    handleUserData,
  } = useStore();

  useEffect(() => {
    if (!userData) {
      getUserData()
        .then((res) => handleUserData(res.user))
        .catch((err) => console.error(err));
    }
  }, [userData]);

  const hidePassword = (pass) => {
    return pass?.replace(/.(?=.{4,}$)/g, '*');
  };

  if (userData)
    return (
      <StyledPageContainer>
        <StyledContentWrapper>
          <h2>Profile Saya</h2>
          <div style={{ marginTop: '2rem' }}>
            <Card>
              <Row gutter={[24, 0]}>
                <Col span="4">
                  <img src={IC_USER} alt="usericon" width="90" height="90" />
                </Col>
                <Col span="16">
                  <Space size="md">
                    <span style={{ fontSize: '2rem', marginRight: '0.75rem' }}>
                      {userData.name}
                    </span>
                    <Tag color="blue">
                      {userData.type === 1 ? 'Admin' : 'User'}
                    </Tag>
                  </Space>
                  <section style={{ marginTop: '1rem' }}>
                    <Row gutter={[8, 4]}>
                      <Col span="8">Email</Col>
                      <Col span="16">{userData.email}</Col>
                    </Row>
                    <Row gutter={[8, 4]}>
                      <Col span="8">Password</Col>
                      <Col span="16">{hidePassword(userData.password)}</Col>
                    </Row>
                  </section>
                </Col>
                <Col span="4">
                  <CustomButton.IconTextButton
                    icon={IC_EDIT}
                    onClick={() => history.push('/profile/edit')}
                  >
                    Edit
                  </CustomButton.IconTextButton>
                </Col>
              </Row>
            </Card>
          </div>

          {JSON.stringify(userData)}
        </StyledContentWrapper>
      </StyledPageContainer>
    );
  else return <Spin />;
};

export { default as EditProfile } from './edit';

import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  DatePicker,
  Alert,
  Divider,
  Input,
  Button,
  Select,
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import { Footer } from './shared';
import { DeleteFilled, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { InputWrapper } from '..';

const SettingSPP = (props) => {
  const [form] = Form.useForm();
  const {
    visible,
    onCancel,
    value,
    isEdit,
    handleSubmit,
    items,
    toBeLocked,
    submitLock,
    loadingSubmit,
    ...rest
  } = props;
  const [deletedItems, setDeletedItems] = useState([]);

  useEffect(() => {
    if (!visible) {
      setDeletedItems([]);
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (value) {
      console.log(value);
      const valueAdded =
        value.type === 'SPP'
          ? value.sppItems.map((el) => ({
              master_bill_item: el.master_bill_item._id,
              priority: el.priority,
            }))
          : [
              {
                priority: '',
                master_bill_item: null,
              },
            ];

      form.setFieldsValue({
        addedItems: valueAdded.length
          ? valueAdded
          : [
              {
                priority: '',
                master_bill_item: null,
              },
            ],
      });
    } else {
      form.setFieldsValue({
        addedItems: [
          {
            priority: '',
            master_bill_item: null,
          },
        ],
      });
    }
  }, [value]);

  const handleDeletedItems = (item) => {
    setDeletedItems([...deletedItems, item]);
  };

  const flexibleDisabled = (key) => {
    return form.getFieldValue('addedItems')[key]?.fix_amount === 0;
  };

  const submitPayloadEdit = () => {
    const fieldValues = form.getFieldsValue();

    const addedItems = [];
    const editedItems = [];

    fieldValues.addedItems.forEach((val) => {
      const existed = value.sppItems.find(
        (item) => item.master_bill_item?._id === val.master_bill_item
      );

      if (existed) {
        editedItems.push({
          sppItemId: existed.spp,
          ...val,
        });
      } else {
        addedItems.push({
          ...val,
        });
      }

      // if (val?.ppdbItemId) {
      //   editedItems.push(val);
      // } else {
      //   const found = value.ppdbItems.find(
      //     (el) => el.master_bill_item?._id === val.master_bill_item
      //   );
      //   if (found) {
      //     editedItems.push({
      //       ppdbItemId: found.ppdb,
      //       ...val,
      //     });
      //   } else {
      //     addedItems.push(val);
      //   }
      // }
    });

    const deleted = deletedItems.filter(Boolean).map((el) => {
      const foundItem = value.sppItems.find(
        (val) => val.master_bill_item?._id === el
      );
      if (foundItem) {
        return foundItem?._id;
      }
    });

    value.sppItems.forEach((item) => {
      const inEdited = editedItems.some(
        (el) => el.master_bill_item === item?.master_bill_item?._id
      );

      if (!inEdited) {
        deleted.push(item?._id);
      }
    });

    const payload = value.lock_status
      ? {
          editedItems: editedItems.filter(Boolean),
        }
      : {
          addedItems,
          deletedItems: deleted,
          editedItems: editedItems.filter(Boolean),
        };

    handleSubmit(payload, value._id, value.lock_status);
  };

  return (
    <Drawer
      title={`${isEdit ? 'Edit' : toBeLocked ? 'Lock' : 'Setting'} SPP ${
        value.school_year?.name || ''
      }`}
      width="55%"
      visible={visible}
      footer={
        <Footer
          onCancel={onCancel}
          onSubmit={() => {
            if (toBeLocked) {
              submitLock(value.lock_status, value._id);
            } else {
              submitPayloadEdit();
            }
          }}
          toBeLocked={toBeLocked}
          loadingState={loadingSubmit}
        />
      }
      onClose={onCancel}
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          addedItems: [
            {
              priority: '',
              master_bill_item: null,
            },
          ],
        }}
        onFinish={() => console.log(form.getFieldsValue(), deletedItems)}
      >
        <section>
          {toBeLocked && (
            <Alert
              type="info"
              description="Mohon cek detail pengaturan sebelum lock pengaturan."
              message="Cek terlebih dahulu"
              showIcon
              style={{ marginBottom: '2rem' }}
            />
          )}
          <h3 style={{ marginBottom: '2rem' }}>Item Tagihan</h3>
          <Form.List name="addedItems" style={{ background: 'blue' }}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      required={false}
                      key={field.key}
                      style={{ height: '100%', marginBottom: 0 }}
                    >
                      <Input.Group>
                        <Row gutter={[12, 1]}>
                          <Col span={11}>
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: 'Nama item tagihan wajib diisi.',
                                },
                              ]}
                              // style={{ marginBottom: 0 }}
                              name={[field.name, 'master_bill_item']}
                            >
                              <Select
                                style={{ width: '100%', marginBottom: 0 }}
                                placeholder={`Pilih komponen ${field.name + 1}`}
                                disabled={value.lock_status}
                              >
                                {items.map((item) => {
                                  return (
                                    <Select.Option value={item._id}>
                                      {item.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={11}>
                            <InputWrapper
                              placeholder="Prioritas"
                              type="number"
                              min={1}
                              style={{ marginBottom: 0 }}
                              formItemProps={{
                                name: [field.name, 'priority'],
                              }}
                            />
                          </Col>
                          <Col span={2} style={{ textAlign: 'center' }}>
                            {fields.length > 1 ? (
                              <Button
                                type="text"
                                onClick={() => {
                                  handleDeletedItems(
                                    form.getFieldValue('addedItems')[field.name]
                                      ?.master_bill_item
                                  );
                                  remove(field.name);
                                }}
                                disabled={value.lock_status}
                                icon={
                                  <DeleteOutlined
                                    style={{ fontSize: '20px' }}
                                  />
                                }
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="icon"
                      onClick={() => add()}
                      style={{ width: '30%' }}
                      icon={<PlusOutlined />}
                    >
                      Tambah Tipe Tagihan
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </section>
      </Form>
    </Drawer>
  );
};

export default SettingSPP;

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_SIDER_ACTIVE = 'SET_SIDER_ACTIVE';
export const SET_HEADER_ACTIVE = 'SET_HEADER_ACTIVE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_SELECTED_UNIT = 'SET_SELECTED_UNIT';
export const SET_SCHOOL_YEAR = 'SET_SCHOOL_YEAR';
export const SET_LIST_SCHOOL_YEAR = 'SET_LIST_SCHOOL_YEAR';
export const SET_LIST_KELAS = 'SET_LIST_KELAS';
export const SET_CALLING_CODES = 'SET_CALLING_CODES';

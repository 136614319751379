import {
  Button,
  Carousel,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tag,
  Card,
  Divider,
  Tabs,
  message,
  Alert,
  Typography,
} from 'antd';
import { Drawer, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import IC_EDIT from 'assets/icons/Edit.svg';
import IC_CALENDAR from 'assets/icons/Calendar.svg';
import { CardBase, CustomButton, Loader } from 'components/atoms';
import {
  BASE_URL,
  editDetailMurid,
  fetchDetailTahunAjaran,
  getDetailMurid,
  getTabAllBills,
  studentTab,
  studentTabSPP,
} from 'utils/fetch';
import queryString from 'querystring';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FundProjectionScreenOutlined,
  RetweetOutlined,
  StarFilled,
} from '@ant-design/icons';
import truncateText from 'utils/helpers/truncate-text';
import { InfoCard } from 'components/fragments/card';
import { statusMurid } from 'utils/helpers/common';
import {
  DetailMuridGeneralTable,
  DetailMuridPPDBTable,
  DetailMuridRefundTable,
  DetailMuridSPPTable,
  DetailMuridMutasiTable,
  DetailMuridTunggakanTable,
  DetailMuridRiwayatVA,
  DetailMuridPaymentList,
  DetailMuridApresiasi,
} from 'components/fragments/table';
import moment from 'moment';
import styled from 'styled-components';
import exportExcel from 'utils/helpers/xlsx';
import { themeColor } from 'lib/theme';

const StyledCarousel = styled(Carousel)`
  > .slick-dots li button {
    width: 5px;
    height: 24px;
    background: #d9d9d9;
    border-radius: 10px;
  }
  > .slick-dots li.slick-active button {
    width: 5px;
    height: 32px;
    background: #1890ff;
    border-radius: 10px;
  }
`;

const { TabPane } = Tabs;
const { Link } = Typography;

const DetailMurid = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    state: { selectedUnit, activeSchoolYear },
  } = useStore();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [allocationDrawer, setAllocationDrawer] = useState({
    type: '',
    id: '',
  });
  const [editFormType, setEditFormType] = useState(1);
  const [dataMurid, setDataMurid] = useState(null);
  const [dataWali, setDataWali] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [openModal, setOpenModal] = useState({
    state: false,
    type: '',
  });

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const { tabs = 'tunggakan' } = queryString.parse(
    location.search.replace('?', '')
  );

  useEffect(() => {
    if (activeSchoolYear?._id) {
      fetchData();
    }
  }, [id, unitId, activeSchoolYear]);

  const fetchData = () => {
    setLoading(true);
    getDetailMurid(unitId, schoolYearId, id)
      .then((res) => {
        console.log(res, 'detail murid');
        setDataMurid(res.student);
        setDataWali(res.studentGuardians);
      })
      .catch((err) => console.log(err.response))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: `/daftar-murid?unit=${selectedUnit?.name?.toLowerCase()}`,
        node: <span>Daftar Murid</span>,
      },
      {
        linkTo: '/daftar-murid/detail',
        node: <span>Detail Murid</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['2']);
  }, [selectedUnit]);

  const editMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setEditFormType(1);
          setOpenDrawer(true);
        }}
      >
        Info Murid
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setEditFormType(4);
          setOpenDrawer(true);
        }}
      >
        Status Murid
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setEditFormType(2);
          setOpenDrawer(true);
        }}
      >
        Wali Murid
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setEditFormType(3);
          setOpenDrawer(true);
        }}
      >
        Nominal SPP
      </Menu.Item>
    </Menu>
  );

  const handleSubmitEdit = (values, type) => {
    setLoadingSubmit(true);
    editDetailMurid(values, unitId, schoolYearId, id, type)
      .then((res) => {
        console.log(res);
        message.success('Berhasil mengubah detail murid');
        fetchData();
        setOpenDrawer(false);
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err?.response?.data?.m || 'Gagal mengubah detail murid');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const parsedAllBills = (data) => {
    const parsed = [['No', 'Nama Tagihan', 'Tanggal Tagihan', 'Tunggakan']];

    data.forEach((dt, idx) => {
      parsed.push([
        idx + 1,
        dt.name,
        moment(dt.date).format('DD/MM/YYYY'),
        dt.paid_amount - dt.total_amount,
      ]);
    });

    return parsed;
  };

  const parsedSppBills = (data) => {
    const parsed = [
      ['No', 'Bulan', 'Status', 'Tanggal Tagihan', 'Tanggal Pembayaran'],
    ];

    data.forEach((dt, idx) => {
      const status = !dt.active_status
        ? 'TIDAK AKTIF'
        : dt.paid_amount - dt.total_amount === 0
        ? 'LUNAS'
        : dt.paid_amount - dt.total_amount;

      parsed.push([
        idx + 1,
        moment(dt.month).format('MMMM YYYY'),
        status,
        moment(dt.month).format('DD/MM/YYYY'),
        dt.bill_date ? moment(dt.bill_date).format('DD/MM/YYYY') : '-',
      ]);
    });

    return parsed;
  };

  const parsedPpdbBills = (data) => {
    const parsed = [
      ['No', 'Cicilan ke-', 'Tanggal Penagihan', 'Status', 'Nominal Tagihan'],
    ];

    data.forEach((dt, idx) => {
      const total = dt.total_amount - (dt.paid_amount || 0);
      const isLunas = total === 0;
      const status = !dt.active_status
        ? 'TIDAK AKTIF'
        : isLunas
        ? 'LUNAS'
        : total;

      parsed.push([
        idx + 1,
        dt.instalment_repition,
        moment(dt.instalment_date).format('DD/MM/YYYY'),
        status,
        dt.total_amount,
      ]);
    });

    return parsed;
  };

  const parsedGeneralBills = (data) => {
    const parsed = [
      ['No', 'Nama Tagihan', 'Tanggal Tagihan', 'Status', 'Nominal Tagihan'],
    ];

    data.forEach((dt, idx) => {
      const total = dt.total_amount - (dt.paid_amount || 0);
      const isLunas = total === 0;
      const status = !dt.active_status
        ? 'TIDAK AKTIF'
        : isLunas
        ? 'LUNAS'
        : total;

      parsed.push([
        idx + 1,
        dt.name,
        moment(dt.date).format('DD/MM/YYYY'),
        status,
        dt.total_amount,
      ]);
    });

    return parsed;
  };

  const parsedRefund = (data) => {
    const parsed = [
      [
        'No',
        'Nama Tagihan',
        'Tanggal Refund',
        'Jumlah Refund',
        'Alasan Refund',
      ],
    ];

    data.forEach((dt, idx) => {
      const total = dt.total_amount - (dt.paid_amount || 0);
      const isLunas = total === 0;
      const status = !dt.active_status
        ? 'TIDAK AKTIF'
        : isLunas
        ? 'LUNAS'
        : total;

      parsed.push([
        idx + 1,
        dt.name,
        moment(dt.date).format('DD/MM/YYYY'),
        dt.total_amount,
        dt.notes,
      ]);
    });

    return parsed;
  };

  const parsedMutasi = (data) => {
    const parsed = [
      [
        'No',
        'Tanggal',
        'Deskripsi',
        'Nominal',
        'Tagihan',
        'Dana Masuk',
        'Tabungan',
      ],
    ];

    data.forEach((dt, idx) => {
      parsed.push([
        idx + 1,
        moment(dt.date).format('DD/MM/YYYY'),
        dt.description,
        dt.amount,
        dt.balance,
        dt.fund,
        dt.tabungan,
      ]);
    });

    return parsed;
  };

  const parsedHistoryVA = (data) => {
    const parsed = [['No', 'Tanggal', 'Nominal', 'Notes', 'Result']];

    data.forEach((dt, idx) => {
      parsed.push([
        idx + 1,
        moment(dt.date).format('DD/MM/YYYY'),
        dt.amount,
        dt.notes,
        dt.result,
      ]);
    });

    return parsed;
  };

  const handleDownloadData = async (id) => {
    try {
      setLoadingDownload(true);
      const promises = await Promise.all([
        getTabAllBills(id),
        studentTabSPP(id, schoolYearId),
        studentTab(id, 'ppdb-instalment-list'),
        studentTab(id, 'general-bill-list'),
        studentTab(id, 'refund-list'),
        studentTab(id, 'mutasi'),
        studentTab(id, 'history-va', schoolYearId),
      ]);
      setLoadingDownload(false);
      const allBills = parsedAllBills(promises[0].allData);
      const sppBills = parsedSppBills(promises[1].sppBills);
      const ppdbbills = parsedPpdbBills(promises[2].ppdbInstalments);
      const generalBills = parsedGeneralBills(promises[3].generalBills);
      const refundList = parsedRefund(promises[4].refundList);
      const mutasiList = parsedMutasi(promises[5].allData);
      const historyUploadVA = parsedHistoryVA(promises[6].historyUploadVA);

      const multipleSheets = [
        {
          data: allBills,
          sheetName: 'Tunggakan',
        },
        {
          data: sppBills,
          sheetName: 'Uang Sekolah',
        },
        {
          data: ppdbbills,
          sheetName: 'Cicilan Uang Pangkal',
        },
        {
          data: generalBills,
          sheetName: 'Tagihan Lainnya',
        },
        {
          data: refundList,
          sheetName: 'Refund',
        },
        {
          data: mutasiList,
          sheetName: 'Mutasi',
        },
        {
          data: historyUploadVA,
          sheetName: 'Riwayat VA',
        },
      ];

      exportExcel(multipleSheets, 'Laporan Detail Murid', true);

      console.log(promises, 'this is result promises download');
    } catch (err) {
      setLoadingDownload(false);
      console.log(err.response);
    }
  };

  const firstGuardianData = useMemo(() => {
    return dataWali ? dataWali.find((d) => Number(d.index) === 1) : null;
  }, [dataWali]);

  // no email guardian update

  const noEmailGuardian = useMemo(() => {
    return firstGuardianData
      ? firstGuardianData?.phone_number
        ? true
        : false
      : true;
  }, [firstGuardianData]);

  const ButtonDownloadGroup = (
    <Menu>
      <Menu.Item onClick={() => handleDownloadData(id)}>
        Download Data
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setOpenModal({
            state: true,
            type: 'lunas',
          });
        }}
      >
        Download Rekap Tagihan Lunas
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setOpenModal({
            state: true,
            type: 'belum-lunas',
          });
        }}
      >
        Download Rekap Tagihan Belum Lunas
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Space size='middle'>
            <Button
              icon={<FundProjectionScreenOutlined />}
              onClick={() =>
                history.push({
                  pathname: `/daftar-murid/detail/${id}/riwayat-kelas`,
                  search: queryString.stringify({
                    unit: selectedUnit?.name,
                  }),
                })
              }
            >
              Riwayat Kelas
            </Button>
            <Dropdown overlay={ButtonDownloadGroup}>
              <Button icon={<DownloadOutlined />} loading={loadingDownload}>
                Download
              </Button>
            </Dropdown>
            <Dropdown overlay={editMenu} overlayStyle={{ width: '16rem' }}>
              <Button type='default' icon={<EditOutlined />}>
                Edit Info
              </Button>
            </Dropdown>
          </Space>
        </div>
      </PageHeader>

      {!noEmailGuardian ? (
        <Alert
          type='error'
          message={
            <div style={{ fontWeight: 'bold' }}>
              Nomor Whatsapp wali murid belum ada &nbsp;
              <Link
                style={{ color: '#1890FF', textDecoration: 'underline' }}
                onClick={() => {
                  setEditFormType(2);
                  setOpenDrawer(true);
                }}
              >
                Update Nomor
              </Link>
            </div>
          }
          showIcon
          icon={<ExclamationCircleOutlined />}
        />
      ) : null}

      {!loading && dataMurid ? (
        <>
          <Row gutter={[12, 12]} style={{ margin: '2rem 0' }} align='top'>
            <Col span='15' style={{ height: '100%' }}>
              <Card
                style={{
                  backgroundColor: '#FFF8E4',
                  borderColor: '#FFD698',
                  height: '100%',
                  padding: 0,
                }}
              >
                <Row gutter={[24, 0]}>
                  <Col span='10' style={{ color: 'red' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                      }}
                    >
                      <header>
                        <h2
                          style={{
                            color: themeColor.primary,
                            margin: 0,
                            fontSize: '2rem',
                          }}
                        >
                          {dataMurid?.name} (
                          {dataMurid.gender === 1 ? 'L' : 'P'})
                        </h2>
                        <span style={{ fontSize: '1.5rem', color: '#292929' }}>
                          NIS: {dataMurid.nik || '-'}
                        </span>
                        <p style={{ fontSize: '1.5rem', color: '#292929' }}>
                          Password: {dataMurid.password || '-'}
                        </p>
                        <a
                          style={{ fontSize: '1.2rem' }}
                          href={`https://parents.skketapang.org/auth/login?virtual_account=${dataMurid?.virtual_account}&password=${dataMurid?.password}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Link Parents:{' '}
                          {`https://parents.skketapang.org/auth/login?virtual_account=${dataMurid?.virtual_account}&password=${dataMurid?.password}`}
                        </a>
                      </header>
                      <Space>
                        <Tag color={statusMurid[dataMurid?.status || 0].color}>
                          {statusMurid[dataMurid?.status || 0].status}
                        </Tag>
                        {dataMurid.type === 2 ? (
                          <Tag color='orange'>
                            <StarFilled /> &nbsp;ASAK
                          </Tag>
                        ) : (
                          <Tag color='blue'>Murid Standar</Tag>
                        )}
                      </Space>
                    </div>
                  </Col>
                  <Col span='2'>
                    <Divider type='vertical' style={{ height: '100%' }} />
                  </Col>
                  <Col
                    span='12'
                    style={{ height: '100%', fontSize: '1.35rem' }}
                  >
                    <Row gutter={[8, 8]}>
                      <Col
                        span='12'
                        style={{ color: '#262626', fontWeight: 400 }}
                      >
                        <p style={{ marginBottom: '1.15rem' }}>Kelas</p>
                        <p style={{ marginBottom: '1.15rem' }}>TTL</p>
                        <p style={{ marginBottom: '1.15rem' }}>Nomor VA</p>
                        <p style={{ marginBottom: '1.15rem' }}>Nomor VA Lama</p>
                        <p style={{ marginBottom: '1.15rem' }}>Uang Sekolah</p>
                        <p style={{ margin: 0 }}>Dana Bantuan Siswa</p>
                      </Col>
                      <Col span='12'>
                        <p style={{ marginBottom: '1.15rem' }}>{`${
                          dataMurid.grade
                            ? dataMurid.grade.name
                            : 'Murid Tanpa Kelas'
                        }`}</p>
                        <p
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            marginBottom: '1.15rem',
                          }}
                        >
                          {dataMurid?.dob && dataMurid?.birth_location
                            ? `${dataMurid?.birth_location}, ${moment(
                                dataMurid?.dob
                              ).format('DD MMMM YYYY')}`
                            : '-'}
                        </p>
                        <p style={{ marginBottom: '1.15rem' }}>
                          {dataMurid.virtual_account}
                        </p>
                        <p style={{ marginBottom: '1.15rem' }}>
                          {dataMurid.old_virtual_account || '-'}
                        </p>
                        <p style={{ marginBottom: '1.15rem' }}>
                          Rp{(dataMurid.spp_amount || 0).toLocaleString()}
                        </p>
                        <p style={{ margin: 0 }}>
                          {dataMurid?.dana_bantuan_siswa || 0}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            {dataWali && dataWali.length ? (
              <Col span='9' style={{ height: '200px' }}>
                <Card
                  style={{
                    height: '100%',
                    backgroundColor: '#ffffff',
                    borderColor: '#b3b3b3',
                  }}
                >
                  <StyledCarousel
                    dotPosition='right'
                    style={{ height: '100%' }}
                    dots={{ style: { color: 'blue' } }}
                  >
                    {[firstGuardianData]?.map((wali) => {
                      let status;

                      switch (wali.status) {
                        case 1:
                          status = 'Ibu Kandung';
                          break;
                        case 2:
                          status = 'Ayah Kandung';
                          break;
                        case 3:
                          status = 'Kakek';
                          break;
                        case 4:
                          status = 'Nenek';
                          break;
                        case 5:
                          status = 'Paman';
                          break;
                        case 6:
                          status = 'Bibi';
                          break;
                        case 7:
                          status = 'Lainnya';
                          break;

                        default:
                          break;
                      }

                      return (
                        <div
                          style={{
                            color: '#fff',
                            textAlign: 'center',
                          }}
                        >
                          <h2>Wali Murid</h2>
                          <p>{wali?.name || '-'}</p>
                          <p>{`(+${wali?.phone_code || '62'}) - ${
                            wali?.phone_number || '-'
                          }`}</p>
                          <p>{status}</p>
                        </div>
                      );
                    })}
                  </StyledCarousel>
                </Card>
              </Col>
            ) : null}
          </Row>
          <div style={{ height: '2rem' }}></div>
          <PageHeader title='Riwayat Tagihan dan Pembayaran' />
          <Row gutter={[18, 8]}>
            <Col span='8'>
              <InfoCard
                sideColor='#99C5AD'
                title='Dana Masuk'
                content={`Rp${(dataMurid.funds || 0).toLocaleString()}`}
                action={{
                  name: 'Alokasi',
                  function: () =>
                    setAllocationDrawer({
                      type: 'fund',
                      id: id,
                    }),
                }}
              />
            </Col>
            <Col span='8'>
              <InfoCard
                sideColor='#A7D7FF'
                title='Total Tabungan'
                content={`Rp${(dataMurid.savings || 0).toLocaleString()}`}
                action={{
                  name: 'Alokasi',
                  function: () =>
                    setAllocationDrawer({
                      type: 'saving',
                      id: id,
                    }),
                }}
              />
            </Col>
            <Col span='8'>
              <InfoCard
                sideColor='#FFA6A7'
                title='Total Tunggakan'
                content={`Rp${(dataMurid.totalDebt || 0).toLocaleString()}`}
              />
            </Col>
          </Row>

          <section
            style={{
              margin: '2rem 0',
              width: '100%',
              border: '1px solid #bfbfbf',
              padding: '1rem 2rem',
            }}
          >
            <Tabs
              defaultActiveKey={tabs}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <TabPane tab='Tunggakan' key='tunggakan'>
                <DetailMuridTunggakanTable
                  studentId={id}
                  unitId={unitId}
                  refetchData={fetchData}
                />
              </TabPane>
              <TabPane tab='Uang Sekolah' key='spp'>
                <DetailMuridSPPTable
                  studentId={id}
                  unitId={unitId}
                  refetchData={fetchData}
                />
              </TabPane>
              <TabPane tab='Cicilan Uang Pangkal' key='ppdb'>
                <DetailMuridPPDBTable
                  studentId={id}
                  unitId={unitId}
                  refetchData={fetchData}
                />
              </TabPane>
              <TabPane tab='Tagihan Lainnya' key='general'>
                <DetailMuridGeneralTable
                  studentId={id}
                  unitId={unitId}
                  refetchData={fetchData}
                />
              </TabPane>
              <TabPane tab='Refund' key='refund'>
                <DetailMuridRefundTable studentId={id} unitId={unitId} />
              </TabPane>
              <TabPane tab='Mutasi' key='mutasi'>
                <DetailMuridMutasiTable studentId={id} unitId={unitId} />
              </TabPane>
              <TabPane tab='Riwayat VA' key='history-va'>
                <DetailMuridRiwayatVA studentId={id} unitId={unitId} />
              </TabPane>
              <TabPane tab='Pembayaran' key='payment-list'>
                <DetailMuridPaymentList studentId={id} unitId={unitId} />
              </TabPane>
              <TabPane tab='Apresiasi' key='apresiasi-list'>
                <DetailMuridApresiasi studentId={id} unitId={unitId} />
              </TabPane>
            </Tabs>
          </section>
        </>
      ) : (
        <Loader text='Fetching Details ...' />
      )}

      <Drawer.EditDetailMurid
        visible={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        type={editFormType}
        dataMurid={dataMurid}
        dataWali={dataWali}
        handleSubmit={handleSubmitEdit}
        loadingState={loadingSubmit}
      />

      <Drawer.DetailDanaMasuk
        visible={allocationDrawer.type === 'fund'}
        onCancel={() => {
          setAllocationDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ id: { id }, unitId: unitId, studentId: '' }}
        content={2}
        refetchData={fetchData}
      />

      <Drawer.DetailTabungan
        visible={allocationDrawer.type === 'saving'}
        onCancel={() => {
          setAllocationDrawer({
            type: '',
            id: '',
          });
        }}
        value={{ unitId: unitId, studentId: { id: allocationDrawer.id } }}
        content={2}
        refetchData={fetchData}
      />

      <Modal.PilihTanggalCutoff
        visible={openModal.state}
        type={openModal.type}
        id={id}
        onCancel={() => {
          setOpenModal({
            state: false,
            type: '',
          });
        }}
      />
    </>
  );
};

export default DetailMurid;

import { Button, Dropdown, Empty, Menu, message, Space, Table } from 'antd';
import { PageHeader, Modal } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import {
  getListTahunAjaran,
  getLaporanBulananAllUnit,
  getLaporanBulananUnit,
  updateLaporanBulanan,
  downloadLaporanDashboard,
  getLaporanBulananSPP,
} from 'utils/fetch';
import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { Loader } from 'components/atoms';
import { themeColor } from 'lib/theme';

const TableRow = (props) => {
  const { columns, data, ...rest } = props;

  return (
    <section
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1.6rem',
      }}
    >
      {columns.map((col, idx) => {
        const isLast = idx === columns.length - 1;

        return (
          <div
            key={col.key}
            style={{
              width: '200px',
              textAlign: isLast ? 'right' : 'left',
              borderBottom: '1px solid #f0f0f0',
              padding: '2rem 0',
            }}
          >
            {col.render(data[col.dataIndex])}
          </div>
        );
      })}
    </section>
  );
};

const LaporanBulanan = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    state: { activeSchoolYear, unitData, listTahunAjaran, userData },
  } = useStore();
  const [selectedYear, setSelectedYear] = useState({
    name: '',
    _id: '',
  });
  const [selectedUnit, setSelectedUnit] = useState(userData?.unit || '');
  const [data, setData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState('');
  const [otherData, setOtherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [filter, setFilter] = useState({ units: [], school_year: '' });
  const [filterModal, setFilterModal] = useState(false);
  const [userUnits, setUserUnits] = useState([]);
  const schoolYearId = activeSchoolYear?._id;
  const arrayUnit = useMemo(
    () => Object.keys(unitData).map((key) => unitData[key]),
    [unitData]
  );

  const baseHeader = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Kelas',
      dataIndex: 'grade',
      key: 'grade',
      width: 150,
      fixed: 'left',
    },
  ];

  const [tableHeader, setTableHeader] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (arrayUnit.length && userData && activeSchoolYear?._id) {
      const defaultUnits = userData?.units?.length
        ? userData?.units?.map((unit) => unit?._id)
        : arrayUnit.map((unit) => unit?._id);

      setFilter({ units: defaultUnits, school_year: activeSchoolYear._id });
      setUserUnits(defaultUnits);
      setSelectedYear({
        name: activeSchoolYear.name,
        _id: activeSchoolYear._id,
      });
    } else {
      setFilter({
        units: [],
        school_year: '',
      });
    }
  }, [arrayUnit, userData, activeSchoolYear]);

  useEffect(() => {
    if (userData?.unit) {
      setSelectedUnit(userData.unit);
    }
  }, [userData]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/laporan-bulanan',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>
              Dashboard Laporan SPP Bulanan
            </p>
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['5']);
  }, [lastUpdated]);

  useEffect(() => {
    fetchDataTahunAjaran();
  }, []);

  const fetchDataAllUnit = (query) => {
    setLoading(true);
    getLaporanBulananSPP(query)
      .then((res) => {
        const { value } = res;
        const headerMonth = value?.months?.map((mo) => {
          return {
            title: moment(mo.month).format('MMMM'),
            children: [
              {
                title: 'Total',
                dataIndex: moment(mo.month).format('MMMM'),
                key: moment(mo.month).format('MMMM'),
                render: (data) => {
                  const isEqual = data?.paidAmount === data?.totalAmount;
                  return (
                    <span
                      style={{
                        color: isEqual ? 'green' : 'red',
                        fontWeight: isEqual && 600,
                      }}
                    >
                      {'Rp' +
                        Number(data?.totalAmount || 0).toLocaleString('en')}
                    </span>
                  );
                },
              },
              {
                title: 'Terbayar',
                dataIndex: moment(mo.month).format('MMMM'),
                key: moment(mo.month).format('MMMM'),
                render: (data) => {
                  const isEqual = data?.paidAmount === data?.totalAmount;
                  return (
                    <span
                      style={{
                        color: isEqual ? 'green' : 'red',
                        fontWeight: isEqual && 600,
                      }}
                    >
                      {'Rp' +
                        Number(data?.paidAmount || 0).toLocaleString('en')}
                    </span>
                  );
                },
              },
            ],
          };
        });

        const tableData = [...value?.listGrades, 'Total']?.map((grd, idx) => {
          const monthData = {};

          value?.months?.forEach((mo) => {
            const foundedGrade = mo.groupingGrades?.find(
              (el) => el.grade === grd
            );

            const month = moment(mo.month).format('MMMM');

            if (foundedGrade) {
              monthData[month] = {
                ...foundedGrade,
              };
            } else {
              monthData[month] = {
                totalAmount: mo.totalAmount,
                paidAmount: mo.paidAmount,
              };
            }
          });

          return {
            no: grd === 'Total' ? grd : idx + 1,
            grade: grd === 'Total' ? '' : grd,
            ...monthData,
          };
        });

        setDataTable(tableData);
        setData(value);
        setTableHeader([...baseHeader, ...headerMonth]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    console.log({ filter });

    if (filter?.units.length && filter?.school_year) {
      fetchDataAllUnit(filter);
    }
  }, [filter]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const MenuTA = ({ onClick }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          maxHeight: '25rem',
          overflowY: 'scroll',
          paddingRight: '1rem',
        }}
      >
        {listTahunAjaran.map((year) => (
          <Menu.Item key={year._id} onClick={() => onClick(year)}>
            {year.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const keys = otherData ? Object.keys(otherData) : [];

  const parsedResult = (arrayData = [], total) => {
    const monthHeader = [];
    const amountMonth = [];
    const keysMonth = [];

    data?.months?.forEach((mo) => {
      keysMonth.push(moment(mo.month).format('MMMM'));
      monthHeader.push(moment(mo.month).format('MMMM'));
      monthHeader.push('');
      amountMonth.push('Total');
      amountMonth.push('Terbayar');
    });

    const parsed = [
      ['', '', ...monthHeader],
      ['No', 'Kelas', ...amountMonth],
    ];

    arrayData.forEach((dt, idx) => {
      const array = [dt.no, dt.grade || ''];

      keysMonth.forEach((key) => {
        array.push(dt[key].totalAmount);
        array.push(dt[key].paidAmount);
      });

      parsed.push(array);
    });

    return parsed;
  };

  const parsedResultMonth = (data = []) => {
    const parsed = [
      [
        'No',
        'Bulan',
        'Nama Murid',
        'Kelas',
        'Total Tagihan',
        'Sudah Bayar',
        'Sisa Tagihan',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        moment(dt.month).format('MMMM YYYY'),
        dt.student?.name || '-',
        dt.student?.grade?.name || 0,
        dt.total_amount || 0,
        dt.paid_amount || 0,
        dt?.total_amount - dt?.paid_amount || 0,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const downloadLaporan = async () => {
    try {
      const parsedDataAll = parsedResult(dataTable);

      const multipleData = [
        { data: parsedDataAll, sheetName: 'Semua Laporan SPP Bulanan' },
      ];

      data?.months?.forEach((mo) => {
        multipleData.push({
          data: parsedResultMonth(mo.sppBills),
          sheetName: moment(mo.month).format('MMMM'),
        });
      });

      exportExcel(multipleData, 'Laporan SPP Bulanan', true);
    } catch (err) {
      console.log(err);
      message.error(err?.data?.m || 'Gagal download laporan bulanan');
    }
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Button onClick={() => setFilterModal(true)}>Pilih Unit</Button>
          <Dropdown
            overlay={
              <MenuTA
                onClick={(v) => {
                  setSelectedYear({
                    name: v.name,
                    _id: v._id,
                  });
                  setFilter((p) => ({ ...p, school_year: v._id }));
                }}
              />
            }
          >
            <Button>{selectedYear?.name || '-'}</Button>
          </Dropdown>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadLaporan}
            loading={loadingDownload}
          >
            Download Laporan
          </Button>
        </Space>
      </PageHeader>

      <section>
        <Table
          columns={tableHeader}
          scroll={{ x: 4000, y: 600 }}
          dataSource={dataTable}
          pagination={{ pageSize: 100 }}
          loading={loading}
        />
      </section>

      <Modal.FilterUnits
        visible={filterModal}
        onClose={() => setFilterModal(false)}
        onSelectedFilter={(values) => setFilter((p) => ({ ...p, ...values }))}
        units={filter.units}
        userUnits={userUnits}
      />
    </>
  );
};

export default LaporanBulanan;

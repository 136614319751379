import {
  EditOutlined,
  DeleteOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { Drawer } from 'components/shared';
import moment from 'moment';
import { useStore } from 'store';
import { deleteApresiasi, fetchTahunAjaran, studentTab } from 'utils/fetch';

const { Dropdown, Button, Table, Menu, Tag, Space } = require('antd');
const { useState, useEffect } = require('react');

const DetailMuridRefund = ({ studentId, unitId }) => {
  const {
    state: { activeSchoolYear },
  } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    content: 0,
    id: '',
    data: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    studentTab(studentId, 'appreciation-list')
      .then((value) => {
        console.log(value, 'appreciation-list');
        setData(value.appreciations);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => setLoading(false));
  };

  const _handleDeleteApresiasi = (id) => {
    deleteApresiasi(unitId, id)
      .then((res) => {
        console.log(res);
        fetchData();
      })
      .catch((err) => {
        console.log(err, 'error apresiasi delete');
      });
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 80,
      render: (data, _, i) => <span>{i + 1}</span>,
    },
    {
      title: 'Nominal Apresiasi',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (data) => <span>{`Rp${(data || 0).toLocaleString()}`}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'bill_name',
      key: 'bill_name',
      width: 250,
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      render: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Catatan',
      dataIndex: 'notes',
      key: 'notes',
      render: (data) => <span>{data}</span>,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (id, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setOpenDrawer({
                state: record.bill_type,
                id: record.bill_id,
                content: 'Apresiasi',
                student: record.student._id,
                data: record,
              });
            }}
          >
            Edit
          </Button>

          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => _handleDeleteApresiasi(id)}
          >
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <article>
        <Table
          columns={tableColumns}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
          }}
          // scroll={{ x: 1500 }}
          loading={loading}
        />
      </article>

      <Drawer.DetailTagihanSPP
        visible={openDrawer.state === 'SPP'}
        onCancel={() => {
          setOpenDrawer({
            content: 0,
            id: '',
            state: '',
            data: null,
          });
        }}
        value={{
          id: openDrawer.id,
          unitId: unitId,
          studentId: openDrawer?.student,
          data: openDrawer?.data,
        }}
        content={openDrawer.content}
        refetchData={fetchData}
      />

      <Drawer.DetailTagihanPPDB
        visible={openDrawer?.state === 'PPDB'}
        onCancel={() => {
          setOpenDrawer({
            content: 0,
            id: '',
            state: '',
            data: null,
          });
        }}
        value={{ id: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
        apresiasiData={openDrawer?.data}
      />

      <Drawer.DetailTagihanLainnya
        visible={openDrawer?.state === 'General'}
        onCancel={() => {
          setOpenDrawer({
            content: 0,
            id: '',
            state: '',
            data: null,
          });
        }}
        value={{ id: openDrawer.id, unitId: unitId }}
        refetchData={fetchData}
        apresiasiData={openDrawer?.data}
      />
    </>
  );
};

export default DetailMuridRefund;

export const setUserToken = (value) => {
  localStorage.setItem('skk-access-token', value);
};

export const getUserToken = () => {
  const serialize = localStorage.getItem('skk-access-token');
  return serialize || '';
};

export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

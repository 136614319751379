import { CardBase, CustomButton, Loader } from 'components/atoms';
import { Drawer, Modal, PageHeader } from 'components/shared';
import React, { useEffect, useMemo, useState } from 'react';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Menu,
  Row,
  Space,
  Spin,
  Progress,
  message,
  Popover,
} from 'antd';
import { useStore } from 'store';
import {
  downloadVADashboard,
  fetchTahunAjaran,
  getDashboardAllDanaMasuk,
  getDashboardAllTabungan,
  getDashboardAllTotalTunggakan,
  getDashboardDataAllUnit,
  getDashboardDataUnit,
  getMainDashboardData,
  updateDashboardData,
} from 'utils/fetch';
import { useHistory } from 'react-router-dom';
import IC_PENDAPATAN from 'assets/icons/pendapatan.svg';
import { ProgressContainer, ProgressTitle } from './style';

import IC_ASAK from 'assets/icons/ASAK.svg';
import IC_TUNGGAKAN from 'assets/icons/tunggakan.svg';
import IC_DANA_MASUK from 'assets/icons/dana-masuk.svg';
import IC_REFUND from 'assets/icons/Refund.svg';
import IC_PEMBAYARAN from 'assets/icons/Pembayaran.svg';
import moment from 'moment';
import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';
import exportExcel from 'utils/helpers/xlsx';
import { getNotesData } from 'utils/helpers/common';

export const CardDashboard = ({
  title,
  rincian = 0,
  amount = 0,
  debt = false,
  selectMonth,
  selectedMonth = '-',
  loading,
  icon = IC_PENDAPATAN,
  disableAction,
  fullHeight,
  noFooter,
  openModal = () => {},
}) => {
  const {
    state: { activeSchoolYear },
  } = useStore();

  return (
    <Card
      style={{ height: fullHeight || noFooter ? '100%' : 'auto' }}
      bodyStyle={{ height: fullHeight ? '78%' : 'auto' }}
      actions={
        !noFooter && [
          <div
            style={{
              maxHeight: '4.7rem',
              margin: 0,
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={openModal}
          >
            Lihat Rincian ({rincian})
          </div>,
        ]
      }
    >
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <img src={icon} />
        {selectMonth ? (
          // <Dropdown
          //   disabled={disableAction}
          //   overlay={<MenuBulan onClick={(v) => selectMonth(v)} />}
          // >
          <Button disabled={disableAction}>{selectedMonth}</Button>
        ) : // </Dropdown>
        null}
      </header>
      {!loading ? (
        <section style={{ marginTop: '2rem' }}>
          <p style={{ margin: 0 }}>{title}</p>
          <h2
            style={{ color: debt ? '#FF4D4F' : '#0b0b0b', fontWeight: '700' }}
          >
            Rp{(amount || 0).toLocaleString()}
          </h2>
        </section>
      ) : (
        <Loader text="Wait for details ..." />
      )}
    </Card>
  );
};

const DashboardKeseluruhan = () => {
  const {
    state: { activeSchoolYear, unitData, userData },
    handleBreadcrumb,
    handleSiderActive,
  } = useStore();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [studentCondition, setStudentCondition] = useState(null);
  const [lastUpdated, setLastUpdated] = useState('');
  const [asakMonth, setAsakMonth] = useState({
    name: '',
    value: '',
  });
  const [refundMonth, setRefundMonth] = useState({
    name: '',
    value: '',
  });
  const [listTahunAjaran, setListTahunAjaran] = useState([]);
  const [selectedTahun, setSelectedTahun] = useState(null);
  const [dashboardUnit, setDashboardUnit] = useState([]);
  const [rincianModal, setRincianModal] = useState({
    state: false,
    table: '',
    data: null,
  });
  const [downloadModal, setDownloadModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const arrayUnit = useMemo(
    () => Object.keys(unitData).map((key) => unitData[key]),
    [unitData]
  );
  const history = useHistory();
  const [filter, setFilter] = useState({
    units: [],
    cutOffDate: '',
    school_year: '',
  });
  const [totalTunggakan, setTotalTunggakan] = useState(null);
  const [totalDanaMasuk, setTotalDanaMasuk] = useState(null);
  const [totalTabungan, setTotalTabungan] = useState(null);

  useEffect(() => {
    if (arrayUnit.length && userData) {
      const defaultUnits = userData?.units?.length
        ? userData?.units?.map((unit) => unit?._id)
        : arrayUnit.map((unit) => unit?._id);
      const defaultCutOff = moment().format('MM-DD-YYYY');

      setFilter({ units: defaultUnits, cutOffDate: defaultCutOff });
      setDashboardUnit(defaultUnits);
    } else {
      setFilter({
        units: [],
        cutoffDate: '',
      });
    }
  }, [arrayUnit, userData]);

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setSelectedTahun(activeSchoolYear);
      setFilter((p) => ({ ...p, school_year: activeSchoolYear?._id }));
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    fetchListTahun();
  }, []);

  useEffect(() => {
    if (filter.units?.length && filter.cutOffDate) {
      _fetchDataKeseluruhan(filter);
    }
  }, [filter]);

  const _fetchDataKeseluruhan = async (filter) => {
    try {
      setLoadingUpdate(true);
      const [responseTotalTunggakan, responseDanaMasuk, responseTabungan] =
        await Promise.all([
          await getDashboardAllTotalTunggakan(filter),
          await getDashboardAllDanaMasuk(filter),
          await getDashboardAllTabungan(filter),
        ]);

      setTotalTunggakan(responseTotalTunggakan?.value);
      setTotalDanaMasuk(responseDanaMasuk?.value);
      setTotalTabungan(responseTabungan?.value);

      setFilterModal(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUpdate(false);
    }
  };

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/dashboard/semua',
        node: (
          <div>
            <p style={{ margin: 0, lineHeight: 0.75 }}>Dashboard Keseluruhan</p>
            {/* <span style={{ fontSize: '1.15rem', color: '#262626', margin: 0 }}>
              Update terakhir:{' '}
              {lastUpdated
                ? moment(lastUpdated).format('DD/MM/YYYY, hh:mm A')
                : '-'}
            </span> */}
          </div>
        ),
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['1']);
  }, [lastUpdated]);

  const fetchListTahun = () => {
    fetchTahunAjaran()
      .then((res) => {
        console.log(res);
        setListTahunAjaran(res.schoolYears);
      })
      .catch((err) => console.log(err, 'error list tahun'));
  };

  const MenuDownload = () => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Menu.Item key={'all'} onClick={downloadLaporan}>
          Laporan Keseluruhan
        </Menu.Item>

        <Menu.Item key={'va'} onClick={() => setDownloadModal(true)}>
          Rekapan Tagihan
        </Menu.Item>
      </Menu>
    );
  };

  const parsedResultTunggakan = (data = []) => {
    const parsed = [
      [
        'No',
        'Virtual Account',
        'Nama Murid',
        'Tunggakan Bulan Lalu',
        'Pendapatan Bulan Berjalan',
        'Penerimaan Bulan Berjalan',
        'Tunggakan',
        'Kelas',
        'Unit',
        'Uang Sekolah',
        'Uang Pangkal',
        'Kegiatan',
        'Denda',
        'Lainnya',
        'Keterangan',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.virtual_account,
        dt.name,
        dt.billLastMonth,
        dt.incomeThisMonth,
        dt.billThisMonth,
        dt.totalAmount,
        dt.grade?.name || '-',
        dt.unit?.name || '-',
        dt.sppAmount,
        dt.ppdbInstalmentAmount,
        dt.kegiatanAmount,
        dt.dendaAmount,
        dt.generalBillAmount,
        getNotesData(dt),
      ];

      parsed.push(array);
    });

    return parsed;
  };
  const parsedResultDanaBelumMasuk = (data = []) => {
    const parsed = [['No', 'Nama Murid', 'Total Dana Masuk', 'Kelas', 'Unit']];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.funds,
        dt.grade?.name || ' -',
        dt.unit?.name || '-',
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const parsedResultTabunganBelumMasuk = (data = []) => {
    const parsed = [['No', 'Nama Murid', 'Total Tabungan', 'Kelas', 'Unit']];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.name,
        dt.savings,
        dt.grade?.name || '-',
        dt.unit?.name || '-',
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const parsedResultSPPDebt = (data = []) => {
    const parsed = [
      ['No', 'Nama Murid', 'Tunggakan', 'Bulan', 'Kelas', 'Unit'],
    ];

    data.forEach((dt, idx) => {
      let array = [];

      if (dt?.sppBills?.length) {
        dt?.sppBills?.forEach((bill, i) => {
          array = [
            idx + 1 + i,
            dt.name,
            bill.total_amount - bill?.paid_amount,
            moment(bill.month).format('MMMM YYYY'),
            dt.grade?.name || '-',
            dt.unit?.name || '-',
          ];

          parsed.push(array);
        });
      } else {
        array = [
          idx + 1,
          dt.name,
          dt?.sppAmount,
          '-',
          dt.grade?.name || '-',
          dt.unit?.name || '-',
        ];

        parsed.push(array);
      }
    });

    return parsed;
  };

  const parsedResultPPDBDebt = (data = []) => {
    const parsed = [
      ['No', 'Nama Murid', 'Tunggakan', 'Jatuh Tempo', 'Kelas', 'Unit'],
    ];

    data.forEach((dt, idx) => {
      let array = [];

      if (dt?.ppdbInstalments?.length) {
        dt?.ppdbInstalments?.forEach((bill, i) => {
          array = [
            idx + 1 + i,
            dt.name,
            bill.total_amount - bill?.paid_amount,
            moment(bill.due_date).format('DD MMMM YYYY'),
            dt.grade?.name || '-',
            dt.unit?.name || '-',
          ];

          parsed.push(array);
        });
      } else {
        array = [
          idx + 1,
          dt.name,
          dt?.ppdbInstalmentAmount,
          '-',
          dt.grade?.name || '-',
          dt.unit?.name || '-',
        ];

        parsed.push(array);
      }
    });

    return parsed;
  };

  const parsedResultGeneralDebt = (data = []) => {
    const parsed = [
      ['No', 'Nama Murid', 'Tunggakan', 'Nama Tagihan', 'Kelas', 'Unit'],
    ];

    data.forEach((dt, idx) => {
      let array = [];

      if (dt?.generalBills?.length) {
        dt?.generalBills?.forEach((bill, i) => {
          array = [
            idx + 1 + i,
            dt.name,
            bill.total_amount - bill?.paid_amount,
            bill?.name || '-',
            dt.grade?.name || '-',
            dt.unit?.name || '-',
          ];

          parsed.push(array);
        });
      } else {
        array = [
          idx + 1,
          dt.name,
          dt?.generalBillAmount,
          '-',
          dt.grade?.name || '-',
          dt.unit?.name || '-',
        ];

        parsed.push(array);
      }
    });

    return parsed;
  };

  const downloadLaporan = () => {
    console.log({ totalDanaMasuk, totalTabungan, totalTunggakan });
    const parsedTunggakan = parsedResultTunggakan(totalTunggakan?.allDebt);
    const parsedDanaBelumMasuk = parsedResultDanaBelumMasuk(
      totalDanaMasuk?.fundsReport
    );
    const parsedTabunganBelumMasuk = parsedResultTabunganBelumMasuk(
      totalTabungan?.savingsReport
    );
    const parsedTunggakanUangSekolah = parsedResultSPPDebt(
      totalTunggakan?.sppDebt
    );
    const parsedTunggakanUangPangkal = parsedResultPPDBDebt(
      totalTunggakan?.ppdbDebt
    );
    const parsedTunggakanLainnya = parsedResultGeneralDebt(
      totalTunggakan?.generalBillDebt
    );
    const parsedData = [
      {
        data: parsedTunggakan,
        sheetName: 'Tunggakan Murid',
      },
      {
        data: parsedDanaBelumMasuk,
        sheetName: 'Dana Belum Alokasi',
      },
      {
        data: parsedTabunganBelumMasuk,
        sheetName: 'Tabungan Belum Alokasi',
      },
      {
        data: parsedTunggakanUangSekolah,
        sheetName: 'Tunggakan Uang Sekolah',
      },
      {
        data: parsedTunggakanUangPangkal,
        sheetName: 'Tunggakan Uang Pangkal',
      },
      {
        data: parsedTunggakanLainnya,
        sheetName: 'Tunggakan Tagihan Lainnya',
      },
    ];
    const fileName = `Laporan Keseluruhan ${moment().format(
      'DD MMMM YYYY HH:mm'
    )}`;
    exportExcel(parsedData, fileName, true);
  };

  const isNormalUser = userData.units !== undefined;

  const MenuTahun = ({ onClick, list = [{ name: '' }] }) => {
    return (
      <Menu
        style={{
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        {list.map((ta, idx) => {
          return (
            <Menu.Item key={idx} onClick={() => onClick(ta)}>
              {ta.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <>
      {totalTunggakan && !loadingUpdate ? (
        <>
          <PageHeader>
            <Space>
              {/* <Button
                icon={<FileExclamationOutlined />}
                onClick={handleUpdateData}
                loading={loadingUpdate}
              >
                Update Data
              </Button> */}
              <Button onClick={() => setFilterModal(true)}>Filter</Button>
              <Dropdown
                overlay={
                  <MenuTahun
                    onClick={(v) => {
                      setSelectedTahun(v);
                      console.log(v, 'value');
                      setFilter((p) => ({ ...p, school_year: v?._id }));
                    }}
                    list={listTahunAjaran}
                  />
                }
              >
                <Button>{selectedTahun?.name}</Button>
              </Dropdown>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                loading={loadingSubmit}
                disabled={studentCondition === 'error'}
                onClick={downloadLaporan}
              >
                Download Laporan
              </Button>
            </Space>
          </PageHeader>
          {studentCondition === 'error' ? (
            <Empty description="No data avalaible" />
          ) : (
            <section>
              <Row gutter={[24, 24]}>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Murid Menunggak"
                    debt={true}
                    amount={totalTunggakan?.allDebtAmount || 0}
                    rincian={totalTunggakan?.allDebt?.length || 0}
                    loading={totalTunggakan ? false : true}
                    icon={IC_TUNGGAKAN}
                    openModal={() => {
                      console.log(studentCondition);
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'tunggakan',
                        data: totalTunggakan?.allDebt || [],
                      });
                    }}
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Dana Masuk"
                    amount={totalDanaMasuk?.fundsAmount || 0}
                    rincian={totalDanaMasuk?.fundsReport?.length || 0}
                    loading={totalDanaMasuk ? false : true}
                    icon={IC_DANA_MASUK}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'dana-masuk',
                        data: totalDanaMasuk?.fundsReport || [],
                      })
                    }
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Tabungan"
                    amount={totalTabungan?.savingsAmount || 0}
                    rincian={totalTabungan?.savingsReport?.length || 0}
                    loading={totalTabungan ? false : true}
                    icon={IC_PEMBAYARAN}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'tabungan',
                        data: totalTabungan?.savingsReport || [],
                      })
                    }
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    title="Total Tunggakan Uang Sekolah"
                    amount={totalTunggakan?.sppAmount || 0}
                    rincian={totalTunggakan?.sppDebt?.length || 0}
                    loading={totalTunggakan ? false : true}
                    icon={IC_TUNGGAKAN}
                    debt={true}
                    openModal={() =>
                      setRincianModal({
                        state: true,
                        table: 'tunggakan-spp',
                        data: totalTunggakan?.sppDebt || [],
                      })
                    }
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Tunggakan Uang Pangkal"
                    amount={totalTunggakan?.ppdbInstalmentAmount || 0}
                    rincian={totalTunggakan?.ppdbDebt?.length || 0}
                    loading={totalTunggakan ? false : true}
                    icon={IC_TUNGGAKAN}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'tunggakan-ppdb',
                        data: totalTunggakan?.ppdbDebt || [],
                      })
                    }
                    disableAction={true}
                    debt={true}
                  />
                </Col>
                <Col span="8">
                  <CardDashboard
                    fullHeight
                    title="Total Tunggakan Lainnya"
                    amount={totalTunggakan?.generalBillAmount || 0}
                    rincian={totalTunggakan?.generalBillDebt?.length || 0}
                    loading={totalTunggakan ? false : true}
                    icon={IC_TUNGGAKAN}
                    openModal={() =>
                      setRincianModal({
                        ...rincianModal,
                        state: true,
                        table: 'tunggakan-general',
                        data: totalTunggakan?.generalBillDebt || [],
                      })
                    }
                    disableAction={true}
                    debt={true}
                  />
                </Col>
              </Row>
            </section>
          )}
        </>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      )}

      <Modal.RincianDashboard
        visible={rincianModal.state}
        table={rincianModal.table}
        data={rincianModal.data}
        asakMonth={asakMonth}
        unitName={dashboardUnit.name}
        onClose={() =>
          setRincianModal({
            state: false,
            table: '',
            data: null,
          })
        }
      />

      {downloadModal && (
        <Modal.DownloadRekapanTagihan
          visible={downloadModal}
          onClose={() => setDownloadModal(false)}
        />
      )}

      {filterModal && (
        <Modal.FilterDashboardKeseluruhan
          visible={filterModal}
          onClose={() => setFilterModal(false)}
          onSelectedFilter={(values) => setFilter(values)}
          units={filter.units}
          cutOffDate={filter.cutOffDate}
          dashboardUnit={dashboardUnit}
        />
      )}
    </>
  );
};

export default DashboardKeseluruhan;

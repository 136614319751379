import { Col, Input, Row, Table, Tag } from 'antd';
import { Card } from 'components/fragments';
import { Drawer, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import IC_EDIT from 'assets/icons/Edit.svg';
import { CustomButton } from 'components/atoms';
import { useHistory } from 'react-router-dom';
import { createNewUser, editUserData, fetchUserList } from 'utils/fetch';
import SearchSVG from 'assets/icons/magnifiying.svg';

const Pengguna = () => {
  const { handleBreadcrumb, handleSiderActive } = useStore();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: null,
  });
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/pengaturan/pengguna',
        node: <span>Pengaturan Pengguna</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['2']);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = () => {
    fetchUserList()
      .then((res) => {
        const numbered = res.users.map((el, idx) => ({ ...el, no: idx + 1 }));
        setData(numbered);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleSubmit = (data, id) => {
    setLoading(true);
    if (!id) {
      createNewUser(data)
        .then((res) => {
          fetchData();
          setOpenDrawer(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } else {
      editUserData(data, id)
        .then((res) => {
          fetchData();
          setOpenDrawer(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Pengguna',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (data) => (
        <span>{data == 1 ? 'Admin' : data == 2 ? 'User' : ''}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'active_status',
      key: 'active_status',
      render: (data) => {
        const color = data ? 'blue' : 'red';
        const text = data ? 'AKTIF' : 'TIDAK AKTIF';

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Unit',
      dataIndex: 'units',
      key: 'units',
      ellipsis: true,
      render: (data) => {
        const strings = data?.map((dt) => dt?.name);

        return data?.length ? (
          <ul>
            {strings?.map((str) => (
              <li key={str}>{str}</li>
            ))}
          </ul>
        ) : (
          '-'
        );
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id) => (
        <CustomButton.IconTextButton
          type="secondary"
          icon={IC_EDIT}
          onClick={() => {
            setIsEdit({
              state: true,
              data: data.find((el) => el._id === id),
            });
            setOpenDrawer(true);
          }}
        >
          Edit
        </CustomButton.IconTextButton>
      ),
    },
  ];

  return (
    <>
      <PageHeader>
        <CustomButton.IconTextButton
          type="primary"
          icon={IC_PLUSR}
          onClick={() => setOpenDrawer(true)}
        >
          Tambah Pengguna
        </CustomButton.IconTextButton>
      </PageHeader>
      <section style={{ width: '30rem', margin: '2rem 0' }}>
        <Input
          suffix={<img src={SearchSVG} />}
          placeholder="Cari nama pengguna"
          size="large"
          onChange={(e) => setSearch(e.target.value)}
        />
      </section>
      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data?.filter((item) => {
            if (search) {
              return item?.name?.toLowerCase().includes(search?.toLowerCase());
            }
            return item;
          })}
          columns={tableColumns}
          pagination={false}
          loading={loading}
        />
      </section>

      <Drawer.ManagePengguna
        visible={openDrawer}
        onCancel={() => {
          setOpenDrawer(false);
          setIsEdit({
            state: false,
            data: null,
          });
        }}
        isEdit={isEdit.state}
        values={isEdit.data}
        handleSubmit={handleSubmit}
        loadingState={loading}
      />
    </>
  );
};

export default Pengguna;

import {
  Button,
  Col,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { Drawer, InputWrapper, PageHeader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import {
  generateExcelTagihan,
  getBill,
  getListKelasId,
  getListTahunAjaran,
} from 'utils/fetch';
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { InfoCard } from 'components/fragments/card';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';
import { goToSearchQuery } from 'utils/helpers/common';

const TagihanSPP = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    handleListSchoolYear,
    handleListKelas,
    state: { activeSchoolYear, selectedUnit, listKelas, listTahunAjaran },
  } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState(null);
  const [filterPopover, setFilterPopover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    name: null,
    grade: null,
    status: null,
    month: null,
    nis: null,
    school_year: null,
  });
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [otherData, setOtherData] = useState(null);
  const [listBulan, setListBulan] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const {
    unit,
    limit = 10,
    page = 1,
    name,
    grade,
    status,
    month,
    nis,
    school_year,
  } = queryString.parse(location.search.replace('?', ''));
  const filterQuery = {
    unit,
    limit,
    page,
    name,
    grade,
    status,
    nis,
    month,
    school_year,
  };

  useEffect(() => {
    if (selectedYear) {
      const list = selectedYear
        ? Array.from({ length: 12 }, (_, i) => {
            const date = new Date(selectedYear.start_date);
            const next = new Date(date.setMonth(date.getMonth() + i));

            return {
              name: `${moment(next).format('MMMM YYYY')}`,
              value: moment(next).format('MM-DD-YYYY'),
            };
          })
        : [];
      setListBulan(list);
    } else {
      const list = activeSchoolYear
        ? Array.from({ length: 12 }, (_, i) => {
            const date = new Date(activeSchoolYear.start_date);
            const next = new Date(date.setMonth(date.getMonth() + i));

            return {
              name: `${moment(next).format('MMMM YYYY')}`,
              value: moment(next).format('MM-DD-YYYY'),
            };
          })
        : [];
      setListBulan(list);
    }
  }, [selectedYear]);

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/tagihan/spp',
        node: <span>Daftar Tagihan Uang Sekolah</span>,
      },
    ];
    handleBreadcrumb(newBreadcrumb);
    handleSiderActive(['3.1']);
  }, []);

  useEffect(() => {
    if (currentPage) {
      Object.keys(filterQuery).forEach((key) => {
        if (!filterQuery[key]) delete filterQuery[key];
      });
      history.push({
        search: queryString.stringify({
          ...filterQuery,
          page: currentPage,
        }),
      });
    }
  }, [currentPage]);

  useEffect(() => {
    fetchDataListKelas();
    // fetchDataTahunAjaran();
  }, []);

  useEffect(() => {
    if (selectedUnit) {
      fetchData();
    }

    return () => {};
  }, [page, limit, name, status, grade, month, school_year, nis, selectedUnit]);

  useEffect(() => {
    setFilter({
      name,
      status,
      grade,
      month,
      school_year,
      nis,
    });
  }, [name, status, grade, month, school_year, nis]);

  const fetchDataTahunAjaran = () => {
    getListTahunAjaran()
      .then((res) => {
        console.log(res);
        handleListSchoolYear(res.schoolsYears);
      })
      .catch((err) => console.error(err));
  };

  const fetchDataListKelas = () => {
    getListKelasId(unitId, schoolYearId)
      .then((res) => {
        console.log(res.grades);
        handleListKelas(res.grades);
      })
      .catch((err) => console.error(err));
  };

  const fetchData = () => {
    let query = `limit=${limit}&page=${page - 1}`;

    if (name) query += `&student_name=${name}`;
    if (status) query += `&student_status=${status}`;
    if (grade) query += `&student_grade=${grade}`;
    if (nis) query += `&student_nik=${nis}`;
    if (month) query += `&month=${month}`;
    if (school_year) query += `&school_year=${school_year}`;

    setLoading(true);

    getBill(unitId, 'spp', query)
      .then((res) => {
        const numbered = res.sppBills.map((el, idx) => ({
          ...el,
          no: idx + 1,
        }));
        console.log(numbered);
        setData(numbered);
        setTotalData(res.totalData);
        setLoading(false);
        setOtherData({
          outstandingBill: res.outstandingBill,
          outstandingStudent: res.outstandingStudent,
          totalOutstanding: res.totalOutstanding,
        });
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response, 'this is error fetch');
      });
  };

  const handleSubmitFilter = () => {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) delete filter[key];
    });

    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        unit,
        limit,
        page,
        ...filter,
      }),
    });
    setFilterPopover(false);
  };

  const filterMenu = (
    <div style={{ width: '30rem' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          type="text"
          name="name"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              name: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.name}
          placeholder="Nama Murid"
        />
        <Input
          type="text"
          name="nis"
          onChange={(e) => {
            const newFilter = {
              ...filter,
              nis: e.target.value,
            };
            setFilter(newFilter);
          }}
          value={filter?.nis}
          placeholder="Nomor NIS"
        />
        <Select
          placeholder="Tahun Ajaran"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              school_year: val,
            };
            setFilter(newFilter);
            const year = listTahunAjaran.find((el) => el._id === val);
            setSelectedYear(year);
          }}
          value={filter?.school_year}
          allowClear
          onClear={() => {
            setSelectedYear(null);
            setFilter({
              ...filter,
              school_year: null,
              month: null,
            });
          }}
        >
          {listTahunAjaran?.map((year) => {
            return <Select.Option value={year._id}>{year.name}</Select.Option>;
          })}
        </Select>
        <Select
          placeholder={
            !filter.school_year ? 'Silakan pilih tahun ajaran' : 'Pilih bulan'
          }
          style={{ width: '100%' }}
          disabled={!filter.school_year}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              month: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.month}
          allowClear
        >
          {listBulan?.map((mt) => {
            return <Select.Option value={mt.value}>{mt.name}</Select.Option>;
            // return <p>{JSON.stringify(month)}</p>;
          })}
        </Select>
        <Select
          placeholder="Kelas"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              grade: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.grade}
        >
          {[
            { _id: 'Murid Tanpa Kelas', name: 'Murid Tanpa Kelas' },
            ...listKelas,
          ].map((kelas) => {
            return (
              <Select.Option value={kelas._id}>{kelas.name}</Select.Option>
            );
          })}
        </Select>
        <Select
          placeholder="Status Murid"
          style={{ width: '100%' }}
          onChange={(val) => {
            const newFilter = {
              ...filter,
              status: val,
            };
            setFilter(newFilter);
          }}
          value={filter?.status}
        >
          <Select.Option value={'2'}>Tidak Aktif</Select.Option>
          <Select.Option value={'1'}>Aktif</Select.Option>
          <Select.Option value={'3'}>Lulus</Select.Option>
        </Select>
        <footer
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #d5d5d5',
            paddingTop: '0.5rem',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setFilterPopover(false);
                setFilter({
                  name: null,
                  status: null,
                  grade: null,
                });
                history.push({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    unit: selectedUnit.name,
                    limit,
                    page,
                  }),
                });
              }}
            >
              Hapus Filter
            </Button>
            <Button type="primary" onClick={handleSubmitFilter}>
              Cari
            </Button>
          </Space>
        </footer>
      </Space>
    </div>
  );

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Nama Murid',
      dataIndex: 'student',
      key: 'student',
      width: 250,
      render: (data, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                `/daftar-murid/detail/${data?._id}?unit=${selectedUnit?.name}`,
                '_blank'
              )
            }
          >
            {data?.name || '-'}
          </span>
        );
      },
    },
    {
      title: 'Kelas',
      dataIndex: 'student',
      key: 'student_grade',
      render: (data) => <span>{data?.grade?.name || 'Murid Tanpa Kelas'}</span>,
    },
    {
      title: 'Uang Sekolah Bulan',
      dataIndex: 'month',
      key: 'month',
      width: 200,
      render: (data) => <span>{moment(data).format('MMMM YYYY')}</span>,
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: 150,
      render: (data) => <span>Rp{Number(data || 0).toLocaleString()}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        if (!record.active_status) {
          return <Tag color="red">TIDAK AKTIF</Tag>;
        } else if (Number(data) === 0) {
          return <Tag color="red">BELUM BAYAR</Tag>;
        }

        const total = record.total_amount - (data || 0);
        const isLunas = total === 0;

        if (isLunas) {
          return <Tag color="green">LUNAS</Tag>;
        } else {
          return (
            <Tag color="red">-Rp{Number(total || 0).toLocaleString()}</Tag>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (id) => (
        <Button
          onClick={() => {
            setOpenDrawer(true);
            setSelectedId(id);
          }}
          icon={<EyeOutlined />}
        >
          Lihat
        </Button>
      ),
    },
  ];

  const generateParsed = (data) => {
    const parsed = [
      [
        'No',
        'Nama Murid',
        'NIS',
        'Kelas',
        'Bulan',
        'Nominal Tagihan',
        'Sudah Bayar',
        'Sisa',
      ],
    ];

    data.forEach((dt, idx) => {
      const array = [
        idx + 1,
        dt.student.name,
        dt.student.nik,
        dt.student.grade?.name || 'Murid Tanpa Kelas',
        moment(dt.month).format('MMMM YYYY'),
        dt.total_amount,
        dt.paid_amount,
        dt.total_amount - dt.paid_amount,
      ];

      parsed.push(array);
    });

    return parsed;
  };

  const handleDownload = () => {
    let query = [];

    if (name) query.push(`student_name=${name}`);
    if (status) query.push(`student_status=${status}`);
    if (grade) query.push(`student_grade=${grade}`);
    if (nis) query.push(`student_nik=${nis}`);
    if (month) query.push(`month=${month}`);
    if (school_year) query.push(`&school_year=${school_year}`);

    const strQuery = query.join('&');

    setLoadingDownload(true);

    generateExcelTagihan(unitId, 'spp', strQuery)
      .then((res) => {
        const { excelGenerate } = res;
        if (res) {
          const toBeDownloaded = Object.keys(excelGenerate).map((dt) => {
            const parsed = generateParsed(excelGenerate[dt].dataList);

            return { data: parsed, sheetName: dt };
          });

          console.log(toBeDownloaded);

          exportExcel(toBeDownloaded, 'Laporan Tagihan Uang Sekolah', true);
        }
      })
      .catch((err) => {
        console.log(err, 'error response');
      })
      .finally(() => setLoadingDownload(false));
  };

  return (
    <>
      <PageHeader>
        <Space>
          <Button
            type=""
            icon={<DownloadOutlined />}
            loading={loadingDownload}
            onClick={handleDownload}
          >
            Download Laporan
          </Button>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() =>
              history.push({
                pathname: `${location.pathname}/edit`,
                search: `?unit=${unit}`,
              })
            }
          >
            Edit Tagihan Uang Sekolah
          </Button>
        </Space>
      </PageHeader>
      <section style={{ width: 'auto', margin: '2rem 0' }}>
        <Popover
          content={filterMenu}
          visible={filterPopover}
          placement="bottomLeft"
          style={{ width: '30rem' }}
        >
          <Button
            onClick={() => setFilterPopover(!filterPopover)}
            icon={<FilterOutlined />}
          >
            Cari Berdasarkan
          </Button>
        </Popover>
      </section>
      <Row gutter={[24, 0]}>
        <Col span="8">
          <InfoCard
            title="Jumlah Murid Menunggak"
            content={(otherData?.outstandingStudent || 0).toLocaleString() || 0}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Jumlah Tagihan Belum Lunas"
            content={(otherData?.outstandingBill || 0).toLocaleString() || 0}
            sideColor="#FFA6A7"
          />
        </Col>
        <Col span="8">
          <InfoCard
            title="Total Tunggakan"
            content={`Rp${
              (otherData?.totalOutstanding || 0).toLocaleString() || 0
            }`}
            sideColor="#FFA6A7"
          />
        </Col>
      </Row>
      <section style={{ width: '100%', margin: '2rem 0' }}>
        <Table
          dataSource={data}
          columns={tableColumns}
          pagination={{
            position: ['bottomLeft'],
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            pageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '30', '50'],
            onShowSizeChange: (curr, pageSize) => {
              goToSearchQuery('limit', pageSize, filterQuery, history);
            },
          }}
          scroll={{ x: 1000 }}
          loading={loading}
        />
      </section>

      <Drawer.DetailTagihanSPP
        visible={openDrawer}
        onCancel={() => {
          setOpenDrawer(false);
          setSelectedId(null);
        }}
        value={{ id: selectedId, unitId: unitId }}
        refetchData={fetchData}
      />
    </>
  );
};

export default TagihanSPP;

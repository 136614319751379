import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert, Space, Select, Row, Col, Checkbox } from 'antd';
import { useStore } from 'store';
import { getMasterBillItem, studentDownloadData } from 'utils/fetch';
import moment from 'moment';
import exportExcel from 'utils/helpers/xlsx';

const DownloadLaporanMurid = ({ visible, onCancel, type }) => {
  const {
    state: { listTahunAjaran, activeSchoolYear, selectedUnit },
  } = useStore();
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedYearName, setSelectedYearName] = useState('');
  const [items, setItems] = useState([]);
  const [unitId, setUnitId] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeSchoolYear) {
      setSelectedYear(activeSchoolYear?._id);
      setSelectedYearName(activeSchoolYear?.name);
    }
  }, [activeSchoolYear]);

  useEffect(() => {
    if (selectedUnit?._id) {
      setUnitId(selectedUnit?._id);
    }
  }, [selectedUnit]);

  useEffect(() => {
    fetchMasterBillItem(unitId);
  }, [unitId]);

  const fetchMasterBillItem = (id) => {
    getMasterBillItem(id)
      .then((res) => {
        setItems(res.masterBillItem);
        console.log(res, 'this is response');
      })
      .catch((err) => console.error(err));
  };

  const parseDataCondition = (
    generalBillItemList,
    ppdbItemList,
    studentData
  ) => {
    const header = [
      'No',
      'Nama',
      'Kelas',
      'Nominal SPP',
      'JUL',
      'AGU',
      'SEP',
      'OKT',
      'NOV',
      'DES',
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MEI',
      'JUN',
    ];
    const monthIndex = {
      7: 4,
      8: 5,
      9: 6,
      10: 7,
      11: 8,
      12: 9,
      1: 10,
      2: 11,
      3: 12,
      4: 13,
      5: 14,
      6: 15,
    };

    const generalItems = generalBillItemList?.map(
      (item) => `${item.name} - Lainnya`
    );
    const ppdbItems = ppdbItemList?.map(
      (item) => `${item.name} - Uang Pangkal`
    );

    const parsed = [
      [selectedYearName, '', '', '', '', '', '', '', '', ''],
      [...header, ...ppdbItems, ...generalItems],
    ];

    studentData.forEach((data, index) => {
      const generalAmount = data.generalBillItemOutstanding?.map(
        (item) => item?.debtAmount || 0
      );
      const ppdbAmount = data.ppdbBillItemsOutstanding?.map(
        (item) => item?.debtAmount || 0
      );

      const column = [
        index + 1,
        data.name || '-',
        data?.grade?.name || 'Murid Tanpa Kelas',
        data.sppOutstanding || 0,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ...ppdbAmount,
        ...generalAmount,
      ];

      if (data.sppPaidMonth?.length) {
        data?.sppPaidMonth.forEach((dt) => {
          const month = moment(dt).month();
          const idx = monthIndex[month + 1];
          column[idx] = 'OK';
        });
      }

      parsed.push(column);
    });

    console.log(
      studentData?.filter((dt) => dt.sppPaidMonth?.length > 0),
      'student with paid spp'
    );

    return parsed;
  };

  const parseDataMasterData = (
    masterBillItemPPDB,
    masterBillItemSPPItem,
    dbMasterBillItems,
    studentData
  ) => {
    const header = ['No', 'Nama', 'Kelas', 'Virtual Account', 'Link Parents'];

    const sppItems = masterBillItemSPPItem?.map(
      (spp) => `${spp.name} - Uang Sekolah`
    );
    const ppdbItems = masterBillItemPPDB.map(
      (ppdb) => `${ppdb.name} - Uang Pangkal`
    );
    const generalItems = dbMasterBillItems.map(
      (gen) => `${gen.name} - Lainnya`
    );

    const parsed = [
      [selectedYearName, '', '', '', '', '', '', '', '', '', '', ''],
      [...header, ...sppItems, ...ppdbItems, ...generalItems],
    ];

    studentData?.map((data, index) => {
      const newSppItems = masterBillItemSPPItem?.map((spp) => {
        const foundedItem = data?.spp_items?.find(
          (item) => item?.master_bill_item === spp._id
        );

        return foundedItem?.amount || 0;
      });
      const stdGeneral = data?.generalBillItemStudents?.map(
        (dt) => dt.amount || 0
      );
      const stdPpdb = data?.ppdbBillItemsStudents?.map((dt) => dt.amount || 0);

      const column = [
        index + 1,
        data?.name || '-',
        data?.grade?.name || 'Murid Tanpa Kelas',
        data?.virtual_account || '-',
        `https://parents.skketapang.org/auth/login?virtual_account=${data?.virtual_account}&password=${data?.password}` ||
          '-',
        ...newSppItems,
        ...stdPpdb,
        ...stdGeneral,
      ];

      parsed.push(column);
    });

    return parsed;
  };

  const handleDownload = () => {
    setLoading(true);
    studentDownloadData(unitId, selectedYear, type, {
      masterBillItems: selectedItems,
    })
      .then(({ value }) => {
        console.log(value);
        if (type === 'download-condition') {
          const parsedDataHasClass = parseDataCondition(
            value.generalBillItemList,
            value.ppdbItemList,
            value.studentHasClass
          );
          const parsedDataNoClass = parseDataCondition(
            value.generalBillItemList,
            value.ppdbItemList,
            value.studentNoClass
          );
          const parsedDataNotActive = parseDataCondition(
            value.generalBillItemList,
            value.ppdbItemList,
            value.studentNotActive
          );

          const multipleData = [
            { data: parsedDataHasClass, sheetName: 'Murid Dengan Kelas' },
            { data: parsedDataNoClass, sheetName: 'Murid Tanpa Kelas' },
            { data: parsedDataNotActive, sheetName: 'Murid Tidak Aktif' },
          ];
          console.log(parsedDataHasClass);
          exportExcel(multipleData, `Keseluruhan - ${selectedYearName}`, true);
        } else {
          const parsedHasClass = parseDataMasterData(
            value.masterBillItemPPDB,
            value.masterBillItemSPPItem,
            value.dbMasterBillItems,
            value.studentHasClass
          );
          const parsedNoClass = parseDataMasterData(
            value.masterBillItemPPDB,
            value.masterBillItemSPPItem,
            value.dbMasterBillItems,
            value.studentNoClass
          );
          const parsedNotActive = parseDataMasterData(
            value.masterBillItemPPDB,
            value.masterBillItemSPPItem,
            value.dbMasterBillItems,
            value.studentNotActive
          );
          console.log(parsedHasClass);

          const multipleData = [
            { data: parsedHasClass, sheetName: 'Murid Dengan Kelas' },
            { data: parsedNoClass, sheetName: 'Murid Tanpa Kelas' },
            { data: parsedNotActive, sheetName: 'Murid Tidak Aktif' },
          ];
          exportExcel(multipleData, `Master Data - ${selectedYearName}`, true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      centered
      width="45rem"
      visible={visible}
      onCancel={onCancel}
      title="Pilih Tunggakan Yang Ingin Didownload"
      okText="Download"
      onOk={() => {
        console.log(selectedItems, selectedYear);
        handleDownload();
      }}
      okButtonProps={{ loading: loading }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Select
          placeholder="Tahun Ajaran"
          style={{ width: '100%' }}
          onChange={(val) => {
            setSelectedYear(val);
            const year = listTahunAjaran.find((el) => el._id === val);
            setSelectedYearName(year?.name);
          }}
          value={selectedYear}
          allowClear
          onClear={() => {
            setSelectedYear('');
          }}
        >
          {listTahunAjaran?.map((year) => {
            return <Select.Option value={year._id}>{year.name}</Select.Option>;
          })}
        </Select>
        <section>
          <Row gutter={[12]}>
            {items?.map((item) => {
              const selected = selectedItems.some(
                (item2) => item._id === item2
              );

              return (
                <Col span="12">
                  <Checkbox
                    onChange={() => {
                      if (selected) {
                        const removed = selectedItems.filter(
                          (item3) => item3 !== item?._id
                        );
                        setSelectedItems(removed);
                      } else {
                        setSelectedItems((prev) => [...prev, item?._id]);
                      }
                    }}
                    checked={selected}
                    style={{ margin: '1rem 0' }}
                  >
                    {item?.name}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </section>
      </Space>
    </Modal>
  );
};

export default DownloadLaporanMurid;

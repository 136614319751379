import React, { useState } from 'react';
import { Modal, Button, Alert, DatePicker } from 'antd';
import { BASE_URL } from 'utils/fetch';
import moment from 'moment';

const PilihTanggalCutoffDashboard = ({
  visible,
  onCancel,
  filter,
  setFilter,
  onDownload,
  defaultValue,
  isLoadinDownload,
}) => {
  const [range, setRange] = useState(moment(defaultValue));

  return (
    <Modal
      centered
      visible={visible}
      onCancel={() => {
        onCancel();
        setRange(moment(defaultValue));
      }}
      okText="Download"
      onOk={() => {
        onDownload();
      }}
      okButtonProps={{ loading: isLoadinDownload }}
    >
      <section>
        <h4>Pilih Tanggal Cut Off</h4>
        <div style={{ width: '100%' }}>
          <DatePicker
            style={{ width: '100%' }}
            value={range}
            onChange={(v) => {
              console.log({ v });
              setRange(v);
              setFilter((p) => ({
                ...p,
                cutOffDate: moment(v).format('MM/DD/YYYY'),
              }));
            }}
          />
        </div>
      </section>
    </Modal>
  );
};

export default PilihTanggalCutoffDashboard;

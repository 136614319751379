import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Space,
  Button,
  Card,
  Divider,
  Tag,
  Table,
  InputNumber,
  DatePicker,
  Select,
  Alert,
  message,
  Modal,
  Dropdown,
  Menu,
} from 'antd';
import { Footer } from './shared';
import { InputWrapper, PageHeader } from '..';
import { InfoCard } from 'components/fragments/card';
import { CustomButton, Loader } from 'components/atoms';
import IC_EDIT from 'assets/icons/Edit.svg';
import {
  CalendarOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  FileOutlined,
  MenuOutlined,
  MoneyCollectOutlined,
  StarFilled,
} from '@ant-design/icons';
import YellowCard from 'components/atoms/card-base/yellow-card';
import {
  BASE_URL,
  deletePayment,
  getDetailPembayaran,
  hapusPembayaran,
} from 'utils/fetch';
import moment from 'moment';
import { EditPaymentDate } from '../modal';
import { themeColor } from 'lib/theme';

const DetailPembayaranPPDB = (props) => {
  const { visible, onCancel, value, refetchData, ...rest } = props;
  const [displayItem, setDisplayItem] = useState(0);
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { unitId = '', paymentId = '' } = value;

  const ArrayTanggal = Array.from({ length: 31 }, (_, i) => i + 1);

  useEffect(() => {
    if (!visible) setDisplayItem(0);
  }, [visible]);

  useEffect(() => {
    if (visible && paymentId) {
      fetchData();
    } else {
      setDetail(null);
    }
  }, [visible, paymentId]);

  const fetchData = () => {
    getDetailPembayaran(unitId, 'ppdb-instalment', paymentId)
      .then((res) => {
        console.log(res);
        setDetail(res);
      })
      .catch((err) => console.log(err.response, 'error fetch detail'));
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin menghapus pembayaran ${detail?.payment.name} oleh ${detail?.payment.student.name}?`,
      content: (
        <div>
          <p>
            Jika Anda menghapus pembayaran akan menambah tunggakan murid, dan
            dana alokasi akan dikembalikan ke dana masuk.
          </p>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: 'Hapus',
      cancelText: 'Batal',
      cancelButtonProps: {
        type: 'secondary',
      },
      onOk() {
        setLoading(true);
        hapusPembayaran(
          unitId,
          paymentId,
          'ppdb-instalment',
          'dana_masuk=false'
        )
          .then((res) => {
            console.log(res);
            onCancel();
            message.success('Berhasil menghapus pembayaran');
            refetchData();
          })
          .catch((err) => {
            console.log(err.response);
            message.err(err?.response?.data?.m || 'Gagal menghapus pembayaran');
          });
      },
      centered: true,
    });
  };

  const handleBackToFund = () => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin mengembalikan pembayaran ${detail?.payment.name} oleh ${detail?.payment.student.name} ke Dana Masuk?`,
      content: (
        <div>
          <p>
            Jika anda setuju maka akan menambah tunggakan murid, dan dana akan
            dikembalikan ke dana masuk.
          </p>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: 'Ya',
      cancelText: 'Batal',
      cancelButtonProps: {
        type: 'secondary',
      },
      onOk() {
        hapusPembayaran(unitId, paymentId, 'ppdb-instalment', 'dana_masuk=true')
          .then((res) => {
            console.log(res);
            onCancel();
            refetchData();
            message.success('Berhasil mengembalikan pembayaran ke Dana Masuk');
          })
          .catch((err) => {
            console.log(err.response);
            message.err(
              err?.response?.data?.m ||
                'Gagal mengembalikan pembayaran ke Dana Masuk'
            );
          });
      },
      centered: true,
    });
  };

  const tableColumnsItemTagihan = [
    {
      title: 'Item',
      dataIndex: 'ppdb_item',
      key: 'ppdb_item',
      render: (data) => <span>{data?.master_bill_item?.name || '-'}</span>,
    },
    {
      title: 'Sudah Bayar',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data) => <span>{`Rp${data?.toLocaleString()}` || 0}</span>,
    },
    {
      title: 'Refund',
      dataIndex: 'refund',
      key: 'refund',
      render: (data) => <span>{`Rp${data?.toLocaleString()}` || 0}</span>,
    },
    {
      title: 'Nominal Tagihan',
      dataIndex: 'total_amount',
      key: 'total_amount',
      align: 'right',
      render: (data) => <span>{`Rp${data?.toLocaleString()}` || 0}</span>,
    },
  ];

  const tableColumnsCicilan = [
    {
      title: 'Cicilan ke-',
      dataIndex: 'instalment_repition',
      key: 'instalment_repition',
      width: 120,
      render: (data) => <span>{data + 1}</span>,
    },
    {
      title: 'Tgl Penagihan',
      dataIndex: 'instalment_date',
      key: 'instalment_date',
      render: (data) => <span>{moment(data).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'paid_status',
      key: 'paid_status',
      render: (data, record) => {
        let color;
        let text;

        if (data) {
          color = 'green';
          text = 'LUNAS';
        } else {
          text = `-Rp${(
            Number(record.total_amount) - Number(record.paid_amount)
          ).toLocaleString()}`;
          color = 'red';
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Nominal',
      dataIndex: 'total_amount',
      key: 'total_amount',
      align: 'right',
      render: (data) => <span>{`Rp${data?.toLocaleString()}` || 0}</span>,
    },
  ];

  const MenuHapus = (
    <Menu>
      <Menu.Item danger onClick={handleDelete}>
        Hapus Pembayaran
      </Menu.Item>
      <Menu.Item danger onClick={handleBackToFund}>
        Kembalikan Ke Dana Masuk
      </Menu.Item>
    </Menu>
  );

  const Detail = () => (
    <>
      <section style={{ marginBottom: '2rem' }}>
        <header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '0.75rem',
          }}
        >
          <Space>
            <h2 style={{ margin: 0 }}>{detail?.payment?.name}</h2>
          </Space>
          <Space>
            <Button
              icon={<FileDoneOutlined />}
              onClick={() => {
                window.open(
                  BASE_URL(
                    `bill/${unitId}/ppdb/${detail?.ppdb_bill._id}/download-receipt`
                  ),
                  '_blank'
                );
              }}
            >
              Receipt
            </Button>
            <Button
              icon={<CalendarOutlined />}
              onClick={() => setOpenModal(true)}
            >
              Edit Tanggal
            </Button>
            {detail?.payment?.payment_origin === 'Tabungan' ? (
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={handleDelete}
                // disabled={detail?.payment.payment_origin === 'Manual VA'}
              >
                Hapus Pembayaran
              </Button>
            ) : (
              <Dropdown overlay={MenuHapus}>
                <Button
                  icon={<MenuOutlined />}
                  // onClick={handleBackToFund}
                  // disabled={detail?.payment.payment_origin === 'Manual VA'}
                >
                  Menu Lainnya
                </Button>
              </Dropdown>
            )}
            {/* <CustomButton.IconTextButton
              type="secondary"
              icon={IC_EDIT}
              onClick={() => setDisplayItem(1)}
            >
              <span style={{ color: '#586BA4' }}>Edit</span>
            </CustomButton.IconTextButton> */}
          </Space>
        </header>
        <Space>
          <span>
            <CalendarOutlined style={{ color: '#1890FF' }} />{' '}
            {new Date(detail?.payment.date).toLocaleDateString('ID')}
          </span>
        </Space>
      </section>

      <YellowCard>
        <Row>
          <Col span="12">
            <h2 style={{ color: themeColor.primary }}>
              {detail?.payment?.student.name}
            </h2>
            <Space>
              <Tag color="blue">AKTIF</Tag>
              <Tag color="orange">
                <StarFilled /> &nbsp;ASAK
              </Tag>
            </Space>
          </Col>
          <Col span="1">
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span="11">
            <Row gutter={[8, 8]}>
              <Col span="12">NIS</Col>
              <Col span="12">{detail?.payment?.student?.nik || '-'}</Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span="12">Kelas</Col>
              <Col span="12">{detail?.payment?.student.grade?.name || '-'}</Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span="12">Nomor VA</Col>
              <Col span="12">{detail?.payment?.student.virtual_account}</Col>
            </Row>
          </Col>
        </Row>
      </YellowCard>

      <Row gutter={[18, 0]} style={{ marginTop: '2rem' }}>
        <Col span="12">
          <InfoCard
            title="Sudah Bayar"
            content={`Rp${(
              detail?.payment?.total_amount || 0
            ).toLocaleString()}`}
            sideColor="green"
          />
        </Col>
        {detail?.payment?.refund > 0 && (
          <Col span="12">
            <InfoCard
              title="Refund"
              content={`Rp${(detail?.payment?.refund || 0).toLocaleString()}`}
              sideColor="red"
            />
          </Col>
        )}
      </Row>

      <section style={{ marginTop: '2rem' }}>
        <h3>Item Terbayar</h3>
        <Table
          columns={tableColumnsItemTagihan}
          dataSource={detail?.ppdbItems || []}
          pagination={false}
        />
      </section>

      <section style={{ marginTop: '2rem' }}>
        <h3>Cicilan Terbayar</h3>
        <Table
          columns={tableColumnsCicilan}
          dataSource={detail?.ppdbInstalments || []}
          pagination={false}
        />
      </section>
    </>
  );

  const drawerTitle =
    displayItem === 0
      ? 'Detail Tagihan Murid'
      : displayItem === 1
      ? 'Edit Nominal Uang Pangkal'
      : 'Cancel Tagihan Uang Pangkal';

  return (
    <Drawer
      title={drawerTitle}
      width={displayItem > 0 ? '50vw' : '60vw'}
      visible={visible}
      footer={<Footer onCancel={() => onCancel()} onlyClose />}
      onClose={onCancel}
      {...rest}
    >
      {detail ? <Detail /> : <Loader text="Fetching detail pembayaran ..." />}

      {openModal ? (
        <EditPaymentDate
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          unitId={unitId}
          paymentId={paymentId}
          refetch={() => {
            refetchData();
            fetchData();
          }}
          defaultValue={{
            date: moment(detail?.payment?.date),
            ...detail.payment,
          }}
        />
      ) : null}
    </Drawer>
  );
};

export default DetailPembayaranPPDB;

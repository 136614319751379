import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Space,
  message,
  Input,
  Select,
  List,
} from 'antd';
import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'components/shared';
import { useStore } from 'store';
import {
  editProfile,
  getStudentHasClass,
  getStudentNoClass,
  getUserData,
  submitAssignMurid,
  getListKelas,
} from 'utils/fetch';
import { CardBase, Loader } from 'components/atoms';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import queryString from 'querystring';
import SearchSVG from 'assets/icons/magnifiying.svg';

const AssignMurid = () => {
  const history = useHistory();
  const location = useLocation();

  const [form] = Form.useForm();
  const {
    state: { userData, listKelas = [], selectedUnit, activeSchoolYear },
    handleUserData,
    handleListKelas,
  } = useStore();

  const [showModalReview, setShowModalReview] = useState({
    state: false,
    data: null,
  });

  const [filterKelas, setFilterKelas] = useState(null);
  const [kelasTerpilih, setKelasTerpilih] = useState('');
  const [searchName, setSearchName] = useState('');
  const [gradeId, setGradeId] = useState('');
  const [hasGrade, setHasGrade] = useState(null);
  const [noGrade, setNoGrade] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const unitId = selectedUnit?._id;
  const schoolYearId = activeSchoolYear?._id;
  const { grade = '', unit = '' } = queryString.parse(
    location.search.replace('?', '')
  );

  useEffect(() => {
    if ((unitId, schoolYearId)) {
      fetchStudentHasClass();
      fetchStudentNoClass();
    }
  }, [unitId, schoolYearId]);

  useEffect(() => {
    if (!listKelas?.length && unitId && schoolYearId) {
      getListKelas(unitId, schoolYearId)
        .then((res) => {
          handleListKelas(res.grades);
        })
        .catch((err) => console.log(err));
    }
  }, [listKelas, unitId, schoolYearId]);

  const handleSubmit = (values) => {
    setLoadingSubmit(true);
    submitAssignMurid(values, unitId, schoolYearId)
      .then((res) => {
        console.log(res);
        message.success('Berhasil assign murid ke kelas.');
        history.push({
          pathname: '/daftar-kelas',
          search: queryString.stringify({
            unit: unit.toLowerCase(),
          }),
        });
      })
      .catch((err) => {
        console.log(err.response);
        message.error('Gagal assign kelas kepada murid');
      })
      .finally(() => setLoadingSubmit(false));
  };

  // useEffect(() => {
  //   if (grade) {
  //     setGradeId(grade);
  //   }
  // }, [grade]);

  const fetchStudentNoClass = () => {
    getStudentNoClass(unitId, schoolYearId)
      .then((res) => {
        console.log(res, 'no grade');
        setNoGrade(res.studentsNoGrade);
      })
      .catch((err) => console.log(err.response));
  };

  const fetchStudentHasClass = () => {
    getStudentHasClass(unitId, schoolYearId)
      .then((res) => {
        console.log(res, 'has grade');
        setHasGrade(res.studentsHasGrade);
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Assign Murid</h2>
        {hasGrade && noGrade ? (
          <div style={{ marginTop: '2rem', paddingBottom: '8rem' }}>
            <Row gutter={[20, 14]}>
              <Col span="12">
                <CardBase
                  style={{
                    minHeight: '40.5vh',
                    maxHeight: '52.575rem',
                    overflowY: 'auto',
                  }}
                >
                  <h3>
                    Murid Belum Memiliki Kelas ({noGrade?.length || 0} Murid)
                  </h3>
                  <p style={{ color: '#595959', fontSize: '1.25rem' }}>
                    Pilih murid untuk dipindahkan ke kelas
                  </p>
                  <div style={{ margin: '1.25rem 0' }}>
                    <Row gutter={[8, 0]}>
                      <Col span="16">
                        <Input
                          suffix={<img src={SearchSVG} />}
                          placeholder="Cari nama murid"
                          onChange={(e) => setSearchName(e.target.value)}
                        />
                      </Col>
                      <Col span="8">
                        <Select
                          placeholder="Kelas"
                          style={{ width: '100%' }}
                          onChange={(value) => setFilterKelas(value)}
                          value={filterKelas}
                        >
                          {listKelas?.map((el) => {
                            return (
                              <Select.Option value={el._id}>
                                {el.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                  <List
                    dataSource={noGrade.filter((item) => {
                      return (
                        item.name
                          .toLowerCase()
                          .indexOf(searchName.toLowerCase()) > -1
                      );
                    })}
                    renderItem={(item) => (
                      <List.Item>
                        <Space size="middle">
                          <PlusCircleOutlined
                            style={{ color: '#1890FF', cursor: 'pointer' }}
                            onClick={() => {
                              const newList = [{ ...item, grade }, ...hasGrade];
                              const newNoGrade = [...noGrade].filter(
                                (el) => el._id !== item._id
                              );
                              setNoGrade(newNoGrade);
                              setHasGrade(newList);
                            }}
                          />
                          <span>{item?.name}</span>
                        </Space>
                      </List.Item>
                    )}
                  />
                </CardBase>
              </Col>
              <Col span="12">
                <CardBase
                  style={{
                    minHeight: '40.5vh',
                    maxHeight: '52.575rem',
                    overflowY: 'auto',
                  }}
                >
                  <h3>
                    Kelas {unit?.toUpperCase()}{' '}
                    {listKelas.find((el) => el._id === grade)?.name} (
                    {hasGrade.filter((el) => el.grade === grade)?.length} Murid)
                  </h3>
                  <p
                    style={{
                      color: '#595959',
                      fontSize: '1.25rem',
                    }}
                  >
                    Klik kolom di bawah untuk mengganti kelas
                  </p>
                  <div
                    style={{
                      margin: '1.25rem 0',
                      width: '100%',
                    }}
                  >
                    <Select
                      placeholder="Pilih kelas"
                      style={{ width: '100%', backgroundColor: 'red' }}
                      onChange={(v) => {
                        history.push({
                          pathname: location.pathname,
                          search: queryString.stringify({
                            unit,
                            grade: v,
                          }),
                        });
                      }}
                      value={grade}
                    >
                      {listKelas?.map((item) => {
                        return (
                          <Select.Option value={item._id}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>{item.name}</div>{' '}
                              <div>
                                {
                                  hasGrade.filter(
                                    (el) => el.grade === item?._id
                                  )?.length
                                }{' '}
                                Murid
                              </div>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <List
                    dataSource={hasGrade.filter((el) => el.grade === grade)}
                    renderItem={(item) => (
                      <List.Item>
                        <Space size="middle">
                          <MinusCircleOutlined
                            style={{ color: '#FF4D4F', cursor: 'pointer' }}
                            onClick={() => {
                              const newList = [...noGrade, item];
                              const newHas = [...hasGrade].filter(
                                (el) => el._id !== item._id
                              );
                              setNoGrade(newList);
                              setHasGrade(newHas);
                            }}
                          />
                          <span>{item?.name}</span>
                        </Space>
                      </List.Item>
                    )}
                  />
                </CardBase>
              </Col>
            </Row>
          </div>
        ) : (
          <Loader text="Memuat data ..." />
        )}

        <StyledFixedFooter>
          <Space>
            <Button>Kembali</Button>
            <Button
              type="primary"
              onClick={() => {
                // const studentsNoGrade = noGrade?.length
                //   ? noGrade.map((el) => el._id)
                //   : [] || [];
                // const studentHasGrade = hasGrade?.length
                //   ? hasGrade.map((el) => ({
                //       studentId: el._id,
                //       gradeId: el.grade,
                //     }))
                //   : [] || [];

                setShowModalReview({
                  state: true,
                  data: { noGrade, hasGrade },
                });

                // handleSubmit({ studentsNoGrade, studentHasGrade });
              }}
            >
              Simpan
            </Button>
          </Space>
        </StyledFixedFooter>
      </StyledContentWrapper>
      <Modal.ReviewPenemepatanKelas
        visible={showModalReview.state}
        data={showModalReview.data}
        listKelas={listKelas}
        onClose={() => {
          setShowModalReview({ state: false, data: null });
        }}
        handleSubmit={handleSubmit}
        loadingSubmit={loadingSubmit}
      />
    </StyledPageContainer>
  );
};

export default AssignMurid;

import React from 'react';
import { Row, Col, Tag, Button, Modal, Space, Divider } from 'antd';
import { CardBase } from 'components/atoms';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import IC_TICK from 'assets/icons/tick-mark-1.svg';
import moment from 'moment';

const TahunAjaran = (props) => {
  const { data, handleAktifasi, ...rest } = props;
  const urutan = ['TK', 'SMP', 'SD', 'SMA'];
  const dataKelas = data.data;

  const CardTitle = () => {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: '1rem',
          alignItems: 'center',
        }}
      >
        <h1 style={{ margin: 0 }}>{data.name}</h1>
        {data.status === 1 ? (
          <Tag style={{ marginLeft: '1rem' }} color="blue">
            Aktif
          </Tag>
        ) : (
          ''
        )}
      </div>
    );
  };

  const CardHeader = () => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const start = data
      ? `${months[moment(data.start_date).month()]} ${moment(
          data.start_date
        ).year()}`
      : '';
    const end = data
      ? `${months[moment(data.end_date).month()]} ${moment(
          data.end_date
        ).year()}`
      : '';

    return (
      <header style={{ margin: 0 }}>
        <CardTitle />
        <p style={{ color: '#333333', fontSize: '1.25rem', margin: 0 }}>
          {start} - {end}
        </p>
      </header>
    );
  };

  return (
    <>
      <CardBase title={<CardHeader />} {...rest} hoverable>
        {data.status ? (
          <Row gutter={[4, 4]}>
            {urutan.map((li) => {
              return (
                <Col span="12">
                  <Row>
                    <Col span="6">
                      <h4 style={{ fontWeight: '600' }}>{li.toUpperCase()}</h4>
                    </Col>
                    <Col span="18">{data[li]} Murid</Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Button
            type="default"
            onClick={(e) => {
              e.stopPropagation();
              Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                onOk: () => handleAktifasi(data._id),
                centered: true,
                content: (
                  <div style={{ margin: 0 }}>
                    <h2 style={{ margin: 0 }}>Perhatian!</h2>
                    <p style={{ marginTop: '1rem' }}>
                      Sebelum mengaktifkan tahun ajaran baru pastikan anda
                      telah:
                    </p>
                    <Space>
                      <img src={IC_TICK} alt="tick" width={32} height={32} />
                      <li>Mengganti status kelulusan murid.</li>
                    </Space>
                    <Divider style={{ margin: '1rem 0' }} />
                    <Space>
                      <img src={IC_TICK} alt="tick" width={32} height={32} />
                      <li>Input data murid baru.</li>
                    </Space>
                  </div>
                ),
              });
            }}
          >
            Aktifkan Tahun Ajaran
          </Button>
        )}
      </CardBase>
    </>
  );
};

export default TahunAjaran;

import React, { useState } from 'react';
import { Modal } from 'antd';
import {
  DashboardTunggakanLainnya,
  DashboardDanaMasuk,
  DashboardTunggakanSPP,
  DashboardTabungan,
  DashboardTunggakan,
  DashboardTunggakanPPDB,
  DashboardPPDBTunggakan,
  DashboardMonthlyRecap,
  DashboardMonthlyRecapPBDB,
  DashboardMonthlyRecapGeneral,
} from 'components/fragments/table';
import { useStore } from 'store';
import moment from 'moment';

const RincianDashboard = ({
  visible,
  onClose,
  table,
  data,
  type,
  asakMonth,
  unitName,
  modalTitle,
  ...rest
}) => {
  const {
    state: { activeSchoolYear },
  } = useStore();

  let title = '';

  switch (table) {
    case 'tunggakan':
      title = 'Total Murid Menunggak';
      break;
    case 'dana-masuk':
      title = 'Total Dana Masuk';
      break;
    case 'tunggakan-spp':
      title = 'Total Tunggakan Uang Sekolah';
      break;
    case 'tunggakan-ppdb':
      title = 'Total Tunggakan Uang Pangkal';
      break;
    case 'tunggakan-general':
      title = `Total Tunggakan Lainnya`;
      break;
    case 'tabungan':
      title = 'Total Tabungan';
      break;
    default:
      title = modalTitle || '';
      break;
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      {...rest}
      centered
      cancelText="Batal"
      width="70vw"
    >
      <section style={{ margin: '1rem 0' }}>
        {table === 'tunggakan' && (
          <DashboardTunggakan unitName={unitName} data={data} />
        )}
        {table === 'dana-masuk' && (
          <DashboardDanaMasuk unitName={unitName} data={data} />
        )}
        {table === 'tunggakan-general' && (
          <DashboardTunggakanLainnya unitName={unitName} data={data} />
        )}
        {table === 'tabungan' && (
          <DashboardTabungan unitName={unitName} data={data} />
        )}
        {table === 'tunggakan-spp' && (
          <DashboardTunggakanSPP unitName={unitName} data={data} />
        )}
        {table === 'tunggakan-ppdb' && (
          <DashboardTunggakanPPDB unitName={unitName} data={data} />
        )}
        {table === 'rekap-tunggakan' && (
          <DashboardPPDBTunggakan data={data} type={type} />
        )}
        {table === 'monthly-recap' && (
          <DashboardMonthlyRecap
            month={data.month}
            type={data.type}
            data={data.list}
          />
        )}
        {table === 'monthly-recap-pbdb' && (
          <DashboardMonthlyRecapPBDB
            month={data.month}
            type={data.type}
            data={data.list}
          />
        )}
        {table === 'monthly-recap-general' && (
          <DashboardMonthlyRecapGeneral data={data} />
        )}
      </section>
    </Modal>
  );
};

export default RincianDashboard;

import { CardBase, CustomButton, Loader } from 'components/atoms';
import { Drawer, PageHeader } from 'components/shared';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  message,
  Modal,
  PageHeader as ANTDHeader,
  Row,
  Space,
  Spin,
} from 'antd';
import { useStore } from 'store';
import IC_PLUSR from 'assets/icons/plus-rounded.svg';
import {
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  InfoOutlined,
  LockOutlined,
  StarFilled,
  StarOutlined,
  StarTwoTone,
} from '@ant-design/icons';
import {
  createItemTagihan,
  editDashboardStatus,
  editItemTagihan,
  editPpdbAfterLock,
  editPpdbUnit,
  editSppUnit,
  editSppAfterLock,
  getMasterBillItem,
  getSPPdanPPDBUnit,
  lockPpdbUnit,
  lockSppUnit,
} from 'utils/fetch';
import { StyledStar } from './style';

const TipeTagihan = () => {
  const {
    handleBreadcrumb,
    handleSiderActive,
    state: { selectedUnit },
  } = useStore();
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sppPpdb, setSppPpdb] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({
    itemTagihan: false,
    settingPPDB: false,
    settingSPP: false,
  });
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: '',
  });
  const [isToBeLocked, setIsToBeLocked] = useState(false);
  const unitId = selectedUnit?._id;

  useEffect(() => {
    const newBreadcrumb = [
      {
        linkTo: '/pengaturan-unit/tipe-tagihan',
        node: <span>Item Tagihan</span>,
      },
    ];
    handleSiderActive([9, '9.1']);
    handleBreadcrumb(newBreadcrumb);
  }, []);

  useEffect(() => {
    if (selectedUnit) {
      fetchMasterBillItem();
      fetchSppPpdb();
    }
  }, [selectedUnit]);

  const fetchMasterBillItem = () => {
    setLoading(true);
    getMasterBillItem(unitId)
      .then((res) => {
        setItems(res.masterBillItem);
        setOpenDrawer({
          ...openDrawer,
          itemTagihan: false,
        });
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const fetchSppPpdb = () => {
    setLoading(true);
    getSPPdanPPDBUnit(unitId)
      .then((res) => {
        console.log(res);
        setSppPpdb(res.dataSPPAndPPDB);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const handleSubmitItemTagihan = (values) => {
    setLoadingSubmit(true);
    if (isEdit.state) {
      const payload = { name: values.items, active_status: true };
      editItemTagihan(payload, unitId, isEdit.data.id)
        .then((res) => {
          if (res) {
            message.success('Berhasil mengubah item tagihan');
            fetchMasterBillItem();
          }
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.m || 'Gagal mengubah item tagihan'
          );
          console.error(err);
        })
        .finally(() => setLoadingSubmit(false));
    } else {
      createItemTagihan(values, unitId)
        .then((res) => {
          if (res) {
            message.success('Berhasil membuat item tagihan');
            fetchMasterBillItem();
          }
        })
        .catch((err) => {
          message.error(err?.response?.data?.m || 'Gagal membuat item tagihan');
          console.error(err);
        })
        .finally(() => setLoadingSubmit(false));
    }
  };

  const changeDashboardStatus = (status, id) => {
    editDashboardStatus({ dashboard_status: status }, unitId, id)
      .then((res) => {
        if (res) {
          fetchMasterBillItem();
        }
      })
      .catch((err) => console.error(err));
  };

  const handleEditPpdb = (values, id, isLocked) => {
    setLoadingSubmit(true);
    if (!isLocked) {
      console.log('Tidak Locked', values);
      editPpdbUnit(values, unitId, id)
        .then((res) => {
          if (res) {
            console.log('BERHASIL EDIT');
            message.success('Berhasil mengubah pengaturan Uang Pangkal');
            fetchSppPpdb();
            setOpenDrawer({
              ...openDrawer,
              settingPPDB: false,
            });
          }
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.m || 'Gagal mengubah pengaturan Uang Pangkal'
          );
          console.log(err.response);
        })
        .finally(() => setLoadingSubmit(false));
    } else {
      console.log('locked', values);
      editPpdbAfterLock(values, unitId, id)
        .then((res) => {
          if (res) {
            console.log('Berhasil edit after lock', res);
            message.success('Berhasil mengubah pengaturan Uang Pangkal');
            fetchSppPpdb();
            setOpenDrawer({
              ...openDrawer,
              settingPPDB: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          message.error(
            err?.response?.data?.m || 'Gagal mengubah pengaturan Uang Pangkal'
          );
        })
        .finally(() => setLoadingSubmit(false));
    }
  };

  const handleEditSpp = (values, id, isLocked) => {
    setLoadingSubmit(true);
    if (!isLocked) {
      console.log(values, 'not locked');
      editSppUnit(values, unitId, id)
        .then((res) => {
          if (res) {
            console.log('BERHASIL EDIT');
            message.success('Berhasil mengubah pengaturan Uang Sekolah');
            fetchSppPpdb();
            setOpenDrawer({
              ...openDrawer,
              settingSPP: false,
            });
          }
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.m || 'Gagal mengubah pengaturan Uang Sekolah'
          );
          console.log(err.response);
        })
        .finally(() => setLoadingSubmit(false));
    } else {
      console.log(values, 'locked');
      editSppAfterLock(values, unitId, id)
        .then((res) => {
          if (res) {
            console.log('Berhasil edit after lock', res);
            message.success('Berhasil mengubah pengaturan Uang Sekolah');
            fetchSppPpdb();
            setOpenDrawer({
              ...openDrawer,
              settingSPP: false,
            });
          }
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.m || 'Gagal mengubah pengaturan Uang Sekolah'
          );
          console.error(err);
        })
        .finally(() => setLoadingSubmit(false));
    }
    // console.log(values, id, isLocked, deletedItems, 'Hello from submit');
  };

  const handleLockPpdb = (isLocked, id) => {
    lockPpdbUnit({ lock_status: !isLocked }, unitId, id)
      .then((res) => {
        if (res) {
          message.success('Berhasil mengubah status lock Uang Pangkal');
          fetchSppPpdb();
          setOpenDrawer({
            ...openDrawer,
            settingPPDB: false,
          });
          setIsToBeLocked(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleLockSpp = (isLocked, id) => {
    // console.log(isLocked, id);
    lockSppUnit({ lock_status: !isLocked }, unitId, id)
      .then((res) => {
        if (res) {
          message.success('Berhasil mengubah status lock Uang Pangkal');
          fetchSppPpdb();
          setOpenDrawer({
            ...openDrawer,
            settingSPP: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const SppAndPpdbCardHeader = ({
    title,
    isLocked,
    id,
    type,
    withIcon,
    data,
  }) => {
    return (
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{title}</span>
        {withIcon ? (
          isLocked ? (
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                setIsEdit({ state: true, data: data });
                setOpenDrawer({
                  ...openDrawer,
                  settingPPDB: type === 'PPDB',
                  settingSPP: type === 'SPP',
                });
              }}
            />
          ) : (
            <Space size="middle">
              <Button
                icon={<LockOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    onOk: () =>
                      data.type === 'PPDB'
                        ? handleLockPpdb(data.lock_status, data._id)
                        : handleLockSpp(data.lock_status, data._id),
                    centered: true,
                    content: (
                      <div style={{ margin: 0 }}>
                        <h2 style={{ margin: 0 }}>
                          Lock Pengaturan {data.type}
                        </h2>
                        <p style={{ marginTop: '1rem' }}>
                          Apakah Anda ingin mengunci pengaturan yang baru Anda
                          buat? Jika Anda mengunci pengaturan maka tidak dapat
                          mengubahnya lagi.
                        </p>
                      </div>
                    ),
                  });
                }}
              >
                Lock
              </Button>
              <EditOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEdit({ state: true, data: data });
                  setOpenDrawer({
                    ...openDrawer,
                    settingPPDB: type === 'PPDB',
                    settingSPP: type === 'SPP',
                  });
                }}
              />
            </Space>
          )
        ) : null}
      </header>
    );
  };

  const blacklistEdit = [
    'Uang Kegiatan',
    'Uang Sekolah Pokok',
    'Denda Uang Sekolah',
  ];

  if (!loading)
    return (
      <>
        <section>
          <section style={{ marginBottom: '4rem' }}>
            <PageHeader>
              <CustomButton.IconTextButton
                type="primary"
                icon={IC_PLUSR}
                onClick={() =>
                  setOpenDrawer({
                    itemTagihan: true,
                    settingPPDB: false,
                    settingSPP: false,
                  })
                }
              >
                Tambah Item Tagihan
              </CustomButton.IconTextButton>
            </PageHeader>
            <Row gutter={[12, 18]}>
              {!items?.length && items ? (
                <p>
                  Belum ada item tagihan, silakan menambahkan item tagihan
                  terlebih dahulu
                </p>
              ) : (
                items?.map((item) => {
                  return (
                    <Col xs={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                      <Card style={{ height: '100%' }}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>{item.name}</span>
                          <Space style={{ cursor: 'pointer' }}>
                            {item.dashboard_status ? (
                              <StarFilled
                                style={{
                                  color: '#F8A11E',
                                }}
                                onClick={() =>
                                  changeDashboardStatus(false, item._id)
                                }
                              />
                            ) : (
                              <StyledStar
                                onClick={() =>
                                  changeDashboardStatus(true, item._id)
                                }
                              />
                            )}
                            {!blacklistEdit?.includes(item.name) ? (
                              <EditOutlined
                                onClick={() => {
                                  setIsEdit({
                                    state: true,
                                    data: {
                                      name: item.name,
                                      id: item._id,
                                    },
                                  });
                                  setOpenDrawer({
                                    itemTagihan: true,
                                    settingPPDB: false,
                                  });
                                }}
                              />
                            ) : null}
                          </Space>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </section>

          <section>
            <PageHeader title="Pengaturan Uang Sekolah dan Uang Pangkal" />
            <Row gutter={[12, 24]}>
              {sppPpdb?.map((data) => {
                const master_bill_items =
                  data.type === 'PPDB'
                    ? data?.ppdbItems?.map((el) => el.master_bill_item.name)
                    : data?.sppItems?.map((el) => el.master_bill_item.name);

                return (
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <CardBase
                      fullHeight
                      title={
                        <SppAndPpdbCardHeader
                          title={`${data.type} ${data.school_year?.name}`}
                          isLocked={data.lock_status}
                          id={data._id}
                          type={data.type}
                          data={data}
                          withIcon={
                            data.type === 'PPDB'
                              ? data.ppdbItems.length
                              : data.sppItems.length
                          }
                        />
                      }
                      onClick={() => {
                        const issValid =
                          data.type === 'PPDB'
                            ? data.ppdbItems.length
                            : data.sppItems.length;

                        if (data.lock_status === false && !issValid) {
                          setIsEdit({
                            state: false,
                            data: data,
                          });
                          setOpenDrawer({
                            itemTagihan: false,
                            settingPPDB: data.type === 'PPDB',
                            settingSPP: data.type === 'SPP',
                          });
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                      hoverable
                    >
                      {data.ppdbItems ? (
                        <div>
                          <Row>
                            <Col span="6">
                              <span style={{ fontWeight: 'bold' }}>Items</span>
                            </Col>
                            <Col span="18">
                              {master_bill_items.join(', ') ? (
                                <Tooltip
                                  placement="top"
                                  title={master_bill_items.join(', ')}
                                >
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {master_bill_items.join(', ')}{' '}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div>-</div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col span="6">
                              <span style={{ fontWeight: 'bold' }}>
                                Cicilan
                              </span>
                            </Col>
                            <Col span="18">
                              <span>
                                {data.instalment_repition}x per tanggal{' '}
                                {data.instalment_date}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      ) : data.sppItems.length ? (
                        <Row>
                          <Col span="6">
                            <span style={{ fontWeight: 'bold' }}>Items</span>
                          </Col>
                          <Col span="18">
                            <Tooltip
                              placement="top"
                              title={master_bill_items.join(', ')}
                            >
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {master_bill_items.join(', ') || '-'}
                              </div>
                            </Tooltip>
                          </Col>
                        </Row>
                      ) : (
                        <p style={{ color: 'red', fontSize: '1.25rem' }}>
                          <InfoCircleOutlined /> Silakan mengisi pengaturan{' '}
                          {data.type}
                        </p>
                      )}
                    </CardBase>
                  </Col>
                );
              })}
            </Row>
          </section>
        </section>

        <Drawer.ItemTagihan
          visible={openDrawer.itemTagihan}
          onCancel={() => {
            setOpenDrawer({
              settingPPDB: false,
              itemTagihan: false,
            });
            setIsEdit({
              state: false,
              data: '',
            });
          }}
          isEdit={isEdit.state}
          value={isEdit.data?.name}
          onSubmit={(values) => handleSubmitItemTagihan(values)}
          loadingSubmit={loadingSubmit}
        />
        <Drawer.SettingPPDB
          visible={openDrawer.settingPPDB}
          onCancel={() => {
            setOpenDrawer({
              settingPPDB: false,
              itemTagihan: false,
              settingSPP: false,
            });
            setIsEdit({
              state: false,
              data: '',
            });
            setIsToBeLocked(false);
          }}
          handleSubmit={handleEditPpdb}
          isEdit={isEdit.state}
          value={isEdit.data}
          items={items}
          toBeLocked={isToBeLocked}
          submitLock={handleLockPpdb}
          loadingSubmit={loadingSubmit}
        />
        <Drawer.SettingSPP
          visible={openDrawer.settingSPP}
          onCancel={() => {
            setOpenDrawer({
              settingPPDB: false,
              itemTagihan: false,
              settingSPP: false,
            });
            setIsEdit({
              state: false,
              data: '',
            });
            setIsToBeLocked(false);
          }}
          isEdit={isEdit.state}
          value={isEdit.data}
          items={items}
          toBeLocked={isToBeLocked}
          submitLock={handleLockSpp}
          handleSubmit={handleEditSpp}
          loadingSubmit={loadingSubmit}
        />
      </>
    );
  else return <Loader text="Memuat data ..." />;
};

export default TipeTagihan;

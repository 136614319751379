import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Table, Menu, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { Drawer } from 'components/shared';
import moment from 'moment';
import { useStore } from 'store';
import { fetchTahunAjaran, getTabAllBills, studentTabSPP } from 'utils/fetch';

const DetailMuridTunggakan = ({ studentId, unitId, refetchData }) => {
  const {
    state: { activeSchoolYear },
  } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTahun, setFilterTahun] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({
    state: false,
    id: '',
  });

  useEffect(() => {
    if (filterTahun) {
      fetchData();
    }
  }, [filterTahun]);

  useEffect(() => {
    if (activeSchoolYear?._id) {
      setFilterTahun(activeSchoolYear);
    }
  }, [activeSchoolYear]);

  const fetchData = () => {
    setLoading(true);
    getTabAllBills(studentId)
      .then((value) => {
        console.log(value, 'all tab bills');
        setData(value.allData);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (data, _, idx) => <span>{idx + 1}</span>,
    },
    {
      title: 'Nama Tagihan',
      dataIndex: 'name',
      key: 'name',
      render: (data) => <span>{data}</span>,
    },
    {
      title: 'Tanggal Tagihan',
      dataIndex: 'date',
      key: 'date',
      render: (data) => (
        <span>{data ? moment(data).format('DD/MM/YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Tunggakan',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (data, record) => {
        const debt = data - record.total_amount;

        if (debt === 0) {
          return <Text type="success">LUNAS</Text>;
        } else {
          return (
            <Text type="danger">-Rp{Math.abs(debt).toLocaleString()}</Text>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      align: 'right',
      render: (data, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() =>
            setOpenDrawer({
              state: true,
              id: record.bill_id,
              type: record.bill_type,
            })
          }
        >
          Lihat
        </Button>
      ),
    },
  ];

  return (
    <>
      <article>
        <Table
          columns={tableColumns}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            simple: true,
            total: totalData && totalData,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
          }}
          // scroll={{ x: 1500 }}
          loading={loading}
        />
      </article>

      {openDrawer.type === 'SPP' && (
        <Drawer.DetailTagihanSPP
          visible={openDrawer.state && openDrawer.type === 'SPP'}
          onCancel={() => {
            setOpenDrawer({
              state: false,
              id: '',
              type: '',
            });
          }}
          value={{ id: openDrawer.id, unitId }}
          refetchData={refetchData}
        />
      )}

      {openDrawer.type === 'PPDB' && (
        <Drawer.DetailTagihanPPDB
          visible={openDrawer.state && openDrawer.type === 'PPDB'}
          onCancel={() => {
            setOpenDrawer({
              state: false,
              id: '',
              type: '',
            });
          }}
          value={{ id: openDrawer.id, unitId }}
          refetchData={refetchData}
        />
      )}

      {openDrawer.type === 'General' && (
        <Drawer.DetailTagihanLainnya
          visible={openDrawer.state && openDrawer.type === 'General'}
          onCancel={() => {
            setOpenDrawer({
              state: false,
              id: '',
              type: '',
            });
          }}
          value={{ id: openDrawer.id, unitId }}
          refetchData={refetchData}
        />
      )}
    </>
  );
};

export default DetailMuridTunggakan;

import { StarOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledStar = styled(StarOutlined)`
  color: #292929;

  &:hover {
    color: #f8a11e;
  }
`;

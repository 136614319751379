import React, { useEffect } from 'react';
import { Col, Row, Form, Button, Space, message } from 'antd';
import {
  StyledContentWrapper,
  StyledPageContainer,
  StyledFixedFooter,
} from '../style';
import IC_BACK from 'assets/icons/chevron-left.svg';
import { useHistory } from 'react-router-dom';
import { InputWrapper } from 'components/shared';
import { useStore } from 'store';
import { editProfile, getUserData } from 'utils/fetch';

const EditProfile = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const {
    state: { userData },
    handleUserData,
  } = useStore();

  useEffect(() => {
    if (!userData) {
      fetchData();
    }
  }, [userData]);

  const handleSubmit = (values) => {
    if (
      form.getFieldValue('password') === form.getFieldValue('confirmPassword')
    ) {
      console.log(values);
      editProfile({
        name: values.name,
        email: values.email,
        password: values.password,
      })
        .then((res) => {
          fetchData();
          message.success('Berhasil mengubah data profil');
        })
        .catch((err) => console.error(err));
    } else {
      return;
    }
  };

  const fetchData = () => {
    getUserData()
      .then((res) => handleUserData(res.user))
      .catch((err) => console.error(err));
  };

  return (
    <StyledPageContainer>
      <StyledContentWrapper>
        <div
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '3rem',
            cursor: 'pointer',
          }}
        >
          <img
            src={IC_BACK}
            alt="back"
            style={{ marginRight: '0.75rem' }}
            width="18"
            height="18"
          />
          <span>Kembali</span>
        </div>
        <h2>Edit Profile</h2>
        <div style={{ marginTop: '2rem' }}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              name: userData ? userData.name : '',
              email: userData ? userData.email : '',
              password: userData ? userData.password : '',
              confirmPassword: userData ? userData.password : '',
              status: userData ? (userData.type === 1 ? 'Admin' : 'User') : '',
            }}
            onFinish={handleSubmit}
          >
            <Row gutter={[24, 0]}>
              <Col span="12">
                <InputWrapper
                  type="text"
                  name="name"
                  label="Nama"
                  onChange={() => {}}
                />
              </Col>
              <Col span="12">
                <InputWrapper
                  type="email"
                  name="email"
                  label="Email"
                  onChange={() => {}}
                />
              </Col>
              <Col span="12">
                <InputWrapper
                  type="password"
                  name="password"
                  label="Password"
                  onChange={() => {}}
                />
              </Col>
              <Col span="12">
                <InputWrapper
                  type="password"
                  name="confirmPassword"
                  label="Retype Password"
                  onChange={() => {}}
                />
              </Col>
              <Col span="12">
                <InputWrapper
                  type="text"
                  name="status"
                  label="Status"
                  disabled
                  onChange={() => {}}
                />
              </Col>
            </Row>

            <StyledFixedFooter>
              <Space>
                <Button>Kembali</Button>
                <Button type="primary" onClick={form.submit}>
                  Simpan
                </Button>
              </Space>
            </StyledFixedFooter>
          </Form>
        </div>
      </StyledContentWrapper>
    </StyledPageContainer>
  );
};

export default EditProfile;
